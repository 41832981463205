class FirstPurchase {

    constructor() {
        
    }

    async getUserToken() {
        return await Utils.GetUserAccessToken();
    }

    async getUser() {
        return await Utils.GetOathData();
    }

    async getFirstPurchaseSessionKey() {
        const user = await this.getUser();
        const sessionKey = "fp_" + user.sub;
        return sessionKey;
    }

    async getFirstPurchaseFromSessionStorage() {
        const sessionKey = await this.getFirstPurchaseSessionKey();
        let purchaseInfo = sessionStorage ?
            sessionStorage.getItem(sessionKey) :
            null;
        if (!purchaseInfo) {
            var p = {
                expiresAt: Date.now()
            };
            return p;
        }
        return JSON.parse(purchaseInfo);
    }

    async saveFirstPurchaseToSessionStorage(purchaseInfo) {
        const sessionKey = await this.getFirstPurchaseSessionKey();
        let expireDate = new Date();
        expireDate.setMinutes(expireDate.getMinutes() + 60);
        purchaseInfo.expiresAt = expireDate.getTime();
        if (sessionStorage) {
            sessionStorage.setItem(sessionKey, JSON.stringify(purchaseInfo));
        }
    }

    async getFirstPurchaseFromServer() {
        const user = await this.getUser();
        const token = await this.getUserToken()
        const response = await fetch(`${Urls.EnterpriseApiRoot}utils/v1/contacts/${user.sub}/properties?group=FirstPurchase`, {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        var responseJson = await response.json();
        return responseJson.properties;
    }

    async sendFirstPurchaseToServer(category) {
        const user = await this.getUser();
        const token = await this.getUserToken();
        const response = await fetch(`${Urls.EnterpriseApiRoot}utils/v1/contacts/${user.sub}/properties?group=FirstPurchase`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                group: "FirstPurchase",
                name: category,
                value: "1"
            })
        });
        var responseJson = await response.json();
        return responseJson.properties;
    }

    async getFirstPurchaseInfo() {

        let purchaseInfo = await this.getFirstPurchaseFromSessionStorage();
        var test = "";
        if (purchaseInfo.expiresAt <= Date.now()) {
            purchaseInfo.data = await this.getFirstPurchaseFromServer();
            await this.saveFirstPurchaseToSessionStorage(purchaseInfo);
        }
        return purchaseInfo;
    }

    async setFirstPurchaseInfo(category) {
        let purchaseInfo = await this.sendFirstPurchaseToServer(category);
        await this.saveFirstPurchaseToSessionStorage({ data: purchaseInfo });
    }

    getGoogleEventLabel(category) {
        switch (category) {
            case "1":
                return "BingoOnline";
            case "7":
                return "Scratch";
            case "13":
                return "BingoLotto";
        }
        return "";
    }

    async handleFirstPurchase(category) {
        category = category.toString();
        var purchaseInfo = await this.getFirstPurchaseInfo();

        var categoryAllreadyPurchased =
            purchaseInfo.data.hasOwnProperty(category)

        if (!categoryAllreadyPurchased) {
            let googleLabel = this.getGoogleEventLabel(category);
            if (googleLabel) {
                Utils.TrackGoogleEvent("LotteryShop", "FirstPurchaseCategory", googleLabel);
                await this.setFirstPurchaseInfo(category);
            }
        }
    }
};

window.fsFirstPurchase = new FirstPurchase();
