$.widget("custom.mypageswithdrawal", {
    options: {},
    _init: function () {

        $(document).ready(function () {
            const $widget = $(this),
                $element = $widget.element,
                $paymentSelection = $(".collapse-area-button", $element),
                $amount = $(".form-control.amount", $element),
                $swishAmount = $(".form-control.amount#swishAmount", $element),
                $bankAmount = $(".form-control.amount#bankAmount", $element),
                $mobileNumber = $("#mobileNumber", $element),
                $clearingNumber = $("#clearingNumber", $element),
                $accountNumber = $("#accountNumber", $element),
                $withdrawal = $(".withdrawal-button", $element),
                $changeMobileNumber = $("#changeMobileNumber", $element),
                $changeBankAccount = $("#changeAccountNumber", $element),
                $cancelWithdrawal = $("#cancelWithdrawal", $element),
                $closeWithdrawalStatus = $("#closeWithdrawalStatus", $element),
                $withdrawalOptionsWrapper = $("#withdrawalOptionsWrapper", $element),
                $latestWithdrawalWrapper = $("#latestWithdrawalWrapper", $element),
                $withdrawalStatusWrapper = $("#withdrawalStatusWrapper", $element),
                $showMobileNumber = $("div.show-mobile-number"),
                $addMobileNumber = $("div.add-mobile-number"),
                $spinner = $("#spinner", $element),
                $swishInformationPopover = $("h4#swishInformationPopover", $element),
                minVal = 10,
                maxVal = 150000,
                user = {
                    contactId: "",
                    mobile: "",
                    token: ""
                },
                messages = {
                    withdrawalDescription: "Uttag folkspel",
                    errorDefault: "Något gick fel med ditt förra uttag. Om du fortsätter uppleva problem med framtida uttag ber vi dig att kontakta kundtjänst på 0771-440 440 så hjälper vi dig vidare.",
                    //errorDefault: "Något gick snett. Vänligen uppdatera sidan och försök igen. Om du fortfarande upplever problem ber vi dig att kontakta kundtjänst på 0771-440 440 så hjälper vi dig vidare.",
                    statusInitialized: "Ditt uttag har påbörjats och inväntar godkännande av Folkspel. Så snart uttaget har godkänts kommer vi automatiskt att uppdatera sidan.",
                    statusAwaitingApproval: "Uttaget behöver godkännas manuellt av vårt team. Vi ber om din förståelse då detta kan dröja upp till 2 bankdagar. Har du några frågor, eller vill ha hjälp är du välkommen att Folkspels kundtjänst på 0771-440 440.",
                    statusApprovedSwish: "Ditt uttag har godkänts av Folkspel och dina pengar bör finnas på ditt bankkonto inom 5 minuter. Så snart uttaget har godkänts av Swish, kommer vi automatiskt att uppdatera sidan. ",
                    statusApprovedBank: "Ditt uttag har godkänts av Folkspel och dina pengar bör finnas på ditt bankkonto inom 3-5 bankdagar. Så snart uttaget har godkänts av din bank, kommer vi automatiskt att uppdatera sidan. ",
                    statusCancelled: "Du har valt att ångra uttaget och dina pengar bör synas på ditt Folkspelskonto inom kort. Sidan kommer automatiskt att uppdateras när du kan göra ett nytt uttag.",
                    statusSuccess: "Ditt uttag är klart! Pengarna har dragits från ditt Folkspelskonto och bör synas på ditt bankkonto inom kort.",
                    bankIdError: "Ett fel uppstod vid BankID-signeringen. Vänligen uppdatera sidan och försök igen. Om du fortfarande upplever problem ber vi dig att kontakta kundtjänst på 0771-440 440 så hjälper vi dig vidare.",
                    bankWithdrawalModal: "Ditt uttag har påbörjats, stäng ned detta meddelande för att se statusen på ditt uttag.",
                    swishWithdrawalModal: "Ditt uttag har påbörjats, stäng ned detta meddelande för att se statusen på ditt uttag.",
                    withdrawalCancelledModal: "Du har valt att ångra uttaget och dina pengar bör synas på ditt Folkspelskonto inom kort.",
                    invalidAccountNumber: "Har du skrivit rätt kontonummer? Kontrollera så att du fått med alla siffror, och försök igen.",
                    bankAmountValidationError: `Lägsta beloppet för uttag är ${minVal} kr.`,
                    swishAmountValidationError: `Ange ett belopp mellan ${minVal} kr och ${new Intl.NumberFormat('sv-SE').format(maxVal)} kr.`,
                    mobileValidationError: `Ditt mobilnummer verkar inte stämma, dubbelkolla numret och försök igen.`,
                    accountValidationError: "Ditt kontonummer verkar vara felaktigt, vill du fortsätta ändå?",
                    cancellationError: "Utbetalningen kunde inte avbrytas.",
                    latestWithdrawalCancelled: "Du har avbrutit det här uttaget.",
                    latestWithdrawalSuccess: "Uttaget är klart och pengarna har dragits från ditt Folkspelskonto.",
                    noWithdrawalStatus: "Vi kan inte hitta någon information om det här uttaget."
                };

            getWithdrawals();

            /*---------- UI ----------*/
            function isSectionOpen(element) {
                const section = element.parent().siblings(".collapse");
                return section.hasClass("in") ? true : false;
            };
            function toggleArrow(button) {
                const arrow = button.find(".fas.fa-chevron-down");
                isSectionOpen(button) && arrow.hasClass("flipped") ? arrow.removeClass("flipped") : arrow.addClass("flipped");
            };
            function toggleDisabled(input, disable) {
                const button = input.closest(".card-body").find(".withdrawal-button");
                button && !disable ? button.prop("disabled", false) : button.prop("disabled", true);
            };
            function toggleSpinner(show) {
                if (show) {
                    $spinner.show().css("opacity", 1);
                } else {
                    $spinner.css("opacity", 0).hide();
                }
            };
            function toggleFormElements(elements, show) {
                if (!show) {
                    $.each(elements, function (index, element) {
                        element.hide();
                    })
                } else if (show) {
                    $.each(elements, function (index, element) {
                        element.show();
                    })
                }
            };
            /*---------- VALIDATORS ----------*/
            function validateAmount(input) {
                const val = parseInt(input.val());
                const id = input.attr("id");

                if (id === "swishAmount") {
                    if (val >= minVal && val <= maxVal) {
                        toggleDisabled(input, false);
                        removeValidationError(input);
                    } else {
                        toggleDisabled(input, true);
                        showValidationError(input);
                    }
                }
                if (id === "bankAmount") {
                    if (val >= minVal) {
                        toggleDisabled(input, false);
                        removeValidationError(input);
                    } else {
                        toggleDisabled(input, true);
                        showValidationError(input);
                    }
                }
            };
            function validateMobileNumber(input) {
                const val = input.val().replace(/[-\s]/g, "");
                const regex = /^(\+|00)?\d{1,15}$/;

                if (val.length >= 8 && val.length <= 15 && regex.test(val)) {
                    const amountInput = input.parent().parent().find('.form-control.amount');
                    removeValidationError(input);
                    validateAmount(amountInput);
                } else {
                    toggleDisabled(input, true);
                    showValidationError(input);
                }

            };
            async function validateBankAccount() {
                //const accountNumber = `${$clearingNumber.val()}${$accountNumber.val()}`.replace(/\D/g, "");
                const clearingNumber = $clearingNumber.val().replace(/\D/g, "");
                const accountNumber = $accountNumber.val().replace(/\D/g, "");
                const amountInput = $clearingNumber.parent().parent().find('.form-control.amount');

                if (clearingNumber.length !== 0 || accountNumber.length !== 0) {
                    const data = {
                        accountNumber: clearingNumber + accountNumber
                    };
                    $.ajax({
                        url: `${Urls.EnterpriseApiRoot}wallet/v1/bankaccounts/validate`,
                        method: "POST",
                        contentType: "application/json",
                        headers: {
                            "Authorization": `Bearer ${user.token}`
                        },
                        data: JSON.stringify({
                            ...data
                        }),
                    }).done(function (res) {
                        if (res.result.valid) {
                            validateAmount(amountInput);
                            removeValidationError($clearingNumber);
                        } else {
                            //visa felmeddelande
                            showValidationError($clearingNumber);
                            validateAmount(amountInput);
                        };
                    }).fail(function (err) {
                        console.log(err);
                    });
                }
            };
            function showValidationError(input) {
                const validationError = $(`<p class="validation-error"></p>`);
                const existingValidationError = input.parent().find('.validation-error');
                if (existingValidationError.length === 0) {
                    input.parent().append(validationError);
                    switch (input.attr("id")) {
                        case "mobileNumber":
                            validationError.html(messages.mobileValidationError);
                            break;
                        case "bankAmount":
                            validationError.html(messages.bankAmountValidationError);
                            break;
                        case "swishAmount":
                            validationError.html(messages.swishAmountValidationError);
                            break;
                        case "clearingNumber":
                            validationError.addClass("validation-warning");
                            validationError.html(messages.accountValidationError);
                            break;
                    }
                };
            };
            function removeValidationError(input) {
                const existingValidationError = input.parent().find('.validation-error');
                existingValidationError.addClass("flip-out");
                setTimeout(function () {
                    existingValidationError.remove();
                }, 200);
            };

            /*---------- SWISH ----------*/
            async function hasRegisteredSwishNumber() {
                toggleSpinner(true);
                const mobileNumber = $("div.mobile-number");
                const number = $("div.mobile-number #userMobileNumber");

                if (user.mobile && !number.length > 0) {
                    const numberString = $("<p id='userMobileNumber'>").text(user.mobile);
                    mobileNumber.append(numberString);
                    $showMobileNumber.show();
                };

                if (!user.mobile) {
                    $mobileNumber.value = "";
                    $addMobileNumber.show();
                };
                toggleSpinner(false);
            };
            async function postSwishWithdrawal() {
                const data = {
                    mobile: $mobileNumber.is(":visible") ? $mobileNumber.val() : user.mobile,
                    amount: parseInt($amount.val()),
                    description: messages.withdrawalDescription
                };
                $.ajax({
                    url: `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/withdrawals/swish`,
                    method: "POST",
                    contentType: "application/json",
                    headers: {
                        "Authorization": `Bearer ${user.token}`
                    },
                    data: JSON.stringify({
                        ...data
                    }),
                }).done(function (res) {
                    const modalData = {
                        title: "Uttag med Swish",
                        imageUrl: $("img.payment-logo.swish").attr("src"),
                        imageAlt: "Swish logotyp",
                        body: messages.swishWithdrawalModal,
                    };
                    ModalHandler.ImageModal(modalData, function (complete) {
                        window.location.reload();
                    });
                }).fail(function (err) {
                    const modalData = {
                        title: "Något har gått fel",
                        iconClass: "fas fa-exclamation-circle",
                        body: err.responseJSON.message ? err.responseJSON.message : messages.errorDefault,
                    };
                    ModalHandler.ImageModal(modalData, function (complete) {
                        //window.location.reload();
                        toggleSpinner(false);
                        //validateNumberInput($swishAmount);
                        validateAmount($swishAmount);
                    });
                    console.log(err);
                });
                toggleSpinner(false);
            };

            /*---------- BANKWITHDRAWAL ----------*/
            async function hasRegisteredBankAccount() {
                toggleSpinner(true);
                $.ajax({
                    url: `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/bankaccounts`,
                    method: "GET",
                    contentType: "application/json",
                    headers: {
                        "Authorization": `Bearer ${user.token}`
                    },
                }).done(function (res) {
                    const accountNumber = $("div.show-bank-account div.account-number");
                    if (res.items.length <= 0) {
                        toggleFormElements([$(".add-bank-account"), $(".add-bank-account-information")], true);
                    } else if (!accountNumber.is(":visible")) {
                        const numberString = $(`<p class='account-string' id=${res.items[0].id}>`).text(res.items[0].accountNumber);
                        accountNumber.append(numberString);
                        toggleFormElements([$(".show-bank-account")], true);
                    }
                }).fail(function (err) {
                    const modalData = {
                        title: "Hämta bankkonto",
                        iconClass: "fas fa-exclamation-circle",
                        body: messages.errorDefault
                    };
                    ModalHandler.ImageModal(modalData, function (complete) {
                        window.location.reload();
                    });
                    console.log(err);
                });
                toggleSpinner(false);
            };
            async function saveBankAccount() {
                const clearingNumber = $clearingNumber.val().replace(/\D/g, "");
                const accountNumber = $accountNumber.val().replace(/\D/g, "");

                if (clearingNumber.length === 0 || accountNumber.length === 0) {
                    const modalData = {
                        title: "Spara bankkonto",
                        iconClass: "fas fa-exclamation-circle",
                        body: messages.errorDefault,
                    }
                    ModalHandler.ImageModal(modalData, function () {
                        toggleSpinner(false);
                    });
                } else {
                    ModalHandler.BankIdAuthenticateModal(function (result) {
                        if (result) {
                            $('#siteModal').modal('hide');
                            const data = {
                                accountNumber: clearingNumber + accountNumber
                            };
                            $.ajax({
                                url: `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/bankaccounts`,
                                method: "POST",
                                contentType: "application/json",
                                headers: {
                                    "Authorization": `Bearer ${user.token}`
                                },
                                data: JSON.stringify({
                                    ...data
                                }),
                            }).done(function (res) {
                                postBankWithdrawal(res.id);
                            }).fail(function (err) {
                                const modalData = {
                                    title: "Spara bankkonto",
                                    iconClass: "fas fa-exclamation-circle",
                                    body: err.responseJSON.Message ? err.responseJSON.Message : messages.errorDefault,
                                }
                                ModalHandler.ImageModal(modalData, function () {
                                    toggleSpinner(false);
                                });
                                console.log(err);
                            });
                        } else {
                            $('#siteModal').modal('hide');
                            const modalData = {
                                title: "Byt kontonummer",
                                iconClass: "fas fa-exclamation-circle",
                                body: messages.bankIdError
                            };
                            ModalHandler.ImageModal(modalData, function (complete) {
                                toggleSpinner(false);
                            });
                        };
                    });
                };
            };
            async function postBankWithdrawal(paymentId) {
                const data = {
                    bankAccountId: paymentId ? paymentId : $(".show-bank-account .account-number .account-string").attr("id"),
                    amount: parseInt($("input.amount.bankaccount").val()),
                    description: messages.withdrawalDescription
                };

                $.ajax({
                    url: `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/withdrawals/bankaccount`,
                    method: "POST",
                    contentType: "application/json",
                    headers: {
                        "Authorization": `Bearer ${user.token}`
                    },
                    data: JSON.stringify({
                        ...data
                    }),
                }).done(function (res) {
                    const modalData = {
                        title: "Banköverföring",
                        iconClass: "fas fa-university",
                        body: messages.bankWithdrawalModal
                    };
                    ModalHandler.ImageModal(modalData, function (completed) {
                        if (completed) {
                            location.reload();
                        }
                    });
                }).fail(function (err) {
                    const modalData = {
                        title: "Något har gått fel",
                        iconClass: "fas fa-exclamation-circle",
                        body: err.responseJSON.Message ? err.responseJSON.Message : messages.errorDefault,
                    }
                    ModalHandler.ImageModal(modalData, function (complete) {
                        toggleSpinner(false);
                        validateAmount($bankAmount);
                    });
                    console.log(err);
                });
            };
            async function deleteBankAccount(accountId) {
                //toggleSpinner(true);
                //const user = await getUser();
                $.ajax({
                    url: `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/bankaccounts/${accountId}`,
                    method: "DELETE",
                    contentType: "application/json",
                    headers: {
                        "Authorization": `Bearer ${user.token}`
                    },
                }).done(function (res) {
                    //toggleSpinner(false);
                    /*const modalData = {
                        title: "Ta bort bankkonto",
                        iconClass: "fas fa-check-circle",
                        body: "Ditt bankkonto är nu borttaget."
                    };
                    ModalHandler.ImageModal(modalData);
                    */
                }).fail(function (err) {
                    //toggleSpinner(false);
                    const modalData = {
                        title: "Byt bankkonto",
                        iconClass: "fas fa-exclamation-circle",
                        body: messages.errorDefault
                    };
                    ModalHandler.ImageModal(modalData, function (complete) {
                        toggleSpinner(false);
                        validateAmount($bankAmount);
                    });
                    console.log(err);
                });
                toggleSpinner(false);
            }

            /*---------- COMMON FUNCTIONS ----------*/
            async function getUser() {
                const token = await Utils.GetUserAccessToken();
                const userData = await Utils.GetOathData();

                const contactResponse = await fetch(`${Urls.EnterpriseApiRoot}entity/v1/Contacts/${userData.sub}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const contactData = await contactResponse.json();
                user.contactId = userData.sub;
                user.mobile = contactData.mobile;
                user.token = token;
                return userData;
            };
            async function getWithdrawals() {
                toggleSpinner(true);
                await getUser();
                const currentDate = new Date();
                const fromDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
                const withdrawalResponse = await fetch(`${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/withdrawals`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`,
                    },
                    data: JSON.stringify({
                        fromDate: fromDate.toISOString().slice(0, 10),
                    }),
                });
                const withdrawalData = await withdrawalResponse.json();
                if (withdrawalData.items.length > 0) {
                    const latestWithdrawal = withdrawalData.items[withdrawalData.items.length - 1];

                    if (latestWithdrawal.transactionStatus <= 30) {
                        showPendingWithdrawal(latestWithdrawal);
                    } else {
                        showLatestWithdrawal(latestWithdrawal);
                    }
                } else {
                    toggleSpinner(false);
                    $withdrawalOptionsWrapper.show();
                }
            };
            function showPendingWithdrawal(latestWithdrawal) {
                const withdrawalStatus = $(".withdrawal-status"),
                    withdrawalTo = $(".withdrawal-to");
                if (latestWithdrawal.isCancellable && !latestWithdrawal.cancellationRequested) {
                    $cancelWithdrawal.attr("value", latestWithdrawal.id);
                    $cancelWithdrawal.show();
                } else {
                    withdrawalStatus.text(messages.statusCancelled);
                    withdrawalTo.parent().hide();
                }
                if (latestWithdrawal.transactionStatus <= 30) {
                    createWithdrawalStatusMessage(latestWithdrawal);
                    createWithdrawalInformation(latestWithdrawal);
                    $withdrawalStatusWrapper.show();
                    pollWithdrawalStatus(latestWithdrawal);
                } else {
                    showLatestWithdrawal(latestWithdrawal);
                }
                toggleSpinner(false);
            };
            function createWithdrawalStatusMessage(withdrawal, description) {
                const withdrawalStatus = $(".withdrawal-status");
                const { transactionStatus, cancellationRequested } = withdrawal;
                if (!description) {
                    description = withdrawal.description;
                }
                if (!cancellationRequested) {
                    switch (transactionStatus) {
                        case 10:
                            withdrawalStatus.text(messages.statusInitialized);
                            break;
                        case 20:
                            withdrawalStatus.text(messages.statusAwaitingApproval);
                            break;
                        default:
                            if (description.includes("Swish")) {
                                withdrawalStatus.text(messages.statusApprovedSwish);
                            } else {
                                withdrawalStatus.text(messages.statusApprovedBank);
                            }
                            break;
                    }
                } else {
                    withdrawalStatus.text(messages.statusCancelled);
                }
            };
            async function pollWithdrawalStatus(latestWithdrawal) {
                const pollInterval = setInterval(async function () {
                    const withdrawalStatusData = await getWithdrawalStatus(latestWithdrawal.id);
                    //createWithdrawalStatusMessage(withdrawalStatusData, latestWithdrawal.description);
                    if (withdrawalStatusData.completed) {
                        clearInterval(pollInterval);
                        switch (withdrawalStatusData.status) {
                            case 10:
                            case 20:
                            case 30:
                                createWithdrawalStatusMessage(withdrawalStatusData, latestWithdrawal.description);
                                break;
                            case 40:
                                const modalData = {
                                    title: "Uttag",
                                    iconClass: "fas fa-coins",
                                    body: messages.statusSuccess
                                };
                                ModalHandler.ImageModal(modalData, function (completed) {
                                    location.reload();
                                });
                                break;
                            default:
                                window.location.reload();
                                break;
                        }
                    }
                }, 30000);
            };
            async function getWithdrawalStatus(transactionId) {
                const withdrawalStatusResponse = await fetch(`${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/transactions/${transactionId}/status`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`,
                    }
                });
                const withdrawalStatusData = await withdrawalStatusResponse.json();

                return withdrawalStatusData;
            };
            function showLatestWithdrawal(withdrawal) {
                const withdrawalStatus = $(".withdrawal-status");
                const statusIcon = $(".icon-wrapper i");
                $withdrawalOptionsWrapper.show();
                $latestWithdrawalWrapper.show();
                createWithdrawalInformation(withdrawal);
                switch (withdrawal.transactionStatus) {
                    case 40:
                        withdrawalStatus.text(messages.latestWithdrawalSuccess);
                        statusIcon.addClass("fa-check");
                        break;
                    case 50:
                        withdrawalStatus.text(messages.latestWithdrawalCancelled);
                        statusIcon.addClass("fa-exclamation");
                        break;
                    case 60:
                    case 70:
                        withdrawalStatus.addClass("alert alert-danger");
                        withdrawal.errorMessage ? withdrawalStatus.text(withdrawal.errorMessage) : withdrawalStatus.text(messages.errorDefault);
                        statusIcon.addClass("fa-times");
                        break;
                    default:
                        withdrawalStatus.text(messages.noWithdrawalStatus);
                        statusIcon.addClass("fa-question");
                        break;
                }
                toggleSpinner(false);
            };
            function createWithdrawalInformation(withdrawal) {
                const serverDateString = new Date(withdrawal.createdAt),
                    localDateString = getLocalDateTimeString(serverDateString),
                    withdrawalStarted = $(".withdrawal-started"),
                    withdrawalAmount = $(".withdrawal-amount"),
                    withdrawalTo = $(".withdrawal-to");
                withdrawalStarted.text(localDateString);
                withdrawalAmount.text(`${withdrawal.amount} kr`);
                withdrawalTo.text(withdrawal.description);
            }
            function getLocalDateTimeString(dateTime) {
                const localDate = convertToLocaleDate(dateTime);
                const formattedDate = `${localDate.getFullYear()}-${padZero(localDate.getMonth() + 1)}-${padZero(localDate.getDate())} ${(localDate.getHours())}:${padZero(localDate.getMinutes())}`;
                return formattedDate;
            };
            function convertToLocaleDate(date) {
                const offset = new Date().getTimezoneOffset();
                const offsetMilliseconds = offset * 60 * 1000;
                const localeTimestamp = date.getTime() - offsetMilliseconds;
                return new Date(localeTimestamp);
            };
            function padZero(value) {
                return value.toString().padStart(2, '0');
            };
            async function patchWithdrawal(transactionId) {
                $.ajax({
                    url: `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/withdrawals/${transactionId}`,
                    method: "PATCH",
                    contentType: "application/json",
                    headers: {
                        "Authorization": `Bearer ${user.token}`
                    },
                    data: JSON.stringify([{
                        op: "replace",
                        path: "/transactionstatus",
                        value: "cancelled"
                    }]),
                }).done(function (res) {
                    const modalData = {
                        title: "Avbryt uttag",
                        iconClass: "fas fa-check-circle",
                        body: messages.withdrawalCancelledModal
                    };
                    ModalHandler.ImageModal(modalData, function (complete) {
                        window.location.reload();
                    });
                }).fail(function (err) {
                    const modalData = {
                        title: "Avbryt uttag",
                        iconClass: "fas fa-exclamation-circle",
                        body: err.errorCode === 3040 ? messages.cancellationError : messages.errorDefault,
                    };
                    ModalHandler.ImageModal(modalData, function (complete) {
                        window.location.reload();
                    });
                    console.log(err);
                });
                toggleSpinner(false);
            };

            /*---------- EVENTLISTENERS ----------*/
            $paymentSelection.on("click", function (e) {
                e.preventDefault();
                toggleArrow($(this));
                if ($(this).attr("id") === "swish" && !isSectionOpen($(this))) {
                    if ($("#bankWithdrawal").hasClass("in") && $("#bank .fas.fa-chevron-down").hasClass("flipped")) {
                        $("#bankWithdrawal").removeClass("in");
                        $("#bank .fas.fa-chevron-down").removeClass("flipped")
                    }
                    hasRegisteredSwishNumber();
                } else if ($(this).attr("id") === "bank" && !isSectionOpen($(this))) {
                    if ($("#swishWithdrawal").hasClass("in") && $("#swish .fas.fa-chevron-down").hasClass("flipped")) {
                        $("#swishWithdrawal").removeClass("in");
                        $("#swish .fas.fa-chevron-down").removeClass("flipped")
                    }
                    hasRegisteredBankAccount();
                }
            });

            $amount.each(function () {
                $(this).on("input", function () {
                    validateAmount($(this));
                });
            });

            $withdrawal.on("click", function (e) {
                e.preventDefault();
                $(this).prop("disabled", true);
                toggleSpinner(true);
                if ($(this).attr("id") === "swishWithdrawal") {
                    postSwishWithdrawal();
                } else if ($(this).attr("id") === "bankWithdrawal") {
                    $(".add-bank-account").is(":visible") ? saveBankAccount() : postBankWithdrawal();
                }
            });

            $changeMobileNumber.on("click", function () {
                $(".add-mobile-number").show();
                $(".show-mobile-number").hide();
                $withdrawal.prop("disabled", true);
            });

            $mobileNumber.each(function () {
                $(this).on("input", function () {
                    validateMobileNumber($(this));
                });
            });

            $changeBankAccount.on("click", function () {
                toggleSpinner(true);
                const accountId = $(".show-bank-account .account-number .account-string").attr("id");
                $(".show-bank-account").hide();
                $(".add-bank-account").show();
                deleteBankAccount(accountId);
            });

            $closeWithdrawalStatus.on("click", function () {
                location.reload();
            });

            $cancelWithdrawal.on("click", function () {
                toggleSpinner(true);
                $(this).prop("disabled", true);
                patchWithdrawal($(this).attr("value"));
            });
            $swishInformationPopover.on("click", function () {
                const swishNumberInformationList = $(".swish-number-information-wrapper .swish-number-information-popover ul");
                swishNumberInformationList.is(":visible") ? swishNumberInformationList.hide() : swishNumberInformationList.show();
            });

            $("input#clearingNumber").on("input", function () {
                const value = $(this).val().replace(/[^0-9]/g, '');
                $(this).val(value);
                if (value.length > 0) {
                    setTimeout(function () {
                        validateBankAccount();
                    }, 800);
                };
            });

            $("input#accountNumber").on("input", function () {
                const value = $(this).val().replace(/[^0-9]/g, '');
                $(this).val(value);
                if (value.length > 0) {
                    setTimeout(function () {
                        validateBankAccount();
                    }, 800);
                };
            });
        });
    }
});