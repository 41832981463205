jQuery.validator.addMethod("username", function (value, element) {
    var $elem = $(element);
    if (/^[0-9]+$/.test($elem.val())) {
        return false;
    }
    return true;
});

jQuery.validator.addMethod("socialsecuritynumber", function (value, element) {
    var $elem = $(element);
    $elem.val($elem.val().replace(/[^0-9]/, ""));
    $elem.val($elem.val().replace(/^(?!20|19|18)(.{10,})$/, "19$1"));
    var valid = ValidationHandler.ValidateSocialSecurityNumber($elem.val());
    $elem.trigger("validated", [valid]);
    return valid;
});

jQuery.validator.addMethod("booleanischecked", function (value, element) {
    return /true/ig.test((typeof (value) === "undefined" ? "false" : value));
});

jQuery.validator.addMethod("requiredif", function (value, element, parameters) {
    var dependentProperty = parameters["dependentproperty"];
    var dependentId = "".concat("[id$='", dependentProperty, "']");
    // get the target value (as a string, as that's what actual value will be)
    var targetValue = parameters["targetvalue"];
    var targetValueArr = (targetValue == null ? "" : targetValue).toString().split("|");

    for (var i = 0; i < targetValueArr.length; i++) {
        // get the actual value of the target control, note - this probably needs to cater for more, control types, e.g. radios
        var $control = jQuery(dependentId);
        if ($control.length === 0) {
            dependentId = "".concat("[id$='", dependentProperty, "_", targetValue, "']");
            $control = jQuery(dependentId);
        }
        if ($control.length === 0) continue;
        var controlType = $control.attr("type");
        var actualValue = controlType === "checkbox" ? $control.is(":checked").toLowerCase() : $control.val();
        if (controlType === "radio" && !$control.is(":checked")) actualValue = null;

        // if the condition is true, reuse the existing required field validator functionality
        if (targetValueArr[i] === actualValue) {
            return $.validator.methods.required.call(this, value, element, parameters);
        }
    }
    return true;
});

jQuery.validator.unobtrusive.adapters.addBool("username");
jQuery.validator.unobtrusive.adapters.addBool("socialsecuritynumber");
jQuery.validator.unobtrusive.adapters.addBool("booleanischecked");
jQuery.validator.unobtrusive.adapters.add("requiredif", ["dependentproperty", "targetvalue"], function (options) {
    options.rules["requiredif"] = { dependentproperty: options.params["dependentproperty"], targetvalue: options.params["targetvalue"] };
    options.messages["requiredif"] = options.message;
});
