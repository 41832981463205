$.widget("custom.clickable", {
    options: {
        url: "",
        tab: false
    },
    _create: function() {
        var $widget = this,
            $element = $widget.element;

        $element.click(function(e) {
            e.preventDefault();
            if ($widget.options.url === "") return;

            if ($widget.options.tab) { 
                window.open($widget.options.url);
            } else {
               location.href = $widget.options.url;
            }
            return;
        });
    }
});