$.widget("custom.articlebanner", {
    options: {
    },
    _create: function () {
        var $widget = this,
            $this = $widget.element;

        $.ajax({
            url: Urls.GetPromotional,
            dataType: "html",
            cache: false,
            success: function(data) {
                $this.html(data);
            }
        });
    }
});
