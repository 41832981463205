//import { createWidget } from '@typeform/embed'
//import '@typeform/embed/build/css/widget.css'

$.widget("custom.typeform", {
    options: {
        formid: '',
        adduserinfo: false
    },
    _init: function () {
        var $widget = this,
            $element = $widget.element;

        $element.click(function () {
            window.fsForms.createForm($widget.options.formid, $widget.options.adduserinfo);
        });





 /*       Window.fstypeform({ id:'asdasd' type:'asdasd' form:'asdasd'})*/
    }
});



/*y7REg4qq*/