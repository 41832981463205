$.widget("custom.radiocheck", {
    options: {
    },
    _create: function () {
        var $widget = this,
          $element = $widget.element;

        $element.click(function (e) {
            e.preventDefault();
            if ($element.hasClass("disabled"))
                return;
            var $input = $element.find("input");
            if ($input.prop("checked")) {
                if ($input.attr("type") === "checkbox") {
                    $input.prop("checked", false);
                    $input.parent().removeClass("active");
                }
            }
            else {
                if ($input.attr("type") === "radio") {
                    var group = "input:radio[name='" + $input.attr("name") + "']";
                    $(group).each(function () {
                        $(this).prop("checked", false);
                        $(this).parent().removeClass("active");
                    });
                }
                $input.prop("checked", true);
                $input.parent().addClass("active");
            }
            $input.change();
        });
    }
});