import bootbox from "bootbox";
import { Spinner } from "spin.js";

$.widget("custom.mypagessubscription", {
    options: {

    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $carddropdown = $("select", $element);

        $(".btn-danger", $element).click(function () {
            bootbox.confirm({
                size: "small",
                buttons: {
                    confirm: {
                        label: $widget.options.confirm,
                        className: 'btn-success'
                    },
                    cancel: {
                        label: $widget.options.cancel,
                        className: 'btn-danger'
                    }
                },
                message: $widget.options.message,
                callback: function(result) {
                    if (result) $element.submit();
                }
            });
        });

        $("button[type='submit']", $element).click(function () {
            if ($(this).attr("name") == "subaction") {
                $("#subaction", $element).val($(this).val());
            }
        });

        $(".addcard", $element).click(function (e) {
            e.preventDefault();
            var $this = $(this),
                data = {
                    method: $this.data("method"),
                    setDefault: $this.data("setdefault")
                },
                spinner = new Spinner(DefaultOptions.Spinner).spin($element.get(0)),
                methodInfo = $this.data("method").split(";");

            if (methodInfo[2] == "WyWallet") {
                window.location.href = ("/?rmp=1&setdefault=" + $this.data("setdefault") + "&method=" + $this.data("method"));
            }
            else {
                AjaxHandler.Post(Urls.RegisterSubscriptionPayment, data).then(
                    function (data, textStatus, xhr) {
                        window.location.href = data;
                    },
                    function (jqXHR, textStatus, errorThrown) {
                        bootbox.alert({
                            size: "small",
                            message: "Tekniskt fel."
                        });
                    });
            }
        });


        var setDeleteButtonState = function () {
          if ($carddropdown.length === 0) return;
            var selectedCard =  $carddropdown.val().split(";")[0];
            var defaultCards =  $carddropdown.data("defaultsubscriptions").toString().split(";");
            if ($.inArray(selectedCard, defaultCards) > -1) {
                $(".btn-danger", $element).hide();
            }
            else {
                $(".btn-danger", $element).show();
            }
        };

        $carddropdown.change(setDeleteButtonState);

        setDeleteButtonState();

    }
});