import { Spinner } from "spin.js";

$.widget("custom.invoicelist", {
    options: {
        amounttext: "", 
        datetext: "", 
        ocrtext: "", 
        statustext: "", 
        pdftext: ""
    },
    _create: function () {
        var $widget = this;
        var spinner = new Spinner(DefaultOptions.Spinner);
        var extraInvoicesList = [];

        spinner.spin($widget.element.get(0));

        $.ajax({
            url: "/MyPages/GetInvoices",
            type: "GET",
            contentType: "application/json",
            dataType: "json",
            success: function (response) {
                if (response == null) {
                    errorGettingInvoices();
                }

                for (var i = 0; i < response.length; i++) {
                    if (i < 3) {
                        $('#invoiceTable').append(formatRow(response[i]));
                    } else {
                        $('#showMoreInvoices').show();
                        extraInvoicesList.push(formatRow(response[i]));
                    }
                }

                spinner.stop();
                if (response.length > 0) {
                    $('#myInvoices').show();
                } else {
                    $('#noInvoices').show();
                }
            },
            error: function (jqXhr, textStatus, errorThrown) {
                console.log("Fel vid hämtning av fakturor", errorThrown);
                errorGettingInvoices();
            }
        });

        $('#showMoreInvoices', $widget.element).click(() => {
            for (var i = 0; i < extraInvoicesList.length; i++) {
                $('#invoiceTable').append(extraInvoicesList[i]);
            }
            $('#showMoreInvoices').hide();
        });

        function errorGettingInvoices() {
            spinner.stop();
            $('#errorGettingInvoices').show();
            $('#noInvoices').hide();
            $('#myInvoices').hide();
        }

        var amountText = $widget.options.amounttext;
        var dateText = $widget.options.datetext;
        var ocrText = $widget.options.ocrtext;
        var statusText = $widget.options.statustext;
        var pdfText = $widget.options.pdftext;

        //For adding rows to your table
        function formatRow(rowData) {
            console.log(rowData.OCRNumber);
            //create each TD with the data you need. Can be done in one big line. If you're a monster.
            //The data-label property displays the column label in mobile
			var amountCell = '<td data-label="' + amountText + '"><span>' + rowData.InvoiceAmount + ' kr' + '</span></td>';
			var dateCell = '<td data-label="' + dateText + '"><span>' + rowData.InvoiceDueDateString + '</span></td>';
            var ocrCell = '<td data-label="' + ocrText + '"><span class="shrinkLongText">' + rowData.OCRNumber + '</span></td>';
            var statusCell;
            if (rowData.PaymentStatus === "Betald") {
				statusCell = '<td data-label="' + statusText + '" class="icon-cell" title"' + rowData.PaymentStatus + '"><span><i class="far fa-check-circle table-icon green-text"></i></span></td>';
            } else {
				statusCell = '<td data-label="' + statusText + '" class="center-text">' + rowData.PaymentStatus + '</td>';
            }
			var pdfCell = '<td data-label="' + pdfText + '" class="icon-cell"><a href="/MyPages/GetInvoicePdf?invoiceID=' + rowData.InvoiceID + '"><span><i class="fas fa-file-pdf table-icon" style="padding-left: 4px;"></i></span></a></td>'
            //return the completed row
            return '<tr>' + amountCell + dateCell + ocrCell + statusCell + pdfCell + '</tr>';
        }
    }
});