$.widget("custom.subscriptionoffer", {
    options: {
        
    },
    _create: function () {
        var $widget = this,
            $form = $widget.element,
            $offergroup = $(".group", $form),
            $allofferbuttons = $(".offer .btn", $offergroup);

        $(".cs-placeholder, ul li", $form).click(function (e) {
            e.stopPropagation();
        });

        $allofferbuttons.click(function (e) {
            e.preventDefault();

            var $this = $(this),
                $item = $this.parents(".offer"),
                $offergroup = $item.parents(".group"),
                $groupofferitems = $(".offer", $offergroup);

            if (!$item.hasClass("selected")) {
                if (!$offergroup.data("allowmultiselect")) {
                    $groupofferitems.not(".required").removeClass("selected");
                    $("input[type='checkbox']", $groupofferitems.not(".required")).prop("checked", false);
                    if ($(".offer.selected", $offergroup).length == 0) {
                        $("input[type='checkbox']", $item).prop("checked", true);
                        $("select", $item).removeAttr("disabled");
                        $("select:first", $item).val("1").change();
                        $item.addClass("selected");
                    }
                }
                else {
                    $("input[type='checkbox']", $item).prop("checked", true);
                    $("select", $item).removeAttr("disabled");
                    $("select:first", $item).val("1").change();
                    $item.addClass("selected");
                }
            }
            else if (!$item.hasClass("required")) {
                $("input[type='checkbox']", $item).prop("checked", false);
                $item.removeClass("selected");
            }

            $groupofferitems.each(function () {
                if (!$(this).hasClass("selected")) {
                    $("select", $(this)).val("0").change();
                    $("select", $(this)).attr("disabled", "disabled");
                    $(".btn i", $(this)).removeClass("fas fa-check-square").addClass("far fa-square");
                }
                else {
                    $(".btn i", $(this)).removeClass("far fa-square").addClass("fas fa-check-square");
                }
            });
        });
    }
});