$.widget("custom.mypagesopenticket", {
    options: {
    },
    _create: function () {

        $(document).ready(function () {


            $('.OpenScratchTicketButton').click(function () {
                var scratchTicket = $(this).data('ticketid');

                window.fsTickets.showGameClient({
                    ticketIds: [scratchTicket]
                })
            })

            let hasRedeemed = false;

            window.fsEvents.on('tickets.redeem.success', () => {

                hasRedeemed = true;

            });

            window.fsEvents.on('tickets.gameclient.hidden', () => {

                if (hasRedeemed) {

                    location.reload();

                }

            });

        });


    }
});