$.widget("custom.depositinline", {
    options: {
        returnurl: "/",
        depositamount: 50,
        isloggedin: false,
        usernyxsessionavailable: false,
        loginmessage: ""
    },
    _create: function () {
        var $widget = this,
            $this = $widget.element;

        if ($widget.options.isloggedin) {
            $this.spin();
            if ($widget.options.usernyxsessionavailable) {
                $.ajax({
                    url: Urls.Deposit,
                    dataType: "html",
                    contentType: "application/json; charset=utf-8",
                    data: {
                        returnurl: $widget.options.returnurl,
                        depositamount: $widget.options.depositamount,
                        hideheader: true,
                        mediumform: true
                    },
                    cache: false,
                    success: function (data) {
                        var $element = $(data);
                        FormHandler.InitForms($element);
                        WidgetHandler.InitWidgets($element);
                        TabHandler.InitTabs($element);
                        $this.append($element).spin(false);
                    }
                });
            }
        } else {
            $("button", $this).click(function (e) {
                e.preventDefault();
                ModalHandler.AuthenticateModal();
            });
        }
    }
});