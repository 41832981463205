import Purchase from './Purchase';
import GameClient from './GameClient';
import camelcaseKeys from 'camelcase-keys';

/**
 * @enum {string}
 */
const SuccessAction = {
    OPEN_GAME_CLIENT: 'openGameClient',
    CLOSE: 'close',
    RELOAD: 'reload',
    REDIRECT: 'redirect',
}

class Tickets {
    currentPurchase = null;
    currentGameClient = null;

    constructor() {
        // Purchase events.
        window.fsEvents.on('tickets.purchase.error', () => {
            this.currentPurchase = null;
        });
        window.fsEvents.on('tickets.purchase.cancel', () => {
            this.currentPurchase = null;
        });
        window.fsEvents.on('tickets.purchase.success', () => {
            this.currentPurchase = null;
        });
        // Gameclient events.
        window.fsEvents.on('tickets.gameclient.hidden', () => {
            this.currentGameClient = null;
        });
        window.fsEvents.on('tickets.gameclient.error', () => {
            this.currentGameClient = null;
        });
    }

    /**
     * @param {string} purchases[].roundArticle - The roundArticle key.
     * @param {number} purchases[].quantity - Quanity of tickets to purchase.
     * @param {Object} purchases[].data - Extra data sent to the game client.
     * @param {string} commission.presets - The commissions set for the purchase.
     * @param {string} commission.presets[].id - Commission id.
     * @param {string} commission.presets[].title - Commission title.
     * @param {string} commission.userPresets - The commissions set for the user.
     * @param {string} commission.userPresets[].id - Commission id.
     * @param {string} commission.userPresets[].title - Commission title.
     * @param {boolean} commission.getUserCommissionIfEmptyPreset - Fetch commissions if empty.
     * @param {SuccessAction} success.action - On success action.
     * @param {string} success.redirect - Where to redirect if action is redirect.
     * @param {Object} success.params - Params to be sent to client.
     */
    purchase({ purchases, commission, success }) {
        if (this.currentPurchase) {
            return;
        }
        this.currentPurchase = new Purchase(purchases, commission, success);

        window.fsEvents.once('tickets.purchase.success', (event) => {
            const ticketIds = event.detail.purchase.tickets.map(ticket => ticket.key);

            switch (success?.action) {
                case SuccessAction.OPEN_GAME_CLIENT:
                    this.showGameClient({ ticketIds });
                    break;
                case SuccessAction.REDIRECT:
                    window.location.href = success.redirect;
                    break;
                case SuccessAction.REFRESH:
                    window.location.reload();
                    break;
            }
        });
    }

    /**
     * @param {Object} [payload] - Game client payload.
     * @param {string[]} [payload.roundArticleDemo] - Demo articles.
     * @param {string[]} [payload.ticketIds] - Array of ticket keys.
     * @param {string} [payload.roundKey] - Round key.
     * @param {Object} [payload.params={}] - Additional data to send to game client.
     */
    showGameClient(payload) {
        if (this.currentGameClient) {
            return;
        }
        this.currentGameClient = new GameClient({
            roundArticleDemo: null,
            ticketIds: null,
            roundKey: null,
            params: {},
            ...payload
        });
    }

    /**
     * @param {string} ticketKey - The ticket key to redeem.
     */
    async redeem(ticketKey) {
        const redeemResponse = await fetch(Urls.RedeemTicket,
            {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    payload: ticketKey,
                })
            });
        if (!redeemResponse.ok) {
            throw redeemResponse.status;
        }
        const redeemData = await redeemResponse.json();


        if (!redeemData.ticket) {
            window.fsEvents.emit('tickets.redeem.error', { redeemData });
            throw redeemData.message;
        }

        if (redeemData.ticket.hasOwnProperty('ticketInfo') && redeemData.ticket.ticketInfo) {
            redeemData.ticket.ticketInfo = camelcaseKeys(JSON.parse(redeemData.ticket.ticketInfo), {
                deep: true,
                exclude: [/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/]
            });
        }

        window.fsEvents.emit('tickets.redeem.success', { redeemData });
    }
};

window.fsTickets = new Tickets();
