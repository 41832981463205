import * as AppSearch from './AppSearch';

class Search {

    associationClient = null;

    constructor() {
        //Catch events from searchclients here if we need to do something "globally"
    }

    searchAssociation(searchText) {
        if (this.associationClient == null) this.associationClient = new AppSearch.AssociationSearch();
        return this.associationClient.search(searchText);
    }


};

window.fsSearch = new Search();
