$.widget("custom.youtubeplayer", {
    options: {
        frameid: "",
        buttontext: ""
    },
    _init: function() {
        var $widget = this,
            $element = $widget.element,
            $overlay = $(".block-overlay", $element),
            $button = $("<button />").attr({ type: "button", class: "btn btn-default btn-sm" })
                .text($widget.options.buttontext);

        $button.click(function(e) {
            e.preventDefault();
            window.youtubeplayers[$widget.options.frameid].playVideo();
        });

        var interval = setInterval(checkForYoutubeApi, 100);

        function checkForYoutubeApi() {
            if (window.youtubeplayers && window.youtubeplayers[$widget.options.frameid] && window.youtubeplayers[$widget.options.frameid].playerready) {
                clearInterval(interval);
                $overlay.append($button);
            }
        }
    }
});