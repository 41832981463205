const STORAGE_VERSION = '1';
const STORAGE_TYPE_SESSION = 'sessionStorage';

class Storage {

    constructor(storageType, storageVersion) {
        switch (storageType) {
            case 'sessionStorage':
                break;
            default:
                throw new Error(`Storage failed to initialize storage type ${storageType}. Not supported.`);
        }
        this.storageType = storageType;
        this.storageVersion = storageVersion;
    }

    set(key, value, ttl = 0) {
        const storageKey = `${key}${this.storageVersion}`;

        let time = null;
        if (ttl > 0) {
            let timeEpoch = new Date();
            timeEpoch.setSeconds(timeEpoch.getSeconds() + ttl);
            time = Math.round(timeEpoch.getTime() / 1000)
        }

        const data = JSON.stringify({ value, time });

        switch (this.storageType) {
            case STORAGE_TYPE_SESSION:
                sessionStorage.setItem(storageKey, data);
                break;
            default:
                throw new Error(`Storage get failed on storage type ${storageType}. Not supported.`);
        }

        window.fsEvents.emit('storage.set', { key, data: value });
    }

    get(key, defaultValue = null) {
        const storageKey = `${key}${this.storageVersion}`;

        let data;
        switch (this.storageType) {
            case STORAGE_TYPE_SESSION:
                data = JSON.parse(sessionStorage.getItem(storageKey));
                break;
            default:
                throw new Error(`Storage get failed on storage type ${storageType}. Not supported.`);
        }

        if (data && data.time) {
            const timeEpoch = Math.round(new Date().getTime() / 1000);
            let ttl = data.time - timeEpoch;
            if (ttl <= 0) data = null;
        }

        if (!data) return defaultValue;
        return data.value;
    };

    getOrSet(key, value, ttl = 0) {
        var storedValue = this.get(key);
        if (!storedValue) {
            this.set(key, value, ttl);
        }
        return value;
    }

    removeItem(key) {
        const storageKey = `${key}${this.storageVersion}`;
        switch (this.storageType) {
            case STORAGE_TYPE_SESSION:
                sessionStorage.removeItem(storageKey);
                break;
            default:
                throw new Error(`Storage remove failed on storage type ${storageType}. Not supported.`);
        }

        window.fsEvents.emit('storage.remove', { key });
    }

    clear() {
        switch (this.storageType) {
            case STORAGE_TYPE_SESSION:
                sessionStorage.clear();
                break;
            default:
                throw new Error(`Storage remove failed on storage type ${storageType}. Not supported.`);
        }
        window.fsEvents.emit('storage.clear');
    }

}

window.fsStorage = new Storage('sessionStorage', STORAGE_VERSION);
