import Cookies from "js-cookie";

$.widget("custom.scratchticketchooser", {
    options: {
        openatreload: false
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $scratchModal = $("#scratchModal"),
            $scratchContent = $(".content", $scratchModal);

        if ($widget.options.openatreload) {
            $element.ticketchooseropenscratch();
        }

        $(".gamepreview .scratchticket a[data-purchase]", $element).click(function (e) {
            e.preventDefault();

            $scratchModal.off("show.bs.modal", scratchTicketChooserDisplayTicket).off("hidden.bs.modal", scratchTicketChooserHideModal).on("hidden.bs.modal", scratchTicketChooserHideModal);
            $scratchContent.removeAttr("style");
            Cookies.set("scratchticket-number", $(this).data("number"));

            if (!Utils.UserIsLoggedIn()) {
                $element.ticketchooseropenlogin();
                return;
            } else {
                $element.ticketchoosercheckaccount();
            }
        });

        $(".gamepreview .scratchticket a[data-skinid][data-skinname]", $element).click(function(e) {
            e.preventDefault();
            var $self = $(this);

            $scratchModal.off("hidden.bs.modal", scratchTicketChooserHideModal);

            var opts = {
                assets: $self.data("skinname"),
                gameid: $self.data("skinid"),
                price: 0,
                validateprice: false,
                eventcallback: function(event, params) {
                    $scratchModal.modal("hide");                   
                },
                loaded: function() {
                    $scratchContent.ticketchooserclose();
                }
            };

            $scratchModal.off("show.bs.modal", scratchTicketChooserDisplayTicket).on("show.bs.modal", opts, scratchTicketChooserDisplayTicket).modal("show");
            $scratchContent.removeClass("content-deposit content-login content-askifdeposit")
                .addClass("content-scratchticket").empty();
        });
    }
});

function scratchTicketChooserHideModal() {
    Cookies.remove("scratchticket-number");
    if ($("button.btn-mybingolotto").length === 0) {
        location.reload();
    } else {
        $(document).trigger("updatemybalance");
    }
}

function scratchTicketChooserDisplayTicket(e) {
    setTimeout(function() {
        var $scratchModal = $("#scratchModal"),
            $scratchContent = $(".content", $scratchModal);
        $scratchContent.spin().scratchticketclient(e.data);
    }, 100);
}


(function($) {
    var $scratchModal = $("#scratchModal"),
        $scratchContent = $(".content", $scratchModal);

    $(window).on("beforeunload", function() {
        Cookies.remove("scratchticket-number");
    });

    function urlparams(options) {
        var params = "";
        for (var name in options) {
            if (!options.hasOwnProperty(name)) continue;
            params = Utils.AddUrlParam(params, name, options[name]);
        }
        return params;
    }

    $.fn.ticketchooserclose = function() {
        return this.each(function() {
            var $self = $(this).prepend($("<button type='button' class='close' data-dismiss='modal' aria-label='Close'><i class='far fa-times-circle fa-lg'></i></button>"));
            $("[data-dismiss]", $self).click(function(e) {
                e.preventDefault();
                $scratchModal.modal("hide").removeAttr("style");
            });
        });
    };

    $.fn.ticketchooseropenlogin = function() {
        return this.each(function() {
            var $element = $(this);
            ModalHandler.AuthenticateModal(window.location.href);
        });
    };

    $.fn.ticketchoosercheckaccount = function() {
        return this.each(function() {
            var $element = $(this);

            Utils.GetPurchaseTicket($scratchContent, $element.data("widget-price"), $element.data("widget-gameid")).then(function() {
                $element.ticketchooseropenscratch();
            }, function(loggedout) {
                if (loggedout) {
                    $element.ticketchooseropenlogin();
                } else {
                    $element.ticketchooseropenaskifdeposit($element.data("widget-price"));
                }
            });
        });
    };

    $.fn.ticketchooseropendeposit = function() {
        return this.each(function() {
            var $element = $(this);

            $scratchModal.off("show.bs.modal", scratchTicketChooserDisplayTicket).modal("show");
            $scratchContent.removeClass("content-login content-askifdeposit content-scratchticket").addClass("content-deposit").empty().spin();

            Utils.LoadContent($scratchContent, Urls.Deposit +
                urlparams({ returnUrl: encodeURIComponent(location.href), depositAmount: 25, scratchticket: true, hideremovebutton: true, openafterid: $element.attr("id") }),
            function($content) {
                $content.ticketchooserclose();
                $scratchContent.spin(false);
            }, true);
        });
    };

    $.fn.ticketchooseropenscratch = function() {
        return this.each(function() {
            var $element = $(this);

            var options = {
                assets: $element.data("widget-assets"),
                gameid: $element.data("widget-gameid"),
                price: $element.data("widget-price"),
                validateprice: $element.data("widget-validateprice")
            };

            var opts = $.extend({
                eventcallback: function(event, params) {
                    if (event === "gamecompleted" && !params.demoflag) {
                        $scratchModal.modal("hide");
                    }
                },
                loaded: function() {
                    $scratchContent.ticketchooserclose();
                }
            }, options);

            $scratchModal.off("show.bs.modal", scratchTicketChooserDisplayTicket).on("show.bs.modal", opts, scratchTicketChooserDisplayTicket).modal("show");
            $scratchContent.removeClass("content-deposit content-login content-askifdeposit")
                .addClass("content-scratchticket").empty();
        });
    };

    $.fn.ticketchooseropenaskifdeposit = function(ticketPrice) {
        return this.each(function() {
            var $element = $(this);

            $scratchModal.off("show.bs.modal", scratchTicketChooserDisplayTicket).modal("show");
            $scratchContent.removeClass("content-deposit content-login content-scratchticket").addClass("content-askifdeposit").empty().spin();
            Utils.LoadContent($scratchContent, Urls.DepositTicketFlow, function($content) {
                $(".btn-primary", $content).click(function() {
                    window.location.href = (window.location.origin + "/mina-sidor/?page=%2fMyPages%2fDeposit" + "&amount=" + ticketPrice + "&returnUrl=" + window.location.href);
                });
                $content.ticketchooserclose();
                $scratchContent.spin(false);
            }, true);
        });
    };
})(jQuery);