(function ($) {
    var backButton = true;

    $.fn.tabHandlerClick = function (groupName) {
        var $self = $(this),
            targetTab = $self.data("tab-target"),
            $target = $("#" + targetTab),
            targetUrl = $self.data("tab-url"),
            group = $self.data("tab-group") || groupName,
            backbutton = $self.data("tab-backbutton"),
            silentclose = typeof ($self.data("tab-slientclose")) === "boolean",
            tablinks = "div.navbar-mybingolotto ul.nav-tabs",
            $back = $(tablinks + " li[role='group'] a").parent(),
            $tabcontent = $("#mybingolotto div.tab-content"),
            currentgroup = $(".navbar-mybingolotto ul.nav-tabs:visible").data("tab-group"),
            mybingolottoopen = $("body").hasClass("mybingolotto-open");

        if ($self.parent().hasClass("active") || (silentclose && currentgroup === group && mybingolottoopen)) return;
        if (targetTab === "" || $target.length === 0 || targetUrl === "") return;
        var tracking = $target.data("tab-tracking");

        $target.empty();

        if (typeof (backbutton) === "boolean") {
            backButton = backbutton;
        }

        if (typeof (group) === "string") {
            $(tablinks).addClass("hidden");
            $(tablinks + "[data-tab-group='" + group + "']").removeClass("hidden");
        }

        $("div.tab-pane", $tabcontent).removeClass("active");
        $("div.tab-pane[id='" + targetTab + "']", $tabcontent).addClass("active");

        Utils.LoadContent($target, targetUrl, false, true).always(function () {
            if (backButton) {
                $back.removeClass("hidden");
            } else {
                $back.addClass("hidden");
            }

            $(tablinks + " li").removeClass("active");
            $(tablinks + " li a[href$='#" + targetTab + "']").parent().addClass("active");

            if ($("#page-wrapper .navbar").length > 0) {
                $("#page-wrapper .navbar").data("bs.affix").options.offset.top = $("#page-wrapper").offset().top;

                setTimeout(function () {
                    $("#page-wrapper .navbar").data("bs.affix").options.offset.top = $("#page-wrapper").offset().top;
                }, 100);
            }

        }).done(function () {
            if (tracking) {
                if (typeof (dataLayer) !== "undefined") {
                    dataLayer.push({ "event": "virtualPageView", "virtualPage": tracking });
                } else if (typeof (ga) !== "undefined") {
                    ga("send", "pageview", tracking);
                }
            }
        });
    };

    window.TabHandler = $.extend(window.TabHandler, {}, {
        TabClick: function (e) {
            $(this).tabHandlerClick();
        },

        InitTabs: function ($element) {
            $("[data-tab-target]", $element)
                .off("click", TabHandler.TabClick)
                .on("click", TabHandler.TabClick);
        }
    });
})(jQuery);