$.widget("custom.loginbutton", {
    options: {
        link: "",
        content: "",
        isloggedin: false
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element;

        $element.click(function (e) {
            if ($widget.options.isloggedin) return;
            e.preventDefault();
            ModalHandler.AuthenticateModal($widget.options.link, $widget.options.content);
        });
    }
});