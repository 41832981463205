import camelcaseKeys from 'camelcase-keys';

const SHOW_GAME_CLIENT_DELAY = 1_000;
const BACKDROP_DURATION = 600;
const SCRATCH_PRODUCTS = ['0002', '0012', '0013'];

export default class GameClient {
    tickets = [];
    gameClientData = [];

    constructor(payload) {
        this.showBackdrop();

        const currentTime = new Date().getTime();

        this.checkAuth(payload.roundArticleDemo != null)
            .then(() => this.fetchTicketData(payload))
            .then(() => this.fetchGameClientData(this.tickets[0].roundKey))
            .then(() => this.fetchClientManifest(this.gameClientData.productNumber))
            .then((manifest) => new Promise((resolve, reject) => {
                this.addGameClient();
                this.loadScript(manifest.files['main.js'], () => {
                    // scratch client
                    if (SCRATCH_PRODUCTS.includes(this.gameClientData.productNumber)) {
                        window.fsEvents.once('tickets.gameclient.hide', () => {
                            this.hideBackdrop();
                        });
                        window.fsEvents.once('tickets.gameclient.hidden', () => {
                            this.removeGameClient();
                        });
                        window.fsEvents.once('tickets.gameclient.show', () => {
                            this.hideBackdropLoader();
                        });

                        const showGameClientCallback = () => new Promise((resolve) => {
                            const remainingDelay = SHOW_GAME_CLIENT_DELAY - (new Date().getTime() - currentTime);
                            setTimeout(() => {
                                resolve();
                            }, Math.max(remainingDelay, 0))
                        });

                        try {
                            window.initializeScratchy(
                                this.tickets,
                                this.gameClientData,
                                payload.roundArticleDemo != null,
                                showGameClientCallback,
                            );
                            resolve();
                        } catch (e) {
                            console.error(e);
                            reject(e)
                        }

                    }
                });
            }))
            .catch((error) => {
                this.hideBackdrop();
                this.removeGameClient();
                window.fsEvents.emit('tickets.gameclient.error', { gameClientData: this.gameClientData, tickets: this.tickets });
                if (error === 'auth') {
                    ModalHandler.AuthenticateModal(window.location);
                    return;
                }
                if (error === 'nyx') {
                    ModalHandler.AlertModal('Du kan för tillfället inte spela detta spel.', 'OK');
                    return;
                }
                ModalHandler.AlertModal('Ett tekniskt fel inträffade, var god försök igen.', 'OK');
            });
    }

    checkAuth(isDemo = false) {
        return new Promise((resolve, reject) => {
            if (isDemo) {
                resolve();
                return;
            }
            // Check logged in.
            if (!Utils.UserIsLoggedIn()) {
                reject('auth');
                return;
            }
            // Check nyx active.
            if (!Utils.UserIsNyxActive()) {
                reject('nyx');
                return;
            }
            resolve();
        });
    }

    addGameClient() {
        // scratch client
        if (SCRATCH_PRODUCTS.includes(this.gameClientData.productNumber)) {
            $('body').append('<div id="scratch-client" data-aria-hidden="true" aria-hidden="true"></div>');
        }
    }

    removeGameClient() {
        $('#scratch-client').remove();
    }

    showBackdrop() {
        $('body')
            .css({ overflow: 'hidden' })
            .append(`<div id="gameclients-backdrop"></div>`);

        const showLoaderTimeout = setTimeout(() => {
            $('#gameclients-backdrop')
                .append(`<div class="dot-spin"></div>`);
        }, 1000);

        window.fsEvents.once('scratch.show', function test() {
            clearTimeout(showLoaderTimeout);
        });

        $('#gameclients-backdrop')
            .css({ transformOrigin: 'top' })
            .delay(10)
            .queue(function (nxt) {
                $('#gameclients-backdrop').addClass('visible');
                nxt();
            })
    }

    hideBackdrop() {
        $('body')
            .css({ overflow: '' })

        setTimeout(() => {
            $('#gameclients-backdrop')
                .css({ transformOrigin: 'bottom' })
                .removeClass('visible')
                .delay(BACKDROP_DURATION)
                .queue(function (nxt) {
                    $(this).remove();
                    nxt();
                });
        }, 800);
    }

    hideBackdropLoader() {
        $('#gameclients-backdrop div.dot-spin').remove();
    }

    async fetchTicketData(payload) {
        const url = payload.roundArticleDemo
            ? Urls.GetDemoTickets
            : payload.ticketIds
                ? Urls.GetTickets
                : payload.roundKey
                    ? Urls.GetTicketsByRound
                    : null;

        const data = payload.roundArticleDemo || payload.ticketIds || payload.roundKey;

        // Abort if no ticket data.
        if (!url || !data) {
            throw 'No ticket data';
        }

        const ticketsReponse = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                payLoad: data,
            })
        });

        this.tickets = (await ticketsReponse.json()).map(ticket => {
            ticket.customerData = ticket.customerData
                ? camelcaseKeys(JSON.parse(ticket.customerData), {
                    deep: true,
                    exclude: [/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/]
                })
                : ticket.customerData;
            ticket.ticketInfo = camelcaseKeys(JSON.parse(ticket.ticketInfo), {
                deep: true,
                exclude: [/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/]
            });
            return ticket;
        });

        console.debug({ tickets: this.tickets });
    }

    async fetchGameClientData(roundKey) {
        const gameClientDataReponse = await fetch(Urls.GetGameClientData, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                payLoad: roundKey,
            })
        })

        this.gameClientData = await gameClientDataReponse.json();
        this.gameClientData.data = camelcaseKeys(
            JSON.parse(this.gameClientData.data), {
            deep: true,
            exclude: [/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/]
        });

        console.debug({ gameClientData: this.gameClientData });
    }

    async fetchClientManifest(productNumber) {
        const fetchProductNumber = SCRATCH_PRODUCTS.includes(productNumber)
            ? '0002'
            : productNumber;

        const manifestResponse = await fetch(`${Urls.GetProductManifest}?productNumber=${fetchProductNumber}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return manifestResponse.json();
    }

    loadScript(url, callback) {
        // Adding the script tag to the head as suggested before
        var head = document.head;
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;

        // Then bind the event to the callback function.
        // There are several events for cross browser compatibility.
        script.onreadystatechange = callback;
        script.onload = callback;

        // Fire the loading
        head.appendChild(script);
    }
}