$.widget("custom.mypagespayment", {
    options: {
        swishenvironment: "Production"
    },
    _init: function () {
        const environment = this.options.swishenvironment;
        $(document).ready(function () {
            const $widget = $(this),
                $element = $widget.element,
                $paymentSelection = $(".collapse-area-button", $element),
                $amount = $(".form-control.amount", $element),
                $swishAmount = $(".form-control.amount#swishAmount", $element),
                $creditcardAmount = $(".form-control.amount#creditcardAmount", $element),
                $mobileNumber = $(".form-control.mobile", $element),
                $deposit = $("button[type='submit'].deposit-button", $element),
                $swishDeposit = $("button[type='submit']#swishDeposit", $element),
                $creditcardDeposit = $("button[type='submit']#creditcardDeposit", $element),
                $spinner = $("#spinner", $element),
                $checkout = $("#checkoutContainer", $element),
                $swedbankCheckout = $("#swedbankCheckoutContainer", $element),
                $changeMobileNumber = $("a#changeMobileNumber", $element),
                $deleteSavedCards = $("a#deleteSavedCards", $element),
                $swishInformationPopover = $("h4#swishInformationPopover", $element),
                minVal = parseInt($amount.attr("data-val-range-min")),
                maxVal = parseInt($amount.attr("data-val-range-max")),
                mComm = 20,
                eComm = 10,
                integrationStyle = 20,
                returnUrl = Utils.GetUrlParamByName("returnUrl"),
                swishPaymentMethod = "swishPaymentMethod",
                creditcardPaymentMethod = "creditcardPaymentMethod",
                user = {
                    contactId: "",
                    mobile: "",
                    token: "",
                    hasReusablePayment: false,
                    device: {
                        flow: eComm,
                        isIos: false,
                    },
                },
                messages = {
                    depositDescription: "Insättning folkspel",
                    addCreditcard: "Lägg till betalkort",
                    deleteCreditcard: "Betalsättet är borttaget.",
                    errorDefault: "Något gick snett. Vänligen uppdatera sidan och försök igen. Om du fortfarande upplever problem ber vi dig att kontakta kundtjänst på 0771-440 440 så hjälper vi dig vidare.",
                    statusSuccess: "Insättningen lyckades!",
                    swishEcommDepositModal: "Öppna din swishapp på din mobil eller surfplatta",
                    swishMcommDepositModal: `Swishbetalning pågår. <br/><br/> Om Swish inte öppnas automatiskt, tryck på knappen nedan för att starta Swish manuellt.`,
                    swishTestDepositModal: "Detta är endast en simulering. Swishappen är helt bortkopplad. Betalningen kommer automatiskt att utföras inom några sekunder.",
                    amountValidationError: `Ange ett belopp mellan ${minVal} kr och ${maxVal} kr.`,
                    mobileValidationError: "Ditt mobilnummer verkar inte stämma, dubbelkolla numret och försök igen.",
                };

            if (localStorage.getItem("paymentInfo")) {
                const { pollUrl, paymentToken, paymentMethod } = JSON.parse(localStorage.getItem("paymentInfo"));
                if (pollUrl && paymentToken) {
                    checkDevice();
                    showPaymentStatus(pollUrl, paymentMethod, paymentToken);
                }
            };

            if (Utils.GetUrlParamByName("sId")) {
                var sessionKey = "cardPaymentInfo_" + Utils.GetUrlParamByName("sId");
                if (localStorage.getItem(sessionKey)) {
                    const { paymentId, interactionUrl, contactId } = JSON.parse(localStorage.getItem(sessionKey));
                    if (contactId && paymentId && interactionUrl) {
                        $("#creditcardPayment", $element).collapse('toggle')
                        paymentSelectionHandler($("#creditcard", $element));
                        loadCheckout(interactionUrl, `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${contactId}/payments/card/${paymentId}/status`);
                    }
                }
            };

            /*---------- UI FUNCTIONS ----------*/
            function toggleArrow(button) {
                const arrow = button.find(".fas.fa-chevron-down");
                isSectionOpen(button) ? arrow.removeClass("flipped") : arrow.addClass("flipped");
            };

            function toggleDisabled(input, disable) {
                const button = input.closest(".card-body").find(".deposit-button");
                button && !disable ? button.attr("disabled", false) : button.attr("disabled", true);
            };

            function toggleSpinner(show) {
                show ? $spinner.show().css("opacity", 1) : $spinner.css("opacity", 0).hide();
            };

            function toggleFormElements(elements, show) {
                if (!show) {
                    $.each(elements, function (index, element) {
                        element.hide();
                    })
                } else if (show) {
                    $.each(elements, function (index, element) {
                        element.show();
                    })
                }
            };

            /*---------- VALIDATOR FUNCTIONS ----------*/
            function validateAmount(input) {
                const val = parseInt(input.val());
                if (val >= minVal && val <= maxVal) {
                    toggleDisabled(input, false);
                    const existingValidationError = input.parent().find('.validation-error');
                    existingValidationError.addClass("flip-out");
                    setTimeout(function () {
                        existingValidationError.remove();
                    }, 400)
                } else {
                    toggleDisabled(input, true);
                    showValidationError(input);
                }
                //val >= minVal && val <= maxVal ? toggleDisabled(input, false) : toggleDisabled(input, true);
            };
            function validateMobileNumber(input) {
                const val = input.val().replace(/[-\s]/g, "");
                const regex = /^(\+|00)?\d{1,15}$/;

                if (val.length >= 8 && val.length <= 15 && regex.test(val)) {
                    toggleDisabled(input, false);
                    const existingValidationError = input.parent().find('.validation-error');
                    existingValidationError.addClass("flip-out");
                    setTimeout(function () {
                        existingValidationError.remove();
                    }, 400)
                } else {
                    toggleDisabled(input, true);
                    showValidationError(input);
                }
            };
            function validateUrl(url) {
                const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
                return regex.test(url);
            };

            function showValidationError(input) {
                const validationError = $(`<p class="validation-error"></p>`);
                const existingValidationError = input.parent().find('.validation-error');
                if (existingValidationError.length === 0) {
                    input.parent().append(validationError);
                    if (input.attr("id") === "mobileNumber") {
                        validationError.html(messages.mobileValidationError);
                    } else {
                        validationError.html(messages.amountValidationError);
                    }
                }
            }

            /*---------- SWISH ----------*/
            function checkDevice() {
                const ua = navigator.userAgent;
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)) {
                    user.device.flow = mComm;
                    if (/iPhone|iPad/i.test(ua)) {
                        user.device.isIos = true;
                    }
                } else {
                    user.device.flow = eComm;
                };
                hasRegisteredSwishNumber(user.device);
            };

            async function hasRegisteredSwishNumber(device) {
                user.contactId ? void 0 : await getUser();
                const mobileNumberWrapper = $("div.mobile-number");

                $mobileNumber.value = "";
                if (!user.mobile && device.flow === eComm || !user.mobile && device.flow === mComm && environment === "MockServer" || !user.mobile && device.flow === mComm && environment === "Simulator") {
                    $("div.add-mobile-number").show();
                }

                if (user.mobile) {
                    const numberString = $("<p id='userMobileNumber'>").text(user.mobile);
                    const existingNumber = mobileNumberWrapper.find("#userMobileNumber");
                    if (!existingNumber.length) {
                        mobileNumberWrapper.append(numberString);
                    }
                    $("div.show-mobile-number").show();
                }
                toggleSpinner(false);
            };

            async function postSwishPayment() {
                if (user.device.flow === eComm || user.device.flow === mComm && environment === "MockServer" || user.device.flow === mComm && environment === "Simulator") {
                    if (!user.mobile) {
                        user.mobile = $mobileNumber.val();
                    }
                }

                if (user.device.flow === mComm && environment === "MockServer" || user.device.flow === mComm && environment === "Simulator") {
                    user.device.flow = eComm;
                }

                if (user.device.flow === eComm && !$mobileNumber.is(":visible")) {
                    user.mobile = user.mobile;
                } else {
                    user.mobile = $mobileNumber.val();
                }

                if (user.device.flow === mComm) {
                    user.mobile = null;
                }


                const data = {
                    flow: user.device.flow,
                    mobile: user.mobile,
                    amount: parseInt($swishAmount.val()),
                    returnUrl: null,
                    description: messages.depositDescription
                };

                $.ajax({
                    url: `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/deposits/swish`,
                    method: "POST",
                    contentType: "application/json",
                    headers: {
                        "Authorization": `Bearer ${user.token}`
                    },
                    data: JSON.stringify({
                        ...data
                    }),
                }).done(function (res) {
                    const pollUrl = res.pollUrl.replace("wallet//", "wallet/v1/");
                    const paymentToken = res.token;

                    if (user.device.flow === mComm && user.device.isIos) {
                        const paymentInfo = {
                            pollUrl: pollUrl,
                            token: user.token,
                            paymentToken: res.token,
                            paymentMethod: swishPaymentMethod
                        };
                        localStorage.setItem("paymentInfo", JSON.stringify(paymentInfo));
                        console.log(user.device.flow);
                    }

                    if (user.device.flow === mComm) {
                        window.location = generateSwishUrl(user.device.isIos, paymentToken);
                    }

                    /*
                    if (!user.device.isIos && user.device.flow === mComm) {
                        window.location = generateSwishUrl(user.device.isIos, paymentToken);
                    } else if (user.device.isIos && user.device.flow === mComm) {
                        const paymentInfo = {
                            pollUrl: pollUrl,
                            token: user.token,
                        };
                        localStorage.setItem("paymentInfo", JSON.stringify(paymentInfo));
                    }
                    */

                    showPaymentStatus(pollUrl, swishPaymentMethod, paymentToken);
                }).fail(function (err) {
                    const modalData = {
                        title: "Något har gått fel",
                        iconClass: "fas fa-exclamation-circle",
                        body: err.responseJSON.Message ? err.responseJSON.Message : messages.errorDefault,
                    }
                    ModalHandler.ImageModal(modalData, function (complete) {
                        toggleSpinner(false);
                        validateAmount($swishAmount);
                    });
                    console.log(err);
                });
            };

            function generateSwishUrl(isIos, token) {
                let swishUrl = `swish://paymentrequest?token=${token}&callbackurl=`;
                const swishCallbackUrl = window.location.href;
                const ua = navigator.userAgent;
                if (isIos) {
                    if (/bingolottonative/i.test(ua)) {
                        swishUrl += "bingolotto://";
                    } else if (/fban|fbav/i.test(ua)) {
                        swishUrl += "fb://";
                    } else if (/gsa|instagram|snapchat|firefox|fxios|puffin|duckduckgo|edgios/i.test(ua)) {
                        swishUrl += "null";
                    } else if (/crios|chrome/i.test(ua)) {
                        swishUrl = swishUrl + swishCallbackUrl.replace("https://", "googlechromes://");
                    } else {
                        swishUrl = swishUrl + swishCallbackUrl;
                    }
                }
                return swishUrl;
            };

            /*---------- BANKKORT ----------*/


            function loadCheckout(url, pollUrl) {
                const response = loadJS(url).then((isLoaded) => {
                    payex.hostedView.checkout({
                        container: "swedbankCheckoutContainer",
                        onPaid:
                            function (data) {
                                toggleSpinner(true);
                                $swedbankCheckout.hide();
                                $checkout.show();
                                showPaymentStatus(pollUrl, creditcardPaymentMethod);
                            },
                    }).open();
                }).catch((isLoaded) => {
                    $swishDeposit.show();
                });
                $checkout.show();
            }

            async function postCreditcardPayment() {

                var sessionId = Math.floor(Math.random() * 1000000) + 1;
                var sessionKey = "cardPaymentInfo_" + sessionId;

                const data = {
                    integrationStyle: integrationStyle,
                    amount: parseInt($creditcardAmount.val()),
                    userAgent: navigator.userAgent,
                    returnUrl: window.location.href,
                    description: messages.depositDescription,
                    paymentUrl: Utils.AddUrlParam(window.location.href, "sId", sessionId)
                };

                $.ajax({
                    url: `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/deposits/card`,
                    method: "POST",
                    contentType: "application/json",
                    headers: {
                        "Authorization": `Bearer ${user.token}`
                    },
                    data: JSON.stringify({
                        ...data
                    }),
                }).done(function (res) {
                    const paymentId = res.id;
                    const url = res.interactionUrl;
                    const pollUrl = `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${user.contactId}/payments/card/${res.id}/status`;

                    const cardPaymentInfo = {
                        paymentId: paymentId,
                        interactionUrl: url,
                        contactId: user.contactId
                    };

                    localStorage.setItem(sessionKey, JSON.stringify(cardPaymentInfo));

                    loadCheckout(url, pollUrl);
                }).fail(function (err) {
                    console.log(err);
                    const modalData = {
                        title: "Något har gått fel",
                        iconClass: "fas fa-exclamation-circle",
                        body: err.responseJSON.Message ? err.responseJSON.Message : messages.errorDefault,
                    }
                    ModalHandler.ImageModal(modalData, function (complete) {
                        toggleSpinner(false);
                        validateAmount($creditcardAmount);
                    });
                    $creditcardDeposit.show();
                });
                toggleSpinner(false);
            };
            function loadJS(url, async = true) {
                return new Promise((resolve, reject) => {
                    const scriptEl = document.createElement("script");
                    scriptEl.setAttribute("src", url);
                    scriptEl.setAttribute("type", "text/javascript");
                    scriptEl.setAttribute("async", async);
                    document.body.appendChild(scriptEl);
                    scriptEl.addEventListener("load",
                        () => {
                            resolve(true);
                        });
                    scriptEl.addEventListener("error",
                        (ev) => {
                            reject(false);
                        });
                })
            };

            /*---------- GEMENSAMMA FUNKTIONER ----------*/
            async function getUser() {
                const token = await Utils.GetUserAccessToken();
                const userData = await Utils.GetOathData();

                const contactResponse = await fetch(`${Urls.EnterpriseApiRoot}entity/v1/Contacts/${userData.sub}`, {
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const contactData = await contactResponse.json();
                if (contactData) {
                    user.contactId = userData.sub;
                    user.mobile = contactData.mobile;
                    user.token = token;
                }
            };

            async function showPaymentStatus(pollUrl, paymentMethod, paymentToken) {
                if (paymentMethod === swishPaymentMethod) {
                    const startSwishPaymentModalData = {
                        title: "Insättning med Swish pågår",
                        imageUrl: $("img.payment-logo.swish").attr("src"),
                        imageAlt: "Swish logotyp",
                        progressDots: true,
                        buttonLabel: user.device.flow === eComm ? null : "Öppna swish",
                        buttonHref: user.device.flow === eComm ? null : generateSwishUrl(user.device.isIos, paymentToken)
                    };

                    if (user.device.flow === eComm) {
                        if (environment === "MockServer" || environment === "Simulator") {
                            startSwishPaymentModalData.body = messages.swishTestDepositModal;
                        } else {
                            startSwishPaymentModalData.body = messages.swishEcommDepositModal;
                        }
                    } else {
                        startSwishPaymentModalData.body = messages.swishMcommDepositModal;
                    };

                    ModalHandler.ImageModal(startSwishPaymentModalData, function () {
                        //bara för att inte få error complete(true) när man trycker på stäng.
                    });
                }

                const modalData = {
                    title: "Insättning",
                    iconClass: "fas fa-exclamation-circle",
                    body: "",
                };

                const pollInterval = setInterval(async function () {
                    const paymentStatus = await getPaymentStatus(pollUrl);
                    console.log(paymentStatus);
                    if (paymentStatus.completed) {
                        clearInterval(pollInterval);
                        if (paymentStatus.status <= 30) {
                            modalData.body = messages.errorDefault;
                        } else if (paymentStatus.status >= 50) {
                            modalData.body = paymentStatus.errorMessage ? paymentStatus.errorMessage : messages.errorDefault;
                        } else {
                            modalData.iconClass = "fas fa-coins";
                            modalData.body = messages.statusSuccess;
                        }
                        ModalHandler.ImageModal(modalData, function (complete) {
                            if (paymentStatus.status === 40 && validateUrl(returnUrl)) {
                                window.location = returnUrl;
                            } else {
                                window.location.reload();
                            }
                        });

                        localStorage.getItem("paymentInfo") ? localStorage.removeItem("paymentInfo") : void 0;
                        var sessionKey = "cardPaymentInfo_" + Utils.GetUrlParamByName("sId");
                        localStorage.getItem(sessionKey) ? localStorage.removeItem(sessionKey) : void 0;

                        toggleSpinner(false);
                    }
                }, 3000)
            };

            async function getPaymentStatus(pollUrl) {
                if (!user.token) {
                    if (localStorage.getItem("paymentInfo")) {
                        const { token } = JSON.parse(localStorage.getItem("paymentInfo"));
                        if (token) {
                            user.token = token;
                        }
                    }
                };

                const paymentStatusResponse = await fetch(pollUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`,
                    }
                });
                const paymentStatusData = await paymentStatusResponse.json();
                return paymentStatusData;
            }

            function isSectionOpen(element) {
                const section = element.parent().siblings(".collapse");
                return section.hasClass("in") ? true : false;
            };

            /*---------- EVENTLISTENERS ----------*/

            function paymentSelectionHandler($btn) {
                toggleArrow($btn);
                if (!isSectionOpen($btn)) {
                    toggleSpinner(true);
                    if ($btn.attr("id") === "swish") {
                        if ($("#creditcardPayment").hasClass("in") && $("#creditcard .fas.fa-chevron-down").hasClass("flipped")) {
                            $("#creditcardPayment").removeClass("in");
                            $("#creditcard .fas.fa-chevron-down").removeClass("flipped")
                        }
                        checkDevice();
                    } else {
                        if ($("#swishPayment").hasClass("in") && $("#swish .fas.fa-chevron-down").hasClass("flipped")) {
                            $("#swishPayment").removeClass("in");
                            $("#swish .fas.fa-chevron-down").removeClass("flipped");
                        }
                        user.contactId ? void 0 : getUser();
                        toggleSpinner(false);
                    }
                }
            }

            $paymentSelection.on("click", function (e) {
                e.preventDefault();
                paymentSelectionHandler($(this));
            });

            $amount.each(function () {
                $(this).on("input", function () {
                    validateAmount($(this));
                });
            });

            $mobileNumber.each(function () {
                $(this).on("input", function () {
                    validateMobileNumber($(this));
                });
            });

            $changeMobileNumber.on("click", function () {
                $("div.add-mobile-number").show();
                $("div.show-mobile-number").hide();
                $deposit.prop("disabled", true);
            });

            $deleteSavedCards.on("click", function () {
                ModalHandler.ConfirmModal("Ta bort sparade kortuppgifter",
                    "Ja, jag vill ta bort mina sparade kortuppgifter. Sparade kortuppgifter/kortnummer visas i betalformuläret vid påbörjad kortbetalning. Observera att det kan ta några minuter innan de sparade kortuppgifterna helt tagits bort.",
                    "Bekräfta", async (complete) => {
                        if (complete) {
                            let token = await Utils.GetUserAccessToken();
                            let userData = await Utils.GetOathData();
                            $.ajax({
                                url: `${Urls.EnterpriseApiRoot}wallet/v1/contacts/${userData.sub}/payments/reusable`,
                                method: "DELETE",
                                headers: {
                                    'Content-type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                }
                            }).done(function (res) {
                                ModalHandler.CloseModal();
                            }).fail(function (err) {
                                console.log(err);
                            });
                        }
                    });
            });

            $swishInformationPopover.on("click", function () {
                const swishNumberInformationList = $(".swish-number-information-wrapper .swish-number-information-popover ul");
                swishNumberInformationList.is(":visible") ? swishNumberInformationList.hide() : swishNumberInformationList.show();
            });

            $deposit.on("click", function (e) {
                e.preventDefault();
                if (e.target === $swishDeposit[0]) {
                    $(this).prop("disabled", true);
                    postSwishPayment();
                    toggleSpinner(true);
                } else if (e.target === $creditcardDeposit[0]) {
                    $(this).hide();
                    postCreditcardPayment();
                }
            });

        });
    }
});