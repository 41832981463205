$.widget("custom.nosubmitting", {
    options: {
    },
    _init: function() {
        var $widget = this,
            $element = $widget.element;

        function keyDown(e) {
            var keyCode = e.which || e.keyCode || 0;
            if (keyCode === 13) {
                e.preventDefault();
                return false;
            }
        }

        $("input:not([type='checkbox']):not([type='radio']),textarea", $element)
            .off("keydown", keyDown)
            .on("keydown", keyDown);
    }
});