$.widget("custom.autosubmit", {
    options: {},
    _create: function () {
        var $widget = this,
            $element = $widget.element;

        $element.blur(function() {
            $(this).closest("form").submit();
        });
    }
});