import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/popup.css';

class TypeForm {
 
    async createForm(formid, adduserinfo) {
        const options = {
            hideHeaders: true,
            hideFooter: true,
            hidden: {

            },
            onSubmit: (event) => {
                //L�gg till logik f�r onSubmit
            },
            onReady: (event) => {
                //L�gg till logik f�r onReady
            },
            onClose: (event) => {
                //L�gg till logik f�r onClose
            }
        }

        if (adduserinfo) {

            if (Utils.UserIsLoggedIn()) {
                const token = await Utils.GetUserAccessToken();
                const user = await Utils.GetOathData();
                const contactResponse = await fetch(`${Urls.EnterpriseApiRoot}entity/v1/Contacts/${user.sub}`, {
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const contactData = await contactResponse.json();
                options.hidden = {
                    contactid: contactData.id,
                    firstname: contactData.firstName,
                    lastname: contactData.lastName,
                    email: contactData.email
                }
            }

        }

        const { toggle } = createPopup(formid, options);
        toggle()

        //window.fsEvents.emit('forms.create', {
        //    id: formid
        //});
    }


};

window.fsForms = new TypeForm();

