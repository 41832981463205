$.widget("custom.mypagesmessages", {
    options: {
        readurl: ""
    },
    _create: function () {
        this._bindclicks();
    },
    _bindclicks: function () {
        var $widget = this,
            $element = $widget.element;
        $("div[data-message]", $element).on("click", this._onmessageclick);
    },
    _onmessageclick: function(e) {
        var $self = $(this);
        $.post($self.data("message"), function (data) {
            if (!data.success) return;
            $self.removeClass("unread").removeAttr("data-message").off("click");
            $("i.fa-circle", $self).removeClass("fas").addClass("far");
            $(document).trigger("updatemymessages", {});
        });
    }
});