$.widget("custom.myticketstore-step4", {
    options: {
        gameid: null,
        skin: null,
        opentickets: 1
    },
    _create: function () {
        var $widget = this,
            $this = $widget.element;
        var clientevents = function (event, params) {
            switch (event) {
                case "gamecompleted":
                    $widget.options.opentickets = $widget.options.opentickets - 1;
                    if ($widget.options.opentickets < 1) {
                        $(".btn-default", $this).hide();
                        ModalHandler.CloseModal(function () {});
                    }
                    if (params.winflag && params.amount > 0) {
                        //Show winning message
                        $(".winmessage", $this).show();
                    }                     
                    break;
            }
        }

        var showGameClient = function () {
            ModalHandler.ScratchTicketClientModal({
                gameid: $widget.options.gameid,
                assets: $widget.options.skin,
                session: $widget.options.session,
                eventcallback: clientevents
            });
        }

        $(".btn-default", $this).click(showGameClient);

        showGameClient();
        
    }
});
