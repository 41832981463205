$.widget("custom.searchretailerblock", {
    options: {
        url: "",
        title: "",
        organizationtodisplay: ""
    },
    _create: function () {
        var $widget = this,
            $form = $widget.element,
            $submit = $("button", $form);

        var openModal = function() {
            if ($widget.options.url === "") return;



            var street = $("#Street", $form).val().replace(/\d+/g, '');

            var zipcode = $("#ZipCode", $form).val(),
                city = $("#City", $form).val();

            ModalHandler.OpenModal({
                dialogClass: "retailer",
                contentClass: "retailer",
                modalShown: function($modal) {
                    var $content = $(".modal-content", $modal).empty();
                    Utils.LoadContent($content, $widget.options.url + "?".concat("street=", street, "&zipcode=", zipcode, "&city=", city, "&isInModal=true", "&title=", $widget.options.title, "&organizationToDisplay=", $widget.options.organizationtodisplay), null, true);
                }
            });
        };

        $("input", $form).keydown(function(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                openModal();
                return false;
            }
        });

        $submit.click(function(e) {
            e.preventDefault();
            openModal();
            return false;
        });
    }
});