$.widget("custom.contactform", {
    options: {
    },
    _create: function () {
        var $widget = this,
            $this = $widget.element;
        $("select", $this).change(function () {
            document.location = Utils.AddUrlParam(document.location.href, "TID", $(this).val());
        });

    }
});
