$.widget("custom.mypagesgamingbudget", {
    options: {
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element;

        $(document).ready(function () {

           

            $('.gamingSaveButton').click(function() {

                if (!$('#' + $(this).data('id')).hasClass('error')) {
                    $('.collapse').collapse('hide');
                    $('.gamingEditButton').show();
                }
            });

            $('.gamingCancelButton').click(function() {

                var form = $(this).parents('form');

                $('#' + $(this).data('id')).val('');
                form.valid();

                $('.gamingEditButton').show();
            });



            $('.gamingEditButton').click(function() {

                $('.gamingEditButton').show();
                $(this).hide();

                var testForm = $(this).parents('form');
                $('input').val('');
                testForm.valid();
                $('.collapse').collapse('hide');

            });

        });

        

    }
});