function InitTicketCarousel() {
    var $ticketCarousel = $("#ticketCarousel");
    var carousel = new FWDUltimate3DCarousel({
        //required settings
        carouselHolderDivId: "ticketCarousel",
        carouselDataListDivId: "ticketCarouselData",
        displayType: "responsive",
        autoScale: "no",
        carouselWidth: 700,
        carouselHeight: 420,
        skinPath: "/static/vendors/carousel/skin_modern_silver",
        //main settings
        //backgroundColor:"#DDDDDD",
        backgroundColor: "",
        backgroundImagePath: "",
        thumbnailsBackgroundImagePath: "",
        scrollbarBackgroundImagePath: "",
        backgroundRepeat: "repeat-x",
        carouselStartPosition: "center",
        carouselTopology: "normal",
        carouselXRadius: 190,
        carouselYRadius: 50,
        carouselXRotation: 0,
        carouselYOffset: -20,
        showCenterImage: "no",
        centerImagePath: "load/logo.png",
        centerImageYOffset: 0,
        showDisplay2DAlways: "no",
        slideshowDelay: 5000,
        autoplay: "no",
        showPrevButton: "no",
        showNextButton: "no",
        showSlideshowButton: "no",
        disableNextAndPrevButtonsOnMobile: "no",
        controlsMaxWidth: 940,
        slideshowTimerColor: "",
        showContextMenu: "no",
        addKeyboardSupport: "yes",
        thumbnailWidth: 260,
        thumbnailHeight: 350,
        thumbnailBorderSize: 0,
        thumbnailMinimumAlpha: 1,
        thumbnailBackgroundColor: "",
        thumbnailBorderColor1: "",
        thumbnailBorderColor2: "",
        transparentImages: "yes",
        maxNumberOfThumbnailsOnMobile: 5,
        showThumbnailsGradient: "no",
        showThumbnailsHtmlContent: "yes",
        textBackgroundColor: "",
        textBackgroundOpacity: .7,
        showText: "no",
        showTextBackgroundImage: "yes",
        showThumbnailBoxShadow: "yes",
        showReflection: "yes",
        reflectionHeight: 60,
        reflectionDistance: 0,
        reflectionOpacity: .2,
        //scrollbar settings
        showScrollbar: "no",
        disableScrollbarOnMobile: "yes",
        enableMouseWheelScroll: "yes",
        scrollbarHandlerWidth: 300,
        scrollbarTextColorNormal: "#777777",
        scrollbarTextColorSelected: "#000000",
        //combobox settings
        showComboBox: "no",
        startAtCategory: 1,
        selectLabel: "SELECT CATEGORIES",
        allCategoriesLabel: "All Categories",
        showAllCategories: "no",
        comboBoxPosition: "topright",
        selectorBackgroundNormalColor1: "#fcfdfd",
        selectorBackgroundNormalColor2: "#e4e4e4",
        selectorBackgroundSelectedColor1: "#a7a7a7",
        selectorBackgroundSelectedColor2: "#8e8e8e",
        selectorTextNormalColor: "#8b8b8b",
        selectorTextSelectedColor: "#FFFFFF",
        buttonBackgroundNormalColor1: "#e7e7e7",
        buttonBackgroundNormalColor2: "#e7e7e7",
        buttonBackgroundSelectedColor1: "#a7a7a7",
        buttonBackgroundSelectedColor2: "#8e8e8e",
        buttonTextNormalColor: "#000000",
        buttonTextSelectedColor: "#FFFFFF",
        comboBoxShadowColor: "#000000",
        comboBoxHorizontalMargins: 12,
        comboBoxVerticalMargins: 20,
        comboBoxCornerRadius: 0,
        //lightbox settings
        addLightBoxKeyboardSupport: "yes",
        showLightBoxNextAndPrevButtons: "yes",
        showLightBoxZoomButton: "yes",
        showLightBoxInfoButton: "yes",
        showLighBoxSlideShowButton: "yes",
        showLightBoxInfoWindowByDefault: "no",
        slideShowAutoPlay: "no",
        lightBoxVideoAutoPlay: "no",
        lightBoxVideoWidth: 640,
        lightBoxVideoHeight: 480,
        lightBoxIframeWidth: 800,
        lightBoxIframeHeight: 600,
        lightBoxBackgroundColor: "#000000",
        lightBoxInfoWindowBackgroundColor: "#FFFFFF",
        lightBoxItemBorderColor1: "#fcfdfd",
        lightBoxItemBorderColor2: "#e4FFe4",
        lightBoxItemBackgroundColor: "#333333",
        lightBoxMainBackgroundOpacity: .8,
        lightBoxInfoWindowBackgroundOpacity: .9,
        lightBoxBorderSize: 5,
        lightBoxBorderRadius: 0,
        lightBoxSlideShowDelay: 4000
    });

    carousel.addListener(FWDUltimate3DCarousel.INTRO_FINISH,
       function () {
           $(".buyticketbutton", $ticketCarousel).click(function (e) {
               e.preventDefault();
               if (!Utils.UserIsLoggedIn()) {
                   ModalHandler.AuthenticateModal();
               }
               else {
                   OpenGameClient();
               }
               return false;
           });
           $(".demoticketbutton", $ticketCarousel).click(function (e) {
               e.preventDefault();
               OpenGameClient(true);
               return false;
           });
       },
       false);

    carousel.addListener(FWDUltimate3DCarousel.THUMB_CHANGE,
      function (e) {
          $(".thumb", $ticketCarousel).removeClass(true);
          setTimeout(function () {
              var $thumb = $("#thumb" + e.id, $ticketCarousel);
              $(".buyticket", $thumb).removeClass("click").removeClass("hover");
              $thumb.addClass(true);
          }, 500)
      },
      false);

    var OpenGameClient = function (demo) {
        var skin = $(".thumb.active", $ticketCarousel).data("skin");
        var gameid = $(".thumb.active", $ticketCarousel).data("gameid");
        var options = {
            gameid: gameid,
            assets: skin
        };
        ModalHandler.ScratchTicketClientModal(options);
    };
}

if (!(typeof (FWDU3DCarUtils) === 'undefined')) {
    FWDU3DCarUtils.onReady(function () {
        var $ticketCarousel = $("#ticketCarousel");
        if ($ticketCarousel.length > 0) {
            InitTicketCarousel();
        }
    });
}

$(function () {
    if ($.fn.marquee) {
        $(".scroll-text").marquee({
            duplicated: true,
            duration: $(".scroll-text").width() * 30,
            pauseOnHover: true,
            allowCss3Support: false
        });
    }

    var $body = $("body"),
        $navmenu = $("#sidebarNavigation"),
        $mypages = $("#sidebarMypages"),
        $navmenubutton = $("[data-target='#sidebarNavigation']"),
        $mypagebutton = $("[data-target='#sidebarMypages']"),
        $hidingNavigation = $("#hidingNavigation"),
        $pagewrapper = $("#page-wrapper"), 
        $menuItems = $("ul.navmenu-nav li a", $navmenu),
        shouldTrackNextClick = false;

    $("img", $body).each(function () {
        var $parent = $(this).parent();
        if ($parent.get(0).tagName !== "A") return;
        $parent.addClass("arrow-hidden");
    });

    function iPadPortrait() {
        if (!/ipad/ig.test(navigator.userAgent)) return;
        $("div.row", $pagewrapper).each(function () {
            $("div.block:not(.block-imagewithoverlay)", $(this)).equalHeight();
        });
    }

    $(window).on("resize", iPadPortrait).on("load", iPadPortrait);

    $(".navbar .login, .block-xs-login .login, .navmenu-superheader .login, .gamepausepage .login").click(function (e) {
        e.preventDefault();
        Utils.Authenticate();
    });

    $(".navbar .createaccount, .block-xs-login .createaccount, .navmenu-superheader .createaccount").click(function (e) {
        e.preventDefault();
        Utils.Authenticate(null, "create");
    });

    $(".formconfirmation .close").on("click", function(e) {
        e.preventDefault();
        $(this).parent().fadeOut();
    });

    $navmenu.on("show.bs.offcanvas", function () {
        shouldTrackNextClick = true;
        if (window.dataLayer) {
            window.dataLayer.push({
                "event": "FsEvent",
                "eventCategory": "Menu",
                "eventAction": "ShowMenu"
            });
        }
    }).on("hide.bs.offcanvas", function () {
        shouldTrackNextClick = false;
    });

    $menuItems.click(function(e) {
        var $self = $(this);
        if (!shouldTrackNextClick) return;
        e.preventDefault();

        if (window.dataLayer) {
            window.dataLayer.push({
                "event": "FsEvent",
                "eventCategory": "Menu",
                "eventAction": "ClickMenuItem",
                "eventLabel": $self.text(),
                "eventValue": ($menuItems.index($self) + 1).toString(),
                "eventCallback" : function() {
                    window.location.href = $self.attr("href");
                }
            });
        }

        setTimeout(function() {
            window.location.href = $self.attr("href");
        }, 1000);
    });

    $navmenubutton.click(function() {
        if ($mypages.hasClass("canvas-slid"))
            $mypages.offcanvas("hide");
        else
            $hidingNavigation.slideDown(10);
    });

    $mypagebutton.click(function() {
        if ($navmenu.hasClass("canvas-slid"))
            $navmenu.offcanvas("hide");
        else
            $hidingNavigation.slideDown(10);
    });

    $navmenu.on("hidden.bs.offcanvas", function() {
        $hidingNavigation.slideUp(10);
    });

    $mypages.on("hidden.bs.offcanvas", function() {
        $hidingNavigation.slideUp(10);
    });

    $(document).on("FWDUltimate3DCarousel.ActiveThumbClicked", {}, function (e, srcElement) {
        var OpenGameClient = function (demo) {
            var $ticketCarousel = $("#ticketCarousel");
            var skin = $(".thumb.active", $ticketCarousel).data("skin");
            var gameid = $(".thumb.active", $ticketCarousel).data("gameid");
            var options = {
                gameid: gameid,
                assets: skin
            };
            ModalHandler.ScratchTicketClientModal(options);
        };
        if (srcElement.className == "buyticketbutton") {
            if (!Utils.UserIsLoggedIn()) {
                ModalHandler.AuthenticateModal();
            }
            else {
                OpenGameClient();
            }
            return false;
        }
        else if (srcElement.className == "demoticketbutton") {
            OpenGameClient(true);
            return false;
        }
    });

    if (Utils.UserIsNyxActive()) {
        setTimeout(function () {
            Utils.HasSetTurnoverLimit().then(function (response) {
                if (!response.HasSetLimit) {
                    ModalHandler.SetTurnoverLimit();
                }
            });
        }, 600);
    }
});