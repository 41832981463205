$.widget("custom.simplecountdown", {
    options: {
        datestring: null
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element;

        // Set the date we're counting down to
        //Replacing space with T and adding Z for Safari/iOS support
        var countDownDate = new Date($widget.options.datestring.replace(/-/g, "/")).getTime();

        // Update the count down every 1 second
        var x = setInterval(function () {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            //var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance) / (1000 * 60 * 60));
            if (hours < 10) {
                hours = "0" + hours;
            }
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if (seconds < 10) {
                seconds = "0" + seconds;
            }

            // Display the result in the element with id="demo"
            $element.html(hours + ":" + minutes + ":" + seconds);
            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                $element.html("00:00:00");
            }
        }, 1000);
    }
});