$.validator.setDefaults({
    errorElement: "span",
    errorClass: "help-block",
    highlight: function (element, errorClass, validClass) {
        $(element).closest('.form-group').addClass('has-error');
    },
    unhighlight: function (element, errorClass, validClass) {
        $(element).closest('.form-group').removeClass('has-error');
    },
    //errorPlacement: function (error, element) {
    //    if (element.parent('.input-group').length || element.prop('type') === 'checkbox' || element.prop('type') === 'radio') {
    //        error.insertAfter(element.parent());
    //    } else {
    //        error.insertAfter(element);
    //    }
    //},
    showErrors: function (errorMap, errorList) {
        if (errorList.length === 0) {
            $("input, textarea").each(function () {
                const $self = $(this);
                if ($self.data("bs.popover") && typeof $self.popover !== "undefined") {
                    $self.popover("destroy");
                }
            });
            return;
        }
        $.each(errorList, function (index, error) {
            const $element = $(error.element);
            if ($element.length === 1) {
                //if ($element.data("bs.popover") && typeof $element.popover !== "undefined" && $element.data("bs.popover").$tip && $element.data("bs.popover").$tip.text().trim() !== error.message.trim()) {
                //    console.info("each", $element, $element.data("bs.popover"), "destroy?");
                //    $element.popover("destroy");
                //}

                let placement = $element.data("placement");
                if (typeof placement !== "string") placement = "top";

                $element
                    .data("content", `<div class=\"text-danger\"><i class=\"far fa-times\" aria-hidden=\"true\"></i> ${error.message}</div>`)
                    .addClass("error")
                    .popover({ placement: placement, html: true, container: "body", trigger: "manual" })
                    .popover("show"); // Create a new popover based on the error messsage we just set in the title
            }
        });
    }
});