import bootbox from "bootbox";

$.widget("custom.depositform", {
    options: {
        confirm: "",
        cancel: "",
        message: ""
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $paymentmethod = $("select", $element),
            $savepayment = $(".savepayment", $element),
            $password = $(".password", $element),
            $deletemethod = $(".deletemethod", $element),
            $mobilenumber = $(".mobilenumber", $element),
            $btnnext = $(".btn-next", $element),
            $smsinfo = $(".smsinfo", $element);


        var updateCompontentStatus = function () {
            $("input", $savepayment).prop('checked', false);
            $savepayment.hide();
            $password.val("").hide();
            $deletemethod.hide();
            $mobilenumber.hide();
            $smsinfo.hide();
            var method = $paymentmethod.val();
            if (typeof method === "string" && method !== "") {
                $btnnext.show();
                var methodInfo = method.split(";");
                switch (methodInfo[0]) {
                    case "0": //New payment
                        if (methodInfo[5] == "1") {
                            $savepayment.show();
                        }
                        if (methodInfo[2] == "WyWallet") { //No password required, it is a WyWallet payment
                            $mobilenumber.show();
                        }
                        if (methodInfo[2] == "Smash") {
                            $smsinfo.show();
                            $btnnext.hide();
                        }
                        $btnnext.text($btnnext.data("defaulttext"));
                        break;
                    default: //Saved payment
                        if (methodInfo[7] == "0") { //Default payment, cannot be removed
                            $deletemethod.show();
                        }
                        if (methodInfo[4] == "1") { //Password required
                            $password.show();
                        }
                        $btnnext.text($btnnext.data("savedmethodtext"));
                        break;
                }
            }
        };

        $paymentmethod.change(updateCompontentStatus);

        updateCompontentStatus();

        $deletemethod.click(function (e) {
            e.preventDefault();
            bootbox.confirm({
                size: "small",
                buttons: {
                    confirm: {
                        label: $widget.options.confirm,
                        className: 'btn-success'
                    },
                    cancel: {
                        label: $widget.options.cancel,
                        className: 'btn-danger'
                    }
                },
                message: $widget.options.message,
                callback: function(result) {
                    if (result) $element.submit();
                }
            });
        });
    }
});