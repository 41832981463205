import '@ungap/event-target';

const VERSION = 'v1.';
const NAMESPACE = 'fs.';

class Events {
    constructor() {
        this.target = new EventTarget();
    }

    emit(key, data) {
        const eventKey = `${NAMESPACE}${VERSION}${key}`;
        console.debug('emit', eventKey, data);
        this.target.dispatchEvent(
            new CustomEvent(
                eventKey,
                {
                    detail: data
                }
            )
        );
    };

    on(key, callback) {
        const eventKey = `${NAMESPACE}${VERSION}${key}`;
        this.target.addEventListener(
            eventKey,
            callback
        );
    };

    off(key, callback) {
        const eventKey = `${NAMESPACE}${VERSION}${key}`;
        this.target.removeEventListener(
            eventKey,
            callback
        );
    };

    once(key, callback) {
        const eventKey = `${NAMESPACE}${VERSION}${key}`;
        this.target.addEventListener(
            eventKey,
            callback,
            {
                once: true
            }
        );
    };
}

const events = new Events();
window.fsEvents = events;
