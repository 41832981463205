export default class Purchase {
    walletData = null;
    presetCommission = false;
    commissions = [];
    roundArticles = [];
    $modal = null;

    constructor(purchases, commission, success) {
        this.success = success;

        this.checkAuth()
            .then(() => this.showLoadingModal())
            .then(() => Promise.all([
                this.fetchWallet(),
                this.fetchProducts(purchases),
                this.fetchCommissions(commission),
            ]))
            .then(() => this.showModal())
            .catch((error) => {
                console.debug({ error });
                window.fsEvents.emit('tickets.purchase.error', { error, payload: { purchases, commission, success } });
                if (error === 'auth') {
                    ModalHandler.AuthenticateModal(window.location);
                    return;
                }
                if (error === 'nyx') {
                    ModalHandler.AlertModal('Du kan för tillfället inte spela detta spel.', 'OK');
                    return;
                }
                ModalHandler.AlertModal('Ett tekniskt fel inträffade, var god försök igen.', 'OK');
            });
    }

    showLoadingModal() {
        return new Promise((resolve) => {
            ModalHandler.OpenModal({
                modalShown: ($modal) => {
                    this.$modal = $modal;
                    $(".modal-content", $modal)
                        .html(`<div class="modal-header purchase-modal-loading">
                            <div class="dot-flashing-wrapper"><span class="dot-flashing">&nbsp;</span></div>
                        </div>`);
                    resolve();
                }
            });
        });
    }

    checkAuth() {
        return new Promise((resolve, reject) => {
            // Check logged in.
            if (!Utils.UserIsLoggedIn()) {
                reject('auth');
            }
            // Check nyx active.
            if (!Utils.UserIsNyxActive()) {
                reject('nyx');
            }
            resolve();
        });
    }

    async fetchWallet() {
        const walletReponse = await fetch(window.Urls.GetWallet, {
            credentials: 'include'
        });
        this.walletData = await walletReponse.json();
        console.debug({ walletData: this.walletData });

        // No wallet data available.
        if (!this.walletData) {
            throw 'error';
        }

        // Not logged in.
        if (!this.walletData.userLoggedIn) {
            throw 'auth';
        }
    }

    async fetchProducts(purchases) {
        const articlesResponse = await fetch(Urls.GetRoundArticles, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                payLoad: purchases.map((purchase) => purchase.roundArticle)
            })
        });
        const articlesData = await articlesResponse.json();

        this.roundArticles = articlesData.map((articleData, index) => ({
            roundArticle: purchases[index].roundArticle,
            quantity: purchases[index].quantity,
            data: purchases[index].data,
            product: articleData.productNumber,
            article: articleData.articleNumber,
            title: articleData.articleName,
            price: articleData.price,
            customGraphics: articleData.customGraphics,
        }))
    }

    async fetchCommissions(commission) {
        // Predefined commissions sent to purchase.
        if (commission?.hasOwnProperty('preset') && commission?.preset != null && commission?.preset.length > 0) {
            console.log('preset is set!')
            this.commissions = commission.preset;
            this.presetCommission = true;
            return;
        }

        // User commissions sent to purchase.
        if (commission?.hasOwnProperty('userPreset') && commission?.userPreset != null && commission?.userPreset.length > 0) {
            this.commissions = commission.userPreset;
            this.presetCommission = false;
            return;
        }

        // If getUserCommisionIfEmptyPreset is false (or not set), don't try to fetch user commissions.
        if (!commission?.hasOwnProperty('getUserCommisionIfEmptyPreset') || commission?.getUserCommisionIfEmptyPreset === false) {
            return;
        }

        // Fetch user commissions.
        const token = await Utils.GetUserAccessToken();
        const user = await Utils.GetOathData();
        const contactResponse = await fetch(`${Urls.EnterpriseApiRoot}entity/v1/Contacts/${user.sub}`, {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        const contactData = await contactResponse.json();

        this.commissions = contactData.roles
            .filter(r => r.roleKey === 'supporter')
            .map(r => ({
                id: r.key,
                title: r.connection
            }));

        this.presetCommission = false;
    }

    showModal() {
        // Calculate bonus.
        const bonuses = this.walletData.bonuses;
        this.roundArticles.forEach((roundArticle) => {
            let bonusUsed = 0;
            bonuses.forEach(bonus => {
                if (bonus.products.includes(roundArticle.product)) {
                    bonusUsed = Math.min(bonus.amount, roundArticle.price * roundArticle.quantity);
                    bonus.amount -= bonusUsed;
                }
            });
            roundArticle.bonusUsed = bonusUsed;
        });

        // Total price, bonuses removed.
        const totalPrice = this.roundArticles.reduce(
            (amount, roundArticle) => amount + roundArticle.price * roundArticle.quantity - roundArticle.bonusUsed,
            0
        );

        // Missing wallet amount.
        const missingAmount = Math.max(0, 0 - (this.walletData.amount - totalPrice));

        // The selected associations to support.
        const supportingAssociations = this.commissions
            .map(c => c.title);

        window.fsEvents.emit('tickets.purchase.open', {
            roundArticles: this.roundArticles,
            commissions: this.commissions,
            commissionsType: this.presetCommission ? 1 : 0
        });

        const $body = $(`<div class="modal-title-block">
            <span>
                ${missingAmount > 0 ? 'Sätt in pengar' : 'Bekräfta köp'}
            </span>
            </div>
            <div class="modal-header">
                <div class="pad-b text-left">
                    <div class="mb-2">
                        ${this.roundArticles.map(article => `
                            <div class="flex-row">
                                <div class="flex-row">
                                    <img src="${Urls.CdnRoot}product-assets/${article.product}_${article.article}_${article.customGraphics ?? ''}icon.png" alt="Ikon för ${article.title}" class="mr-1 product-image" />
                                    <div>
                                        <span class="title">
                                            ${article.title}
                                        </span>
                                        <span class="price">
                                            ${article.bonusUsed > 0
                ? `<del>${Utils.FormatCurrency(article.price * article.quantity)}</del> ${Utils.FormatCurrency(article.price * article.quantity - article.bonusUsed)}<br />
                                                Använder ${Utils.FormatCurrency(article.bonusUsed)} bonus`
                : Utils.FormatCurrency(article.price * article.quantity)}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <span class="quantity">${article.quantity}</span>
                                </div>
                            </div>
                        `)}
                    </div>
                    <div class="flex-row">
                        <span>Att betala</span>
                        <span>${Utils.FormatCurrency(totalPrice)}</span>
                    </div>
                    <div class="flex-row">
                        <span>Ditt saldo</span>
                        <span>${Utils.FormatCurrency(this.walletData.amount)}</span>
                    </div>
                    ${missingAmount > 0 ? `<div class="flex-row">
                        <span><strong>Att sätta in</strong></span>
                        <span><strong>${Utils.FormatCurrency(missingAmount)}</strong></span>
                    </div>` : ''}
                    ${supportingAssociations.length > 0 ? `<div class="mt-2">
                        Överskottet går till:<br />
                        <strong>${supportingAssociations.join('<br />')}</strong>
                    </div>` : ''}
                </div>
            </div>
            <div class="modal-body">
            <section class="text-center">
                <button id="modalCancelButton" href="#" class="btn btn-block btn-default">Avbryt</a>
            </section>
            <section class="text-center">
                ${missingAmount > 0
                ? '<a class="btn btn-block btn-primary" href="/mina-sidor/?page=%2fMyPages%2fDeposit&returnUrl=' + window.location.href + '">Sätt in pengar</a>'
                : '<button id="modalPurchaseButton" class="btn btn-block btn-primary">Köp</button>'
            }
            </section>
            </div>`);

        $(".modal-content", this.$modal)
            .addClass('block-white')
            .addClass('purchase-modal')
            .html($body);

        $('#modalPurchaseButton').on('click', () => {
            this.purchase();
        });

        $('#modalCancelButton').on('click', () => {
            ModalHandler.CloseModal()
        });

        this.$modal.on('hidden.bs.modal', this.cancelPurchase);
    }

    cancelPurchase() {
        window.fsEvents.emit('tickets.purchase.cancel');
    }

    async purchase() {
        $('#modalPurchaseButton')
            .attr('disabled', 'disabled')
            .html('<div class="dot-flashing-wrapper"><span class="dot-flashing">&nbsp;</span></div>');

        try {
            const purchaseResponse = await fetch(Urls.PurchaseTickets, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    commissionType: this.presetCommission ? 1 : 0,
                    commissionAccounts: this.commissions.map(c => c.id),
                    items: this.roundArticles.map(article => ({
                        roundArticleKey: article.roundArticle,
                        quantity: article.quantity,
                        customerData: JSON.stringify(article.data),
                    }))
                })
            });
            if (!purchaseResponse.ok) {
                throw purchaseResponse.statusText;
            }

            const purchase = await purchaseResponse.json();

            if (!purchase.transactionId) {
                throw purchase.message;
            }

            this.$modal.off('hidden.bs.modal', this.cancelPurchase);

            ModalHandler.CloseModal(() => {
                window.fsEvents.emit('tickets.purchase.success', {
                    purchase,
                    roundArticles: this.roundArticles,
                    commissions: this.commissions,
                    commissionsType: this.presetCommission ? 1 : 0
                });
            });
        }
        catch (error) {
            window.fsEvents.emit('tickets.purchase.error', { error });
            ModalHandler.AlertModal('Ett tekniskt fel inträffade, var god försök igen.', 'OK');
        }
    }
}