import bootbox from "bootbox";

$.widget("custom.cancelwithdrawl", {
    options: {
        message: "",
        confirm: "",
        cancel: ""
    },
    _init: function () {
        var $widget = this,
            $form = $widget.element;

        $("button[type='submit']", $form).click(function(e) {
            e.preventDefault();
            bootbox.confirm({
                size: "small",
                buttons: {
                    confirm: {
                        label: $widget.options.confirm,
                        className: 'btn-success'
                    },
                    cancel: {
                        label: $widget.options.cancel,
                        className: 'btn-danger'
                    }
                },
                message: $widget.options.message,
                callback: function(result) {
                    if (result) $form.submit();
                }
            });
        });
    }
});