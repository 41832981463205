import { Spinner } from "spin.js";

$.widget("custom.scratchticketclient", {
    options: {
        gameid: 2100,
        wager: null,
        session: null,
        assets: "sverigelotten",
        loader_color: "0x43c6db",
        loader_bgcolor: null,
        loaded: null,
        eventcallback: null,
        spinner: {
            color: "#fff"
        },
        price: 25
    },
    _init: function () {
        var $widget = this,
            $element = $widget.element,
            spinneroptions = $.extend({}, DefaultOptions.Spinner, $widget.options.spinner);

        if (!this.options.session && $("body").data("sessionid")) {
            this.options.session = $("body").data("sessionid");
        }

        function logStep(page) {
            if (page !== "") {
                if (typeof (dataLayer) !== "undefined") {
                    dataLayer.push({ "event": "virtualPageView", "virtualPage": page });
                } else if (typeof (ga) !== "undefined") {
                    ga("send", "pageview", page);
                }
            }
        };

        logStep("/digitallott" + window.location.pathname);

        $widget.spinner = new Spinner(spinneroptions).spin($element.get(0));

        window["nyxcb"] = function (event, params) {
            switch (event) {
                case "purchaseMade":
                    var totalAmount = $widget.options.price;
                    var transactionId = $widget.options.session + "_" + ($widget.options.wager || Utils.CreateGuid().replace("-", ""));
                    Utils.TrackEComPurchaseWithEvent("ScratchTicket", transactionId, {
                        transactionId: transactionId,
                        transactionTotal: totalAmount,
                        transactionProducts: [
                            {
                                sku: "".concat($widget.options.gameid),
                                name: "".concat($widget.options.assets),
                                category: "ScratchTicket",
                                price: totalAmount,
                                quantity: 1
                            }
                        ]
                    });
                    logStep("/digitallott/koptlott");
                    break;
                case "giveawayticket":
                    if ($widget.options.giveawayurl) {
                        window.location.href = $widget.options.giveawayurl;
                    }
                    break;
                case "gamecompleted":
                    $(document).trigger("updatemybalance");
                    break;
            }
            if ($widget.options.eventcallback) {
                $widget.options.eventcallback(event, params);
            }
        };

        $widget._resize();
        $(window).off("resize").on("resize", function() {
            $widget._resize();
        });
        $widget._loadClientScripts();
        Utils.WaitForElement($("canvas", $widget.element), function($elem) {
            $widget._resize();
        });
    },
    _resize: function () {
        var $widget = this;
        $widget.element.closest(".modal-dialog").css("width", $("canvas", $widget.element).width() + "px");
    },
    _loadClient: function () {
        var $widget = this;
        var assetsroot = "/globalassets/gameclient/html5/lottery/";
        NYX.lottery.generic.start($widget.element.attr("id"), {
            assets: $widget.options.assets,
            gameid: $widget.options.gameid,
            wagerid: $widget.options.wager,
            session: $widget.options.session,
            loader_color: $widget.options.loader_color,
            loader_bgcolor: $widget.options.loader_bgcolor,
            loader_image: (assetsroot + "sv/" + $widget.options.assets + "/imgloading.png")
        });
        $widget._resize();
        if ($widget.options.loaded) {
            $widget.options.loaded();
        }
    },
    _loadClientScripts: function () {
        var $widget = this;
        if (Utils.HasSetTurnoverLimit().then(function(result) {
            if (result.HasSetLimit) {
                var gameClientRoot = "/nyxclient/html5/lottery/";
                $.getJSON(gameClientRoot + "generic/assets.json?t=" + Math.floor(Date.now() / (1000 * 60)), {},
                    function(data) {
                        var jsArr = data.JS_FILES.split(",");
                        var loaded = 0;
                        $(jsArr).each(function(index, val) {
                            $.getScript(gameClientRoot + "generic/" + val, function() {
                                loaded++;
                                if (jsArr.length === loaded) {
                                    $widget._loadClient();
                                }
                            });
                        });
                    });
            } else {
                window.location = result.RedirectUrl;
            }
        }));

        
    },
    _nyxCallback: function (event) {

    }
});