import Cookies from "js-cookie";
import bootbox from "bootbox";

(function ($) {
    window.ModalHandler = $.extend(window.ModalHandler, {}, {
        OpenModal: function (options) {
            var $modal = $("#siteModal"),
                $dialog = $(".modal-dialog", $modal),
                $content = $(".modal-content", $dialog),
                $sidebarNav = $("#sidebarNavigation"),
                $myPagesNav = $("#sidebarMypages");

            ModalHandler.CloseModal(function () {
                $modal.attr("class", "modal fade");
                if (options.cssClass) {
                    $modal.addClass(options.cssClass);
                }
                $dialog.attr("class", "modal-dialog");
                if (options.dialogClass) {
                    $dialog.addClass(options.dialogClass);
                }

                $content.empty().attr("class", "modal-content").attr("id", Utils.CreateGuid());
                if (options.contentClass) {
                    $content.addClass(options.contentClass);
                }

                if ($sidebarNav.css("display") === "block") {
                    $sidebarNav.offcanvas("hide");
                }
                if ($myPagesNav.css("display") === "block") {
                    $myPagesNav.offcanvas("hide");
                }

                if (options.modalShown) {
                    $modal.on("shown.bs.modal",
                        function () {
                            $modal.off("shown.bs.modal");
                            options.modalShown($modal);
                        });
                }

                var modalOptions = {
                    show: true,
                    backdrop: true
                }
                if (options.static) {
                    modalOptions.backdrop = "static";
                }
                $modal.removeData("bs.modal").modal(modalOptions);
            });

            return $modal;
        },
        CloseModal: function (callback) {
            var $modal = $("#siteModal");
            $(".modal-content", $modal).empty();
            if (ModalHandler.IsOpenModal()) {
                $modal.on("hidden.bs.modal",
                    function (e) {
                        $modal.off("hidden.bs.modal");
                        if (callback) {
                            callback();
                        }
                    });
                $modal.modal("hide");
            } else if (callback) {
                callback();
            }
        },
        IsOpenModal: function () {
            return $("#siteModal").css("display") === "block";
        },
        SetTurnoverLimit: function () {
            var $content = $("<div />");

            Utils.LoadContent($content,
                Urls.SetTurnoverLimitModal,
                function ($data) {
                    $content = $data;

                    bootbox.dialog({
                        message: $content,
                        closeButton: false
                    });
                });
        },
        ConfirmCampaignForm: function (options) {
            ModalHandler.OpenModal({
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    var params = "";
                    if (options) {
                        for (var name in options) {
                            if (!options.hasOwnProperty(name)) continue;
                            params = Utils.AddUrlParam(params, name, options[name]);
                        }
                    }
                    Utils.LoadContent($content, Urls.ConfirmCampaignForm + params);
                }
            });
        },
        DepositModal: function (options) {
            ModalHandler.OpenModal({
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    var params = "";
                    if (options) {
                        for (var name in options) {
                            if (!options.hasOwnProperty(name)) continue;
                            params = Utils.AddUrlParam(params, name, options[name]);
                        }
                    }
                    Utils.LoadContent($content, Urls.DepositModal + params);
                }
            });
        },
        DepositConfirmation: function (options) {
            ModalHandler.OpenModal({
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    Utils.LoadContent($content, Urls.DepositConfirmation + "?a=" + options.action);
                }
            });
        },
        ResizeModal: function () {
            var $modal = $("#siteModal .modal-content"),
                $window = $(window),
                dimension = 640 / 840,
                height,
                width;

            if ($window.height() >= $window.width()) {
                width = $(window).width() * 0.95;
                if (width > 420) {
                    width = 420;
                }
                height = width / dimension;
            } else {
                height = $window.height() * 0.95;
                if (height > 615) {
                    height = 615;
                }
                width = height * dimension;
            }

            var left = ($window.width() / 2) - (width / 2);
            var top = ($window.height() / 2) - (height / 2);

            $modal.css("height", height + "px").css("width", width + "px").css("left", left + "px")
                .css("top", top + "px");
        },
        ScratchTicketClientModal: function (options) {
            ModalHandler.OpenModal({
                cssClass: "scratchticketclient",
                static: true,
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    options["loaded"] = function () {
                        var $closeBtn =
                            $('<button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="far fa-times-circle fa-lg"></i></button>');
                        $content.prepend($closeBtn);

                        //Make sure the modal and backdrop are siblings (changes the DOM)
                        $("#siteModal").before($(".modal-backdrop"));
                        //Make sure the z-index is higher than the backdrop
                        $("#siteModal").css("z-index", parseInt($(".modal-backdrop").css("z-index")) + 9999);
                    }
                    $content.scratchticketclient(options);
                }
            });
        },
        LotterySubscriptionModal: function (campaign,
            bonus,
            preselectedPackage,
            onlyCardPayment,
            showAssociationPicker,
            commissionAccountId,
            commissionAccountName,
            associationHeader,
            associationBody) {
            ModalHandler.OpenModal({
                cssClass: "modal-lotterysubscription",
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    $modal.css("-webkit-overflow-scrolling", "auto");

                    var associationId = commissionAccountId;
                    var associationName = commissionAccountName;
                    if (associationId == null || associationId == "") {
                        associationId = Cookies.get("commissionAccount_customerId");
                        associationName = Cookies.get("commissionAccount_customerName");
                        if (associationName) {
                            associationName = decodeURIComponent(associationName.replace(/\+/gm, "%20"));
                        }
                    }
                    var campaigncode = campaign;
                    var campaignbonus = bonus;
                    $content.lotterysubscription({
                        showassociationpicker: (showAssociationPicker && associationId == null),
                        associationheader: associationHeader,
                        associationbody: associationBody,
                        associationid: associationId,
                        associationname: associationName,
                        campaigncode: campaigncode,
                        campaignbonus: campaignbonus,
                        preselectedpackageid: preselectedPackage,
                        onlyCardPayment: onlyCardPayment
                    });
                }
            });
        },
        AuthenticateModal: function (returnUrl, message) {
            ModalHandler.OpenModal({
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    Utils.LoadContent($content, Urls.AuthenticateModal + "?returnUrl=" + btoa(returnUrl) + "&message=" + (message ? btoa(message) : ""));
                }
            });
        },
        AuthenticationFailedModal: function (returnUrl) {
            ModalHandler.OpenModal({
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    Utils.LoadContent($content, Urls.AuthenticationFailedModal + "?returnUrl=" + btoa(returnUrl));
                }
            });
        },
        BankIdAuthenticateModal: function (complete) {
            ModalHandler.OpenModal({
                static: true,
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    Utils.LoadContent($content, Urls.BankIdAuthenticateModal, function () {
                        complete($modal);
                    });
                }
            });
        },
        BankIdSignModal: function (complete) {
            ModalHandler.OpenModal({
                static: true,
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    Utils.LoadContent($content, Urls.BankIdSignModal, function () {
                        complete($modal);
                    });
                }
            });
        },
        ConfirmModal: function (header, body, primaryButtonText, complete) {
            ModalHandler.OpenModal({
                static: true,
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    Utils.LoadContent($content, Urls.ConfirmModal + "?header=" + header + "&body=" + body + "&primaryButtonText=" + primaryButtonText, function () {
                        $('#modalConfirmButton', $modal).click(function () {
                            complete(true);
                        });
                        $('#modalCancelButton', $modal).click(function () {
                            ModalHandler.CloseModal();
                            complete(false);
                        });
                    });
                }
            });
        },
        PurchaseSuggestionModal: function (header, body, product, totalWinAmount, totalTicketAmount, information, primaryButtonText, cancelButtonText, productNumber, articleNumber, complete) {
            ModalHandler.OpenModal({
                static: true,
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    Utils.LoadContent($content, Urls.PurchaseSuggestionModal + "?header=" + header + "&body=" + body + "&product=" + product + "&totalWinAmount=" + totalWinAmount + "&totalTicketAmount=" + totalTicketAmount + "&information=" + information + "&primaryButtonText=" + primaryButtonText + "&cancelButtonText=" + cancelButtonText + "&productNumber=" + productNumber + "&articleNumber=" + articleNumber, function () {
                        $('#modalConfirmButton', $modal).click(function () {
                            Utils.PushGoogleTag('Accept_purchase_offer');
                            complete(true);
                        });
                        $('#modalCancelButton', $modal).click(function () {
                            Utils.PushGoogleTag('Decline_purchase_offer');
                            ModalHandler.CloseModal();
                            complete(false);
                        });
                    });
                }
            });
        },
        InformationModal: function (header, icon, body, complete) {
            ModalHandler.OpenModal({
                static: true,
                modalShown: function ($modal) {
                    var $content = $(".modal-content", $modal);
                    Utils.LoadContent($content, Urls.InformationModal + "?header=" + header + "&icon=" + icon + "&body=" + body, function () {
                        $('#modalCancelButton', $modal).click(function () {
                            ModalHandler.CloseModal();
                            complete(true);
                        });
                    });
                }
            });
        },
        AlertModal: function (body, buttonLabel) {
            ModalHandler.OpenModal({
                static: true,
                modalShown: function ($modal) {
                    var $body = $(`<div class="modal-header">
                            <div class="pad-b text-left">
                                <span class="message">${body}</span>
                            </div>
                        </div>
                        <div class="modal-body">
                            <section class="text-center">
                                <button id="modalAlertButton" class="btn btn-block btn-primary">${buttonLabel}</a>
                            </section>
                        </div>`);
                    $('.modal-content', $modal).append($body);
                    $('#modalAlertButton').on('click', () => {
                        ModalHandler.CloseModal();
                    })
                }
            });
        },
        ImageModal: function (modalData, complete) {
            ModalHandler.OpenModal({
                static: true,
                modalShown: function ($modal) {
                    var $body = $(
                        `<div class="modal-header image-modal">
                            <div class="text-center bg-primary">
                            <div class="message">${modalData.title}</div>
                            </div>
                            ${modalData.imageUrl ? `<img src="${modalData.imageUrl}" alt="${modalData.imageAlt}"/>` : ""}
                            ${modalData.iconClass ? `<i class="icon ${modalData.iconClass}"></i>` : ""}
                            ${modalData.progressDots ? `      <div class="progress-dots col-3">
        <div class="snippet" data-title="dot-pulse">
          <div class="stage">
            <div class="dot-pulse"></div>
          </div>
        </div>
      </div>` : ""}
                            <div class="pad-l pad-r text-center">
                                <span class="body">${modalData.body}</span>
                            </div>
                        </div>
                        <div class="modal-body pad-b text-center image-modal">
                            <section class="text-center">
                                ${modalData.buttonHref ? `<button id="modalAlertButton" class="btn btn-block btn-primary">${modalData.buttonLabel}</button>` : ""}
                                <button id="modalAlertCloseButton" class="btn btn-block btn-primary">Stäng</button>
                            </section>
                        </div>`
                    );

                    $('.modal-content', $modal).append($body);

                    $('#modalAlertCloseButton', $modal).click(function () {
                        ModalHandler.CloseModal();
                        complete(true);
                    });
                    $('#modalAlertButton').on('click', () => {
                        window.location = modalData.buttonHref;
                    });
                    //$('#modalAlertCloseButton').on('click', () => {
                    //    ModalHandler.CloseModal();
                    //});
                }
            });
        },
    });
})(jQuery);