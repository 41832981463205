import moment from "moment";

$.widget("custom.moment", {
    options: {
        date: ""
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element;

        if ($widget.options.date === "") return;
        $element.html(moment($widget.options.date).fromNow());
    }
});