$.widget("custom.associationform", {
    options: {
        hiddenfield: "",
        textfield: "",
        enablebutton: "",
    },
    _create: function () {
        var $widget = this,
            $form = $widget.element;

        if ($widget.options.hiddenfield === "" || $widget.options.textfield === "") return;

        var $textfield = $("#" + $widget.options.textfield, $form),
            $hiddenfield = $("#" + $widget.options.hiddenfield, $form),
            $enablebutton;

        if ($widget.options.enablebutton !== "") {
            $enablebutton = $("button[id$='" + $widget.options.enablebutton + "']", $form);
            $enablebutton.on("click", function (e) {
                e.preventDefault();
                if ($textfield.hasAttr("disabled") || $textfield.is(":disabled")) {
                    $textfield.prop("disabled", false).removeAttr("disabled").focus();
                    $enablebutton.addClass("hidden");
                }
            });
        }

        $textfield.devbridgeAutocomplete({
            noCache: true,
            minChars: 2,
            lookup: function (query, response) {
                $form.spin();
                $.ajax({
                    url: Urls.SearchAssociation,
                    method: "GET",
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    data: { associationName: query },
                    success: function (data) {
                        response({ "suggestions": data });
                        $form.trigger(jQuery.Event("autocompleted"));
                        $form.spin(false);
                    }
                });
            },
            onSelect: function (suggestion) {
                $hiddenfield.val(suggestion.data);
                $form.trigger(jQuery.Event("selected"));
            }
        });
    }
});