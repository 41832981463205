$.widget("custom.registercardform", {
    options: {
    },
    _create: function () {
        var $widget = this,
            $this = $widget.element,
            $email = $("#EmailAddress", $this).parents(".form-group"),
            $mobile = $("#Mobile", $this).parents(".form-group");
        $("input[type=radio]", $this).click(function () {
            if ($(this).val().indexOf("PayEx")>-1) {
                if (!$email.is(":visible")) {
                    $mobile.fadeOut(200, function () {
                        $email.fadeIn(200);
                    });
                }
            }
            else {
                if (!$mobile.is(":visible")) {
                    $email.fadeOut(200, function () {
                        $mobile.fadeIn(200);
                    });

                }
            }
        });

    }
});
