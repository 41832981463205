(function ($) {
    window.Urls = $.extend(window.Urls, {}, {
        Authenticate: "/Account/Authenticate",
        VerifyMobileAndEmail: "/Account/VerifyMobileAndEmail",
        AuthenticateModal: "/Account/AuthenticateModal",
        AuthenticationFailedModal: "/Account/AuthenticationFailedModal",
        ConfirmModal: "/Common/ConfirmModal",
        InformationModal: "/Common/InformationModal",
        PurchaseSuggestionModal: "/Common/PurchaseSuggestionModal",
        SetTurnoverLimitModal: "/Account/SetTurnoverLimitModal",
        HasSetTurnoverLimit: "/Account/HasSetTurnoverLimit",
        Deposit: "/Deposit/Index",
        DepositModal: "/Deposit/Modal",
        DepositConfirmation: "/Deposit/Confirm",
        DepositTicketFlow: "/Deposit/TicketFlow",
        Games: "/Game/Games",
        Game: "/Game/Game",
        RegisterSubscriptionPayment: "/MyPages/RegisterSubscriptionPayment",
        HomeBySwedenUrl: "/Common/GetHomeBySwedenUrl",
        UserIsSubscriber: "/Common/GetUserIsSubscriber",
        WinnerTicker: "/Game/GetWinnerTicker",
        RegisterMobilePayment: "/MyPages/RegisterMobilePayment",
        ChatTicker: "/Game/GetChatTicker",
        SearchAssociation: "/Common/SearchAssociation",
        GetCommissionInfo: "/Common/GetCommissionInfo",
        GetCashAndBonus: "/Common2/GetCashAndBonus",
        GetUnreadMessages: "/Common2/GetUnreadMessages",
        BankIdAuthenticateModal: "/Common/BankIdAuthenticateModal",
        BankIdSignModal: "/Common/BankIdSignModal",
        BankIdAuthenticate: "/Common/BankIdAuthenticate",
        BankIdSign: "/Common/BankIdSign",
        BankIdCheckComplete: "/Common/BankIdCheckComplete",
        BankIdCancel: "/Common/BankIdCancel",
        SetReadMessages: "/Common2/SetReadMessages",
        GetPromotional: "/Common/GetPromotionalBlock",
        SetNewPasswordModal: "/Account/SetNewPasswordModal",
        GetPurchaseTicket: "/Common/GetPurchaseTicket",
        ConfirmCampaignForm: "/Common/ConfirmCampaignForm",
        GetQrCodeImage: "/Common/GetQrCodeImage",
        RetrySession: "/Common2/RetrySession",
        GetWallet: "/Common2/GetWallet",
        GetProductManifest: "/Common2/GetProductManifest",
        GetUserAccessToken: "/Common2/GetUserAccessToken",
        RedeemTicket: "/Ticket/RedeemTicket",
        GetRoundArticles: "/Ticket/GetRoundArticles",
        PurchaseTickets: '/Ticket/PurchaseTickets',
        GetDemoTickets: '/Ticket/GetDemoTickets',
        GetTickets: '/Ticket/GetTickets',
        GetTicketsByRound: '/Ticket/GetTicketsByRound',
        GetGameClientData: '/Ticket/GetGameClientData'
    });
})(jQuery);
