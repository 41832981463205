import "jquery-ui/ui/data.js";
import "jquery-ui/ui/widget.js";

(function($) {
    window.WidgetHandler = $.extend(window.WidgetHandler || {}, {
        InitWidgets: function($element) {
            //scan element for widget
            if ($element.data("widget")) {
                WidgetHandler.InitWidget($element);
            }
            $("[data-widget]", $element).each(function() {
                WidgetHandler.InitWidget($(this));
            });
        },
        InitWidget: function($element) {
            if (!$element.data("widgetinitialized")) {
                var event = jQuery.Event("InitWidget");
                $element.trigger(event);
                if (!event.isDefaultPrevented()) {
                    var options = {};
                    $.each($element.get(0).attributes, function() {
                            if (this.name.indexOf("data-widget-") > -1) {
                                var optionName = this.name.replace("data-widget-", "");

                                if (this.value !== "" && !isNaN(this.value) && parseInt(this.value, 10) !== NaN) {
                                    options[optionName] = parseInt(this.value, 10);
                                } else if (this.value !== "" && (this.value === "true" || this.value === "false" || this.value === "True" || this.value === "False")) {
                                    options[optionName] = /true/ig.test(this.value);
                                } else {
                                    options[optionName] = this.value;
                                }

                            }
                        });
                    $element[$element.data("widget")](options);
                }
            }
            $element.data("widgetinitialized", true);
        }
    });
})(jQuery);