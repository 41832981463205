$.widget("custom.bankidconfirm", {
    options: {
        ssn: "",
        ssnelement: ""
    },
    _create: function() {
        var $widget = this,
            $form = $widget.element;

        $form.submit(function (e) {
            if (!$form.validate().form()) {
                e.preventDefault();
                return false;
            }
            var ssn = $widget.options.ssn !== "" ? $widget.options.ssn : $($widget.options.ssnelement).val();
            if (!($form.data("confirmed") === ssn)) {
                e.preventDefault();
                $form.bankidauthenticate({
                    ssn: ssn,
                    complete: function () {
                        $form.data("confirmed", ssn);
                        $form.submit();
                    }
                });
                return false;
            }
        });
        
    }    
});