import { Spinner } from "spin.js";

(function ($) {

    $.fn.hasAttr = function (name) {
        for (var i = 0, l = this.length; i < l; i++) {
            if (!!(this.attr(name) !== undefined)) {
                return true;
            }
        }
        return false;
    };

    $.fn.spin = function(opts) {
        this.each(function() {
            var $this = $(this), $data = $this.data();
            if ($data.spinner) {
                $data.spinner.stop();
                delete $data.spinner;
            }
            if (opts !== false) {
                $data.spinner = new Spinner($.extend({}, DefaultOptions.Spinner, opts)).spin(this);
            }
        });
        return this;
    };

    $.cachedScript = function(url, options) {
 
        // Allow user to set any option except for dataType, cache, and url
        options = $.extend( options || {}, {
            dataType: "script",
            cache: true,
            url: url
        });
 
        // Use $.ajax() since it is more flexible than $.getScript
        // Return the jqXHR object so we can chain callbacks
        return jQuery.ajax( options );
    };

})(jQuery);