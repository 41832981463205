import Handlebars from "handlebars/dist/handlebars.js";
import moment from "moment";
import Cookies from "js-cookie";
import { Spinner } from "spin.js";

$.widget("custom.navbar", {
    _interval: null,
    options: {
        bonustext: "",
        messageupdateeach: 60,
        isloggedin: "false",
        disablemessages: ""
    },
    _create: function () {
        if (this.options.isloggedin === "false") return;

        Handlebars.registerHelper("format-date", function(date) {
            return moment(date).fromNow();
        });

        Handlebars.registerHelper("if-cond", function (v1, operator, v2, options) {
            switch (operator) {
                case "==":
                    return (v1 == v2) ? options.fn(this) : options.inverse(this);
                case "===":
                    return (v1 === v2) ? options.fn(this) : options.inverse(this);
                case "!=":
                    return (v1 != v2) ? options.fn(this) : options.inverse(this);
                case "!==":
                    return (v1 !== v2) ? options.fn(this) : options.inverse(this);
                case "<":
                    return (v1 < v2) ? options.fn(this) : options.inverse(this);
                case "<=":
                    return (v1 <= v2) ? options.fn(this) : options.inverse(this);
                case ">":
                    return (v1 > v2) ? options.fn(this) : options.inverse(this);
                case ">=":
                    return (v1 >= v2) ? options.fn(this) : options.inverse(this);
                case "&&":
                    return (v1 && v2) ? options.fn(this) : options.inverse(this);
                case "||":
                    return (v1 || v2) ? options.fn(this) : options.inverse(this);
                default:
                    return options.inverse(this);
            }
        });

        var widget = this;
        var mybalance = { bonustext: this.options.bonustext };
        window.fsEvents.on('tickets.purchase.success', function () { $(document).trigger("updatemybalance"); });
        window.fsEvents.on('tickets.redeem.success', function () { $(document).trigger("updatemybalance"); });

        $(document)
            .off("updatemybalance", mybalance, this._updatemybalance)
            .on("updatemybalance", mybalance, this._updatemybalance);

        $(document)
            .off("updatemymessages", this._updatemymessages)
            .on("updatemymessages", this._updatemymessages);

        if (Utils.UserIsNyxActive()) {
            setTimeout(function () {
                widget._updatemybalance({ data: mybalance });
            }, 200);
            if (widget.options.disablemessages !== "y") {
                setTimeout(function () {
                    widget._updatemymessages();
                }, 400);
                this._interval = setInterval(this._updatemymessages, this.options.messageupdateeach * 1000);
            }
        }

        $(".navbar-mymoney-close").click(function (e) {
          e.preventDefault();
            Cookies.remove("mymoneyState");
            $(".navbar-mymoney").slideUp(300, function () {
                $(".btn-mymoney").fadeIn();
            });
        });

      $(".btn-mymoney").click(function(e) {
        e.preventDefault();
        Cookies.set("mymoneyState", "active");
        $(".navbar-mymoney").slideDown(300);
        $(".btn-mymoney").fadeOut();
      });

    },
    _destroy: function () {
        if (this._interval) clearInterval(this._interval);
    },
    _updatemybalance: function(e) {
        var $widget = this,
            $form = $widget.element;

        if (typeof (e) === "undefined" || e == null) return;

        if (!Utils.UserIsNyxActive() || window.hasbeenloggedout) return;

        var options = $.extend({}, DefaultOptions.Spinner, {
            width: 10,
            scale: 0.25,
            color: "#fff"
        });

        var $money = $("[data-money-value]", $form);
        var spinner = new Spinner(options).spin($money.get(0));
        $.getJSON(Urls.GetCashAndBonus, { _: new Date().getTime() }).then(function(data) {
                spinner.stop();
                if (data.loggedout) {
                    window.hasbeenloggedout = true;
                    setTimeout(function () {
                        location.reload(true);
                    }, 45000);
                    return;
                }
            if (data.success) {
                    var html = '<small style="font-weight: bold;">Saldo:</small><br />';
                    html += "<span class='currency'>" + data.cashBalance + "</span>";
                    if ($money.data("bonus-value")) {
                        html = html +
                            "<br /><small>" +
                            e.data.bonustext +
                            ": <span class='currency'>" +
                            data.bonusBalance +
                            "</span></small>";
                    }
                    $money.html(html);
                } else {
                    console.log("cashandbonus success false.");
                }
            },
            function(jq, status, statusText) {
                console.log("cashandbonus error. status: " + statusText);
                spinner.stop();
            });
    },
    _updatemymessages: function () {
        var $widget = this,
            $form = $widget.element,
            $navbar = $("[data-message-count] i.fa", $form),
            $badge = $("[data-message-count] span.badge", $form),
            $mobile = $(".btn-mybingolotto span.badge", $form),
            $mypagebadge = $("#sidebarMypages [data-message-count] span.badge"),
            $navmenu = $("#sidebarNavigation", document),
            $mypages = $("#sidebarMypages", document),
            $element = ($.browser.mobile ? $("#pushmessage-mobile", document) : $("#pushmessage-desktop", document));

                        
        if (!Utils.UserIsNyxActive() || window.hasbeenloggedout) return;

        function popOverScroll() {
            var $popover = $(".popover");
            if ($popover.length === 1 && $element.length === 1 && $element.data("bs.popover"))
                $popover.css("top", $element.offset().top + "px");
        }

        function setMessageAsRead(messageid, callback) {
            if (typeof (messageid) !== "string") return;
            $.ajax({
                url: Urls.SetReadMessages,
                data: { guid: messageid, _: new Date().getTime() },
                method: "POST",
                complete: callback
            });
        }

        function popOverClose(e) {
            e.preventDefault();
            setMessageAsRead($(this).data("id"), function() {
                $element.popover("destroy");
            });
        }

        $(window).off("scroll", popOverScroll).on("scroll", popOverScroll);

        function navigateMessages(direction, data, generate) {
            switch (direction) {
                case "prev":
                    data.length = data.length - 1;
                    break;
                case "next":
                    if (generate) {
                        setMessageAsRead(data.messages[data.length - 1].ExternalId, function() {});
                        data.length = data.length + 1;
                    }
                    break;
                default:
                    break;
            }

            data.message = data.messages[data.length - 1];

            var template = Handlebars.compile($("#UnreadMessage", document).html());
            var $content = $(template(data));

            $("[data-message]", $content).off("click").on("click", function () {
                setMessageAsRead($(this).data("message"), function() {});
            });

            $("button.close", $content).data("id", data.message.ExternalId).off("click", popOverClose).on("click", popOverClose);

            $("button.prev", $content).off("click").on("click", function (e) {
                e.preventDefault();
                navigateMessages("prev", data, true);
            });

            $("button.next", $content).off("click").on("click", function (e) {
                e.preventDefault();
                navigateMessages("next", data, true);
            });

            if (generate && $element.length === 1 && $element.data("bs.popover")) {
                $(".popover-content").html($content);
                return null;
            }

            return $content;
        }

        $.getJSON(Urls.GetUnreadMessages, { _: new Date().getTime() }).then(function (data) {
            
            if (data.loggedout) {
                window.hasbeenloggedout = true;
                setTimeout(function () {
                    location.reload(true);
                }, 45000);
                return;
            }

            if (!data.success) return;

            var myPageParam = Utils.GetUrlParamByName("page"), isMyPageParam = myPageParam === "/MyPages/Messages";

            if (typeof (data.messages) !== "undefined" && data.messages.length > 0 && $element.length === 1 && !$element.data("bs.popover") && !$navmenu.hasClass("canvas-slid") && !$mypages.hasClass("canvas-slid") && !isMyPageParam) {
                $element.popover({
                    html: true,
                    content: function() {
                        data.length = 1;

                        for (var i in data.messages) {
                            if (data.messages.hasOwnProperty(i)) {
                                data.messages[i].HtmlContent =
                                    new Handlebars.SafeString(data.messages[i].MainContent.replace(/\r?\n/ig,
                                        "<br />"));
                            }
                        }

                        return navigateMessages("next", data, false);
                    }
                }).popover("show");
            }

            $badge.text("").addClass("hidden");
            $mobile.text("").addClass("hidden");
            $mypagebadge.text("").parent().addClass("hidden");

            if (data.count > 0) {
                $badge.text(data.count).removeClass("hidden");
                $mobile.text(data.count).removeClass("hidden");
                $mypagebadge.text(data.count).parent().removeClass("hidden");
            }
        });
    }
});