import { Spinner } from "spin.js";

(function ($) {
    var pollinterval,
        $modal,
        $message,
        $elipsisloader,
        $cancelbtn,
        $startbtn;

    function isjQuery(obj) {
        return (obj && (obj instanceof jQuery || obj.constructor.prototype.jquery));
    };

    function isSocialBrowser() {
        return /instagram/ig.test(navigator.userAgent) ||
            /snapchat/ig.test(navigator.userAgent) ||
            /musical/ig.test(navigator.userAgent) ||
            /tiktok/ig.test(navigator.userAgent);
    }

    function configureStartButton(autostarttoken) {
        var userAgent = navigator.userAgent;

        if (isSocialBrowser()) return;

        var location = location = "bankid:///?autostarttoken=" + autostarttoken + "&redirect=";
        var redirect = "null";

        var isNativeApp = $("body").data("nativeapp") !== "None";
        if (isNativeApp) {
            redirect = "bingolotto:///";
        }
        else if (/ip(hone|od|ad)/ig.test(userAgent) && !window.MSStream) { // Check if iOS
            if (/CriOS/ig.test(userAgent))
                location += (window.location.protocol.indexOf("s") !== -1 ? "googlechromes://" : "googlechrome://");
            else if (/Firefox/ig.test(userAgent)) {
                return; //IOS firefox. Let user open BankID app manually.
            }
        }
        location += redirect;
        $startbtn.click(function () {
            window.location = location;
        }).show();
        
    };

    function configureCancelButton(orderRef) {
        if (orderRef) {
            $cancelbtn.on("click", { orderRef: orderRef }, cancelButton);
        }
        else {
            $cancelbtn.on("click", {}, cancelButton);
        }
        $cancelbtn.show();
    };

    function cancelButton(e) {
        e.preventDefault();
        if (e && e.data) {
            abortBankId(e.data);
        }
        else {
            hideModal();
        }
    };

    function hideModal() {
        $modal.modal('hide');
    }

    function abortBankId(options) {

        if (pollinterval) {
            clearInterval(pollinterval);
        }

        $.ajax({
            url: Urls.BankIdCancel,
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify({ orderRef: options.orderRef }),
            cache: false,
            method: "POST",
            complete: function () {
                setTimeout(function () {
                    hideModal();
                }, 1000);
            }
        });
    }

    function pollOrderComplete(orderReference, callback) {
        $.ajax({
            url: Urls.BankIdCheckComplete,
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify({ orderRef: orderReference }),
            cache: false,
            method: "POST",
            success: function (user) {
                if (user.Status > 0 && pollinterval) {
                    clearInterval(pollinterval);
                    $cancelbtn.off("click", cancelButton).hide();
                    $startbtn.hide();
                }

                //Failed
                if (user.Status == 1) {
                    $message.addClass("text-danger");
                    setTimeout(function () {
                        hideModal();
                    }, 5000);
                }

                //Complete
                if (user.Status == 2) {
                    $message.text("");
                    if (typeof (callback) === "function") callback(user);
                    setTimeout(function () {
                        hideModal();
                    }, 3000);
                    return;
                }

                $message.text(user.ClientMessage);
            }
        });
    };

    $.fn.bankidauthenticate = function (options) {
        options = $.extend({
            ssn: "",
            complete: function (user) {
                console && console.info && console.info(user);
            }
        }, options);

        var $self = $(this);

        var openModal = function () {
            var dfd = jQuery.Deferred();
            ModalHandler.BankIdAuthenticateModal(function (modal) {
                $modal = modal;
                $message = $(".message", $modal);
                $elipsisloader = $(".elipsis-loader", $modal);
                $startbtn = $(".start", $modal);
                $cancelbtn = $(".cancel", $modal);
                dfd.resolve();
            });
            return dfd;
        };

        openModal().then(function () {
            $.ajax({
                url: Urls.BankIdAuthenticate,
                dataType: "json",
                contentType: "application/json",
                data: JSON.stringify({
                    socialSecurityNumber: options.ssn
                }),
                cache: false,
                method: "POST",
                success: function (auth) {
                    if (auth.OrderRef !== null && auth.OrderRef !== "") {

                        configureStartButton(auth.AutostartToken);
                        configureCancelButton(auth.OrderRef);
                        
                        $message.removeClass("text-danger").text("");
                        
                        pollinterval = setInterval(function () {
                            pollOrderComplete(auth.OrderRef, options.complete);
                        }, 3000);
                        pollOrderComplete(auth.OrderRef, options.complete);

                    } else {
                        $message.addClass("text-danger").html(auth.ClientMessage);
                        configureCancelButton();
                    }

                }
            });
        });

        return $self;
    };

    $.fn.bankidsign = function (options) {

        options = $.extend({
            ssn: "",
            visiblecontent: "",
            hiddencontent: "",
            complete: function (user) {
                console && console.info && console.info(user);
            }
        }, options);

        var $self = $(this);

        var openModal = function () {
            var dfd = jQuery.Deferred();
            ModalHandler.BankIdSignModal(function (modal) {
                $modal = modal;
                $message = $(".message", $modal);
                $elipsisloader = $(".elipsis-loader", $modal);
                $startbtn = $(".start", $modal);
                $cancelbtn = $(".cancel", $modal);
                dfd.resolve();
            });
            return dfd;
        };

        openModal().then(function () {
            $.ajax({
                url: Urls.BankIdSign,
                dataType: "json",
                contentType: "application/json",
                data: JSON.stringify({
                    socialSecurityNumber: options.ssn,
                    userVisibleData: options.visiblecontent,
                    userNonVisibleData: options.hiddencontent
                }),
                cache: false,
                method: "POST",
                success: function (auth) {
                    if (auth.OrderRef !== null && auth.OrderRef !== "") {

                        configureStartButton(auth.AutostartToken);
                        configureCancelButton(auth.OrderRef);

                        $cancelbtn.on("click", { orderRef: auth.OrderRef }, cancelButton);
                        $message.removeClass("text-danger").text("");

                        pollinterval = setInterval(function () {
                            pollOrderComplete(auth.OrderRef, options.complete);
                        }, 3000);
                        pollOrderComplete(auth.OrderRef, options.complete);

                    } else {
                        $message.addClass("text-danger").html(auth.ClientMessage);
                        configureCancelButton();
                    }

                }
            });
        });

        return $self;
    };

})(jQuery);