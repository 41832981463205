import * as ElasticAppSearch from "@elastic/app-search-javascript";
import Handlebars from "handlebars/dist/handlebars.js";

$.widget("custom.accountpicker", {
    options: {
        excludeidsfromresults: ''
    },
    _init: function () {
        let $widget = this,
            $element = $widget.element,
            $searchbox = $("#searchAccount", $element),
            $noResults = $(".no-results", $element),
            $searchPhrase = $(".search-phrase", $noResults),
            $results = $(".results", $element),
            $resultItemSource = $("script", $element).html(),
            itemTemplate = Handlebars.compile($resultItemSource);

        let timeout = null;

        let handleSearchResult = function (resultList) {
            $results.empty();
            $noResults.hide();
            if (resultList.results.length < 1) {
                $searchPhrase.text($searchbox.val());
                $noResults.show();
            } else {
                $.each(resultList.rawResults, function (index, value) {
                    //Add results to page if it should not be excluded
                    let excludeFromResults = $widget.options.excludeidsfromresults.indexOf(value.id.raw)>-1;
                    
                    if (!excludeFromResults) {
                        var html = itemTemplate(value);
                        $results.append(html);
                    }
                });
            }
        }

        let handleSearchError = function (err) {
            $results.empty();
            $noResults.hide();
        }

        let search = function (searchText) {
            window.fsSearch.searchAssociation(searchText)
                .then(handleSearchResult, handleSearchError)
                .catch(error => {
                    console.log(`error: ${error}`);
                });
        }

        let autoSearch = function () {
            var searchText = $searchbox.val();
            if (searchText.length > 2) {
                if (timeout) clearTimeout(timeout);
                timeout = setTimeout(() => search(searchText), 200);
            }
        }

        $searchbox.keyup(autoSearch);

        $searchbox.keypress(function (event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13') {
                event.preventDefault();
                autoSearch();
            }
        });
    }
});