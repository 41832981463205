$.widget("custom.possiblessn", {
    options: {},
    _create: function () {
        var $widget = this,
            $this = $widget.element;

        function validate(e) {
            var $self = $(this),
                value = $self.val();
            if (/^\d*$/ig.test(value) && ValidationHandler.ValidateSocialSecurityNumber(value)) {
                $self.val(value.replace(/^(?!20|19|18)(.{10,})$/, "19$1"));
            }
        }

        $this.off("keydown", validate).off("keyup", validate).on("keydown", validate).on("keyup", validate);
    }
});