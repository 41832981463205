$.widget("custom.mypageslogin", {
    options: {
        contenturl: ""
    },
    _init: function () {
        var $widget = this,
            $element = $widget.element,
            $mypagescontent = $(".mypagescontent", $element);

        if (!Utils.UserIsLoggedIn()) {
            ModalHandler.AuthenticateModal(location.href);
            return;
        }

        if ($widget.options.contenturl === '') {
            return;
        }

        Utils.LoadContent($mypagescontent, $widget.options.contenturl, function (content) {
            //set a min-height on all my pages windows
            $element.closest(".block-header").css("min-height", content.height() + "px");
        });
    }
});