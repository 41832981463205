$.widget("custom.countdownto", {
    options: {
        datestring: ""
    },
    _create: function () {
        var $widget = this,
            $form = $widget.element,
            $label = $(".label", $form),
            $during = $(".during", $form),
            $after = $(".after", $form);

        if ($widget.options.datestring === "" || $label.length === 0) return;

        var parsedDate = new Date($widget.options.datestring);

        $label.countdown(parsedDate, { defer: true })
            .on("update.countdown", function(e) {
                var htmlTemplate = "%-Ss", cssClass = "label-seconds";

                if (e.offset.weeks >= 1) {
                    htmlTemplate = "%-wv %-dd %-Ht %-Mm %-Ss";
                    cssClass = "label-weeks";
                } else if (e.offset.totalDays >= 1) {
                    htmlTemplate = "%-dd %-Ht %-Mm %-Ss";
                    cssClass = "label-days";
                } else if (e.offset.totalHours >= 1) {
                    htmlTemplate = "%-Ht %-Mm %-Ss";
                    cssClass = "label-hours";
                } else if (e.offset.totalMinutes >= 1) {
                    htmlTemplate = "%-Mm %-Ss";
                    cssClass = "label-minutes";
                }

                $(this).removeClass("label-weeks label-days label-hours label-minutes label-seconds")
                    .addClass(cssClass)
                    .html(e.strftime(htmlTemplate));
            })
            .on("finish.countdown", function() {
                $label.remove();
                $during.remove();
                $after.removeClass("hide");
            }).countdown("start");
    }
});