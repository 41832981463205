(function($) {
    $.support.placeholder = "placeholder" in document.createElement("input");

    if ($.support.placeholder) return;

    $(":text, textarea, :password").focus(function() {
        var $self = $(this), placeholder = $self.attr("placeholder");
        if (placeholder && placeholder.length > 0 && $self.val() === placeholder) {
            $self.val("").removeClass("hasPlaceholder");
        }
    }).blur(function() {
        var $self = $(this), placeholder = $self.attr("placeholder");
        if (placeholder && placeholder.length > 0 && ($self.val() === "" || $self.val() === placeholder)) {
            $self.val(placeholder).addClass("hasPlaceholder");
        }
    });

    $(":text, textarea, :password").blur();
    $("form").submit(function() {
        $(this).find(".hasPlaceholder").each(function() { $(this).val(""); });
    });
})(jQuery);