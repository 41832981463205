import { Spinner } from "spin.js";

$.widget("custom.winningslist", {
	options: {
		winning: "", date: "", pickuppoint: ""
	},
	_create: function () {
		var $widget = this;
		var spinner = new Spinner(DefaultOptions.Spinner);
		var extraWinningTicketsList = [];
		
		spinner.spin($widget.element.get(0));
		$.ajax({
			url: "/MyPages/GetWinningTickets",
			type: "GET",
			contentType: "application/json",
			dataType: "json",
			success: function (response) {
				if (response == null) {
					errorGettingWinningTickets();
				}

				for (var i = 0; i < response.length; i++) {
					if (i < 3) {
						$('#winningTicketTable').append(formatRow(response[i]));
					} else {
						$('#showMoreWinnings').show();
						extraWinningTicketsList.push(formatRow(response[i]));
					}
				}

				spinner.stop();
				if (response.length > 0) {
					$('#myWinningTickets').show();
				} else {
					$('#noWinningTickets').show();
				}
			},
			error: function (jqXhr, textStatus, errorThrown) {
				console.log("Fel vid hämtning av vinster", errorThrown);
				errorGettingWinningTickets();
			}
		});

		$('#showMoreWinnings', $widget.element).click(() => {
			for (var i = 0; i < extraWinningTicketsList.length; i++) {
				$('#winningTicketTable').append(extraWinningTicketsList[i]);
			}
			$('#showMoreWinnings').hide();
		});

		//Can't find where to change this, so doing it in JS. REMOVE IF POSSIBLE
		var container = $('.col-sm-12.block.block-white.block-header');
		container.css("min-height", container.height()); //Change height to min-height to find content
		container.css("height", "");

		function errorGettingWinningTickets() {
			spinner.stop();
			$('#errorGettingWinnings').show();
			$('#noWinningTickets').hide();
			$('#myWinningTickets').hide();
		}

		var prize = $widget.options.winning;
		var date = $widget.options.date;
		var pickUpPoint = $widget.options.pickuppoint;

		//For adding rows to your table
		/* example row data
			BKConsumer: "196305242734"
			RetreiveAddress1City: "Ljungby"
			RetreiveCustomerName: "Willys Ljungby"
			WKDate: 20210725
			WKDateString: "2021-07-25"
			WinDescription: "Ny Bingolott 50 kr"
		 */
		function formatRow(rowData) {
			//create each TD with the data you need. Can be done in one big line. If you're a monster.
			//The data-label property displays the column label in mobile
			var prizeCell = '<td data-label="' + prize + '"><span>' + rowData.WinDescription + '</span></td>';
			var dateCell = '<td data-label="' + date + '"><span>' + rowData.WKDateString + '</span></td>';
			var pickUpPointCell = '<td data-label="' + pickUpPoint + '"><span class="shrinkLongText">' + rowData.RetreiveCustomerName + '</span></td>';
			//return the completed row
			return '<tr>' + prizeCell + dateCell + pickUpPointCell + '</tr>';
		}
    }
});