$.widget("custom.contentloader", {
    options: {
        contenturl: ""
    },
    _init: function () {
        var $widget = this,
            $element = $widget.element,
            $content = $(".content", $element);

        if ($widget.options.contenturl === '') {
            return;
        }

        Utils.LoadContent($content, $widget.options.contenturl);
    }
});