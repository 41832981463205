
$.widget("custom.gamebrowser", {
    options: {
        updateinterval: 15000,
        updating: false,
        showmore: "",
        showless: "",
        contentid: "",
        disableautoupdate: false
    },
    _create: function() {
        var $widget = this,
            $element = $widget.element,
            $games = $(".block-game", $element),
            $expand = $(".show-more-label", $element),
            $expandbtn = $("button.btn-lg", $element);

        function resize() {
            var width = $(window).width();
            $expand.hide();
            $expandbtn.text($widget.options.showmore);
            if (width <= 767) {
                if ($games.length > 2) {
                    $expand.show();
                    $games.hide().slice(0, 2).show();
                }
               
            } else if (width <= 1199) {
                if ($games.length > 3) {
                    $expand.show();
                    $games.hide().slice(0, 3).show();
                }
            } else {
                if ($games.length > 4) {
                    $expand.show();
                    $games.hide().slice(0, 4).show();
                }
            }
        }

        //$(window).on("resize", resize); //.on("orientationchange", resize);
        resize();

        if (!$widget.options.disableautoupdate) {
            setInterval(function() {
                if (!$widget.options.updating) {
                    $widget._updateGameData();
                }
            }, $widget.options.updateinterval);
        }

        if ($expandbtn.length === 0) return;

        $expandbtn.click(function() {
            if (!Utils.IsElementScrolledIntoView($element)) {
                $('html, body').animate({
                    scrollTop: $(".block-game:eq(0)", $element).offset().top - 150
                }, 1000, "swing", function() {});
            }

            var width = $(window).width();
            $games.hide();

            if ($expandbtn.text() === $widget.options.showmore) {
                $games.show();
            }

            if (width <= 767) {
                if ($expandbtn.text() === $widget.options.showless) {
                    $games.slice(0, 2).show();
                }
               
            } else if (width <= 1199) {
                if ($expandbtn.text() === $widget.options.showless) {
                    $games.slice(0, 3).show();
                }

            } else {
                if ($expandbtn.text() === $widget.options.showless) {
                    $games.slice(0, 4).show();
                }
            }

            if ($expandbtn.text() === $widget.options.showmore) {
                $expandbtn.text($widget.options.showless);
            }
            else {
                $expandbtn.text($widget.options.showmore);
            }
        });
    },
    _updateGameData: function() {
        var $widget = this;
        $widget.options.updating = true;

        $.ajax({
            url: Urls.Games,
            headers: {
                "x-pageurl": window.location.href
            },
            type: "POST",
            data: JSON.stringify({ blockContentId: $widget.options.contentid }),
            dataType: "json",
            contentType: "application/json",
            cache: false,
            success: function(data, textStatus, xhr) {
                $(data).each(function(index, obj) {
                    var gameElement = $widget.element.find("[data-pageid='" + obj.ContentGuid + "']");
                    gameElement.gamepuff("updateGame", obj);
                });
                $widget.options.updating = false;
            },
            error: function(jqXhr, textStatus, errorThrown) {
                $widget.options.updating = false;
            }
        });
    }
});