import { Spinner } from "spin.js";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

(function ($) {
    window.Utils = $.extend(window.Utils, {}, {
        GetAccountId: function () {
            var sessionId = $("body").data("sessionid");
            if (!sessionId || sessionId.indexOf("_") < 0) return "";
            return sessionId.substring(sessionId.indexOf("_") + 1);
        },
        HasSetTurnoverLimit: function () {
            var dfd = $.Deferred();
            var accountId = Utils.GetAccountId();
            var hSTLCookieName = "hstl" + "_" + accountId;
            var hSTLCookie = Cookies.get(hSTLCookieName);
            //if cookie exists and it has been set less than 30 minutes ago, don't ask again
            if (hSTLCookie && Date.now() - parseInt(hSTLCookie) < 1800000) {
                dfd.resolve({ HasSetLimit: true, RedirectUrl: "" });
            } else {
                $.ajax({
                    url: Urls.HasSetTurnoverLimit,
                    method: "GET",
                    cache: false,
                    success: function (data) {
                        if (data.HasSetLimit) {
                            Cookies.set(hSTLCookieName, Date.now());
                        }
                        dfd.resolve(data);
                    },
                    error: function () {
                        dfd.resolve({ HasSetLimit: true, RedirectUrl: "" });
                    }
                });
            }

            return dfd.promise();
        },
        ClearFormMessages: function ($form) {
            $('.form-feedback', $form).html("").removeClass('alert-success').removeClass('alert-danger').hide();
        },
        AddFormError: function ($form, message) {
            $('.form-feedback', $form).html(message).removeClass('alert-success').addClass('alert-danger').show();
        },
        AddFormSuccess: function ($form, message) {
            $('.form-feedback', $form).html(message).removeClass('alert-danger').addClass('alert-success').show();
        },
        HandleServiceFailure: function (jqXHR, textStatus, errorThrown, $form) {
            Utils.AddFormError($form, "Tekniskt fel!");
        },
        WaitForElement: function (selector, callback, count) {
            if (jQuery(selector).length) {
                callback(selector);
            } else {
                setTimeout(function () {
                    if (!count) count = 0;
                    count++;
                    if (count < 10) {
                        Utils.WaitForElement(selector, callback, count);
                    } else {
                        return;
                    }
                },
                    100);
            }
        },
        IPhoneModel: function () {
            if (/iPhone/.test(navigator.userAgent) && !window.MSStream) {
                // Get details about the current device
                var currentDeviceInfo = JSON.stringify({
                    'width': (window.screen.width > window.screen.height)
                        ? window.screen.height
                        : window.screen.width,
                    'height': (window.screen.width > window.screen.height)
                        ? window.screen.width
                        : window.screen.height,
                    'ratio': window.devicePixelRatio
                });

                // This is our "database" of possible device configurations
                var database = {
                    '2G/3G/3GS': {
                        'width': 320,
                        'height': 480,
                        'ratio': 1
                    },
                    '4/4S': {
                        'width': 320,
                        'height': 480,
                        'ratio': 2
                    },
                    '5/5S/5C/SE': {
                        'width': 320,
                        'height': 568,
                        'ratio': 2
                    },
                    '6/6S/7/8': {
                        'width': 375,
                        'height': 667,
                        'ratio': 2
                    },
                    '6+/6S+/7+/8+': {
                        'width': 414,
                        'height': 736,
                        'ratio': 3
                    },
                    '11 Pro/X/XS': {
                        'width': 375,
                        'height': 812,
                        'ratio': 3
                    },
                    '11/XR': {
                        'width': 414,
                        'height': 896,
                        'ratio': 2
                    },
                    '11 Pro Max/XS Max': {
                        'width': 414,
                        'height': 896,
                        'ratio': 3
                    }
                };

                // Loop through our database and compare configurations to our current device
                // Return the device name if a match is found
                for (var model in database) {
                    if (database.hasOwnProperty(model) && JSON.stringify(database[model]) === currentDeviceInfo)
                        return "iPhone " + model;
                }
            }
            return "";
        },
        TrackGoogleEvent: function (category, action, label, value, eventId) {
            if (typeof (dataLayer) === "undefined") return;
            dataLayer.push({
                event: 'FsEvent',
                eventCategory: category,
                eventAction: action,
                eventLabel: label,
                eventValue: value,
                eventId: eventId
            });
        },
        PushGoogleTag: function (eventName) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': eventName
            });
        },
        LogDataMatrixScan: function (datamatrix) {
            try {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'scanned_datamatrix',
                    'datamatrix': datamatrix
                });
            } catch (e) {
                console.error(e);
            }
        },
        TrackEComPurchaseWithEvent: function (contentId, eventId, transaction, sendToGtm = true) {
            if (typeof (dataLayer) === "undefined") return;
            $.ajax({
                url: "/Common/TrackEComPurchase",
                method: "POST",
                data: JSON.stringify({
                    eventType: "Purchase",
                    contentId,
                    eventId,
                    googleEComTransaction: transaction
                }),
                cache: false,
                dataType: "json",
                contentType: "application/json",
                success: function () {
                    if (sendToGtm) {
                        var trackData = {
                            event: "FsEComTransaction",
                            transactionId: transaction.transactionId,
                            transactionTotal: transaction.transactionTotal,
                            transactionProducts: transaction.transactionProducts,
                            eventId: transaction.transactionId
                        };

                        dataLayer.push(trackData);
                    }
                }
            });
        },
        TrackEComPurchase: function (transaction) {
            var trackData = {
                event: "FsEComTransaction",
                transactionId: transaction.transactionId,
                transactionTotal: transaction.transactionTotal,
                transactionProducts: transaction.transactionProducts
            };

            dataLayer.push(trackData);
        },
        AddUrlParam: function (uri, key, value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            } else {
                return uri + separator + key + "=" + value;
            }
        },
        AddUrlParams: function (uri, keyValue) {
            for (let key in keyValue) {
                if (Object.prototype.hasOwnProperty.call(keyValue, key)) {
                    uri = Utils.AddUrlParam(uri, key, keyValue[key]);
                }
            }
            return uri;
        },
        RemoveUrlParam: function (uri, key) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + '$2');
            } else {
                return uri;
            }
        },
        UserIsLoggedIn: function () {
            var loggedIn = $("body").data('isloggedin');
            return loggedIn && loggedIn.toLowerCase() === "true";
        },
        UserIsNyxActive: function () {
            var session = $("body").data("sessionid");
            return session != null && session !== "";
        },
        RetrySession: function () {
            var retry = $("body").data("retrysession");
            if (!retry) return;
            if (retry.toLowerCase() !== "true") return;

            setTimeout(function () {
                $.ajax({
                    dataType: "json",
                    url: Urls.RetrySession,
                    success: function (data) {
                        if (data.newsession) {
                            window.location.href = window.location.href;
                        }
                    }
                });
            }, 3000);
        },
        BrowserIsApp:
            function () { //Checks it user is on a mobile app by reading the data-nativeapp property of the body element.
                var appData = $("body").data("nativeapp");
                return appData != "None" && appData != "";
            },
        CreateGuid: function () {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }

            return s4() +
                s4() +
                '-' +
                s4() +
                '-' +
                s4() +
                '-' +
                s4() +
                '-' +
                s4() +
                s4() +
                s4();
        },
        OpenHomeBySwedenUrl: function () {
            $.ajax({
                url: Urls.HomeBySwedenUrl,
                cache: false,
                async: false
            }).done(function (url) {
                if (url != "") {
                    window.open(url);
                }
            });
        },
        GetUrlParamByName: function (name) {
            name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + name + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(window.location.search);
            if (results == null)
                return "";
            else
                return decodeURIComponent(results[1].replace(/\+/g, " "));
        },
        IsElementScrolledIntoView: function (elem) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var elemTop = $(elem).offset().top;
            return ((elemTop <= docViewBottom) && (elemTop >= docViewTop));
        },
        LoadContent: function ($targetElement, url, complete, addToTarget) {
            var dfd = jQuery.Deferred();
            var spinner = new Spinner(DefaultOptions.Spinner);
            if ($targetElement) {
                spinner.spin($targetElement.get(0));
            }
            AjaxHandler.Get(url).then(
                function (data, textStatus, xhr) {
                    if (data === "") {
                        ModalHandler.CloseModal();
                    }
                    var redirectHeader = xhr.getResponseHeader('AjaxRedirectURL');
                    if (redirectHeader != null) {
                        window.location = redirectHeader;
                        return;
                    }
                    spinner.stop();
                    var $content = $(data);
                    FormHandler.InitForms($content);
                    WidgetHandler.InitWidgets($content);
                    TabHandler.InitTabs($content);
                    if (typeof (addToTarget) === "boolean" && addToTarget) {
                        $targetElement.empty().html($content).promise().done(function () {
                            dfd.resolve();

                            if (typeof (complete) === "function") {
                                complete($content);
                            }
                        });
                    } else if ($targetElement) {
                        $targetElement.replaceWith($content);
                        dfd.resolve();

                        if (typeof (complete) === "function") {
                            complete($content);
                        }
                    } else {
                        if (typeof (complete) === "function") {
                            complete($content);
                        }
                    }
                },
                function (xhr, textStatus, errorThrown) {
                    var reg = /<title>(.*)<\/title>/ig.exec(xhr.responseText);
                    var message = reg != null && reg.length === 2 && !/tekniskt fel/ig.test(reg[1])
                        ? reg[1]
                        : "Innehåll kunde inte laddas...";
                    if ($targetElement)
                        $targetElement.empty();
                    $("<div class=\"alert alert-danger\"><p>" + message + "</p></div>").appendTo($targetElement);
                    spinner.stop();
                    dfd.reject();
                });
            return dfd.promise();
        },
        GetPurchaseTicket: function ($targetElement, amountRequired, gameId) {
            var $dfd = $.Deferred();
            var spinner = new Spinner(DefaultOptions.Spinner);
            spinner.spin($targetElement.get(0));
            $.getJSON(Urls.GetPurchaseTicket, { amountRequired: amountRequired, gameId: gameId, _: new Date().getTime() }).then(
                function (data) {
                    if (data.success && data.canpurchase) {
                        $dfd.resolve();
                    } else {
                        $dfd.reject(data.loggedout);
                    }
                    spinner.stop();
                },
                function () {
                    spinner.stop();
                    $dfd.reject(true);
                });
            return $dfd.promise();
        },
        iOSVersion: function () {
            if (/iP(hone|od|ad)/.test(navigator.platform)) {
                var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
            }
            return [0, 0, 0];
        },
        validatePersonalNumber: function (input) {
            // Check valid length & form
            if (!input) return false;

            if (input.indexOf('-') == -1) {
                if (input.length === 10) {
                    input = input.slice(0, 6) + "-" + input.slice(6);
                } else {
                    input = input.slice(0, 8) + "-" + input.slice(8);
                }
            }
            if (!input.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)) return false;

            // Clean input
            input = input.replace('-', '');
            if (input.length == 12) {
                input = input.substring(2);
            }

            // Declare variables
            var d = new Date(((!!RegExp.$1) ? RegExp.$1 : RegExp.$5),
                (((!!RegExp.$2) ? RegExp.$2 : RegExp.$6) - 1),
                ((!!RegExp.$3) ? RegExp.$3 : RegExp.$7)),
                sum = 0,
                numdigits = input.length,
                parity = numdigits % 2,
                i,
                digit;

            // Check valid date
            if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) return false;

            // Check luhn algorithm
            for (i = 0; i < numdigits; i = i + 1) {
                digit = parseInt(input.charAt(i))
                if (i % 2 == parity) digit *= 2;
                if (digit > 9) digit -= 9;
                sum += digit;
            }
            return (sum % 10) == 0;
        },
        Authenticate: function (returnUrl, view) {
            if (!returnUrl) returnUrl = window.location.pathname + window.location.search;
            if (!view) view = "login";

            window.location.href = Utils.AddUrlParams(Urls.Authenticate,
                {
                    "returnUrl": btoa(returnUrl),
                    "view": view,
                    "_": new Date().getTime()
                });
        },
        VerifyMobileAndEmail: function (returnUrl, view) {
            if (!returnUrl) returnUrl = window.location.pathname + window.location.search;
            if (!view) view = "login";

            window.location.href = Utils.AddUrlParams(Urls.VerifyMobileAndEmail,
                {
                    "returnUrl": btoa(returnUrl),
                    "view": view,
                    "_": new Date().getTime()
                });
        },
        FormatCurrency: function (amount, minimumFractionDigits = 0, locale = 'sv', currency = 'SEK') {
            return new Intl.NumberFormat(
                locale,
                {
                    style: 'currency',
                    currency: currency,
                    minimumFractionDigits
                }
            )
                .format(amount)
        },
        GetUserAccessToken: function () {
            return new Promise((resolve, reject) => {
                let accessToken = $("body").data("userAccessToken");
                if (accessToken) {
                    let jwtData = jwt_decode(accessToken) ;
                    if (jwtData.exp > Math.round(Date.now() / 1000)) {
                        resolve(accessToken);
                        return;
                    }
                }
                fetch(Urls.GetUserAccessToken)
                    .then((response) => response.text())
                    .then((data) => {
                        $("body").data("userAccessToken", data);
                        resolve(data);
                    });
            });
        },
        GetOathData: async function () {
            const token = await Utils.GetUserAccessToken();
            console.log({ token });
            return jwt_decode(token);
        },
        GetObjectPropertyValueByString: function (o, s) {
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, '');           // strip a leading dot
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in o) {
                    o = o[k];
                } else {
                    return;
                }
            }
            return o;
        }
    });
})(jQuery);