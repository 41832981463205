$.widget("custom.buttonpremium", {
    options: {
        message: "",
        redirecturl: "",
        identifier: "",
        requirepremium: "true",
        openloginmodalasdefault: "false"
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $webshop = $(".webshop", $element),
            $login = $(".login", $element),
            $notsubscribermessge = $(".notsubscriber", $element);

        if (typeof ($widget.options.requirepremium) !== "boolean" ||
            !/true|false/ig.test($widget.options.requirepremium)) {
            $widget.options.requirepremium = "true";
        }

        var isrequiredpremium = /true/ig.test($widget.options.requirepremium);

        var loginmodaldefault = /true/ig.test($widget.options.openloginmodalasdefault);

        if (Utils.GetUrlParamByName("login") !== "") {
            loginmodaldefault = true;
        }

        var openLoginModal = function () {
            ModalHandler.AuthenticateModal($widget.options.redirecturl, $widget.options.message);
        };

        var goToWebShop = function (doRedirect) {
            if ($widget.options.redirecturl === $widget.options.identifier) {
                $.getJSON(Urls.HomeBySwedenUrl, function (data) {
                    switch (data) {
                        case "":
                            break;
                        case $widget.options.issubscriber:
                            ModalHandler.OpenModal({
                                modalShown: function ($modal) {
                                    var $body = $("<div />")
                                        .addClass("modal-body")
                                        .html(
                                            "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Stäng\"><i class=\"far fa-times-circle fa-lg\"></i></button>" +
                                            $notsubscribermessge.html()
                                            + "<button type=\"button\" class=\"btn btn-primary\" data-dismiss=\"modal\" aria-label=\"Stäng\">Stäng</button>");
                                    $(".modal-content", $modal).append($body);
                                }
                            });
                            break;
                        default:
                            if (doRedirect) {
                                location.href = data;
                            }
                            
                            break;
                    }
                });

            } else if (!isrequiredpremium) {
                location.href = $widget.options.redirecturl;
                return;
            } else {
                $.getJSON(Urls.UserIsSubscriber, function (valid) {
                    if (valid) {
                        if (doRedirect) {
                            location.href = $widget.options.redirecturl;
                        }
                        return;
                    } else {
                        ModalHandler.OpenModal({
                            modalShown: function ($modal) {
                                var $body = $("<div />")
                                    .addClass("modal-body")
                                    .html(
                                        "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Stäng\"><i class=\"far fa-times-circle fg-lg\"></i></button>" +
                                        $notsubscribermessge.html()
                                        + "<button type=\"button\" class=\"btn btn-primary\" data-dismiss=\"modal\" aria-label=\"Stäng\">Stäng</button>");
                                $(".modal-content", $modal).append($body);
                            }
                        });
                    }
                });
            }
        }

        if ($login.length === 1) {

            $login.click(function(e) {
                e.preventDefault();
                openLoginModal();
            });
            if (loginmodaldefault) {
                openLoginModal();
            }

        } else if ($webshop.length === 1) {

            $webshop.click(function(e) {
                e.preventDefault();
                goToWebShop(true);
            });

            if (loginmodaldefault) {
                goToWebShop(false);
            }
        }
    }
});