$.widget("custom.cardfan", {
    options: {
        roundarticle: '',
        commission: '[]',
        commissiontype: 0,
        commissionrefcontactid: null,
        firstpurchasecategory: ""
    },
    _create: function () {
        var $widgetElement = this.element,
            $widget = this,
            selectedCard = "",
            commissionAccounts = JSON.parse($widget.options.commission);
        product = $widgetElement.data('widgetProduct');

        var createCommissionPayLoad = function () {
            var commission = {
                preset: [],
                userPreset: [],
                getUserCommisionIfEmptyPreset: false
            };
            $.each(commissionAccounts, function (index, commissionAccount) {
                if ($widget.options.commissiontype == 0) {
                    commission.userPreset.push({
                        id: commissionAccount.Item1,
                        title: commissionAccount.Item2
                    });
                }
                else {
                    commission.preset.push({
                        id: commissionAccount.Item1,
                        title: commissionAccount.Item2
                    });
                }
            });
            return commission;
        };

        var trackPurchase = function (details) {

            let totalPurchaseAmount = 0,
                transactionId = details.purchase.transactionId;

            let products = details.roundArticles.map(roundArticle => {
                let totalAmount = 0;
                let quantity = 0;
                details.purchase.tickets.map(ticket => {
                    if (ticket.roundArticleKey == roundArticle.roundArticle) {
                        totalPurchaseAmount += ticket.price;
                        totalAmount += ticket.price;
                        quantity++;
                    }
                });
                return {
                    sku: roundArticle.article,
                    name: roundArticle.product,
                    category: "ScratchTicket",
                    price: totalAmount,
                    quantity: quantity
                };
            });

            if ($widget.options.firstpurchasecategory) {
                window.fsFirstPurchase.handleFirstPurchase($widget.options.firstpurchasecategory);
            }

            Utils.TrackEComPurchaseWithEvent("ScratchTicket", transactionId, {
                transactionId: transactionId,
                transactionTotal: totalPurchaseAmount,
                transactionProducts: products
            }, false);
        }

        $(document).ready(function () {
            let aCardIsOnDisplay = false;

            $('.cardholder', $widgetElement).addClass('card-fan-container');
            $('.card-fan', $widgetElement).addClass(`product-${product}`);

            $('.card', $widgetElement).click(function () {

                //check if a card is already on display, do nothing
                if (aCardIsOnDisplay) {
                    if (!checkIfMObile()) {
                        return;
                    } else {
                        $('.card', $widgetElement).removeClass('selected');
                    }
                }

               /* $('.buy-ticket', $widgetElement).prop('disabled', false);*/

                //remove display from all cards
                $('.card', $widgetElement).removeClass('display');

                //add display-class to this card
                $(this).addClass('display');

                if (checkIfMObile()) {
                    $(this).addClass('selected');
                    $('.play-ticket-tag', $widgetElement).text($(this).find('.tag-number').text());
                } else {
                    $(this).addClass('selected');
                    selectedCard = $(this);

                    $('.play-ticket-tag', $widgetElement).text($(this).find('.tag-number').text());
                    $('.play-ticket', $widgetElement).fadeIn();
                    $('.card', $widgetElement).addClass('disabled');
                }


                aCardIsOnDisplay = true;
            });

            $('.close-button', $widgetElement).click(function () {
                $('.card', $widgetElement).removeClass('disabled');
                /*$('.buy-ticket', $widgetElement).prop('disabled', true);*/
                $('.play-ticket', $widgetElement).fadeOut();
                if (selectedCard) {
                    selectedCard.removeClass('selected');
                }
                aCardIsOnDisplay = false;
            });
            function performPurchase() {

                const ticketTitle = $('.play-ticket-tag').text();

                if (!Utils.UserIsLoggedIn()) {
                    // Save selected for 15 minutes.
                    window.fsStorage.set('cardfan-notloggedin-selected', ticketTitle, 60 * 15);
                    Utils.Authenticate();
                    return;
                }

                window.fsTickets.purchase({
                    purchases: [
                        {
                            roundArticle: $widget.options.roundarticle,
                            quantity: 1,
                            data: { ticketTitle }
                        }],
                    commission: createCommissionPayLoad(),
                    success: { action: 'openGameClient' }
                });
            }

            $('.buy-ticket', $widgetElement).click(function () {

                if (!aCardIsOnDisplay) {
                    $('.card', $widgetElement).not('.bought').first().click();
                    setTimeout(performPurchase, 1500);
                } else {
                    performPurchase();
                }
            });

            $('.demo-ticket', $widgetElement).click(function () {
                window.fsTickets.showGameClient({ roundArticleDemo: [$widget.options.roundarticle] })
            });

            $('.reset-tickets', $widgetElement).click(function () {
                $('.card', $widgetElement).removeClass('bought');
                window.fsStorage.removeItem('cardfan-bought-tickets');
                $('.reset-tickets', $widgetElement).prop('disabled', true);
            });

            // If previously selected before login, and currently logged in, show card.
            const selectedTicketTitle = window.fsStorage.get('cardfan-notloggedin-selected');
            if (selectedTicketTitle && Utils.UserIsLoggedIn()) {
                window.fsStorage.removeItem('cardfan-notloggedin-selected');
                setTimeout(() => {
                    $('.card', $widgetElement).eq(parseInt(selectedTicketTitle, 10) - 1).click();
                    setTimeout(() => {
                        $('.buy-ticket', $widgetElement).click();
                    }, 400);
                }, 400)
            }

            const previouslyBoughtTickets = window.fsStorage.get('cardfan-bought-tickets') ?? [];

            $('.card', $widgetElement).each((index, element) => {
                if (previouslyBoughtTickets.includes(index + 1)) {
                    $(element).addClass('bought');
                    $('.reset-tickets', $widgetElement).prop('disabled', false);
                }
            });

            window.fsEvents.on('tickets.purchase.success', (event) => {
                const bougthTickets = event.detail.roundArticles
                    .map((rouncArticle) => parseInt(rouncArticle.data.ticketTitle, 10));
                window.fsStorage.set('cardfan-bought-tickets', [...previouslyBoughtTickets, ...bougthTickets]);

                $('.card', $widgetElement).each((index, element) => {
                    if (bougthTickets.includes(index + 1)) {
                        $(element).addClass('bought');
                        $('.reset-tickets', $widgetElement).prop('disabled', false);
                    }
                });

                trackPurchase(event.detail);

                $('.close-button', $widgetElement).click();
                
            });
        });


        function checkIfMObile() {
            return ($('.cards', $widgetElement).first().css("overflow-x") === "scroll");
        }
    }
});