import { Spinner } from "spin.js";

$.widget("custom.autogirosignup",
    {
        options: {
            step: "0",
            ssn: "",
            ssnaccount: "",
            bankidvisibledata: "",
            errormessage: "",
            bank: ""
        },
        _create: function() {
            var $widget = this,
                $this = $widget.element,
                spinner,
                pollInterval;

            if ($widget.options.errormessage !== "") {
                console.error($widget.options.errormessage);
            }

            //add 19 or 20 to a 10 digit social security number
            $("#ssn").change(function() {
                if ($(this).val().length == 10 && !($(this).val().startsWith("19") || $(this).val().startsWith("20"))) {
                    var date = new Date();
                    var yearBorn = $(this).val().substring(0, 2);
                    var currentYear = date.getFullYear().toString().substr(2, 2);
                    if (yearBorn <= currentYear) {
                        $(this).val("20" + $(this).val());
                    } else {
                        $(this).val("19" + $(this).val());
                    }
                }
            });

            $('#step1Form').on('submit',
                function(e) {
                    e.preventDefault();

                    //Most likely auto filled, controller will use the account SSN, not the filled in with the *:s
                    if ($("#ssn").val().length == 12 && $("#ssn").val().endsWith("****")) {
                        this.submit();
                        return true;
                    }

                    if (Utils.validatePersonalNumber($("#ssn").val())) {
                        this.submit();
                        return true;
                    } else {
                        $("#ssnErrorMessage").show();
                        return false;
                    }
                });

            if ($widget.options.step === 1) {
                $("#bankList").on('change',
                    function() {
                        $("#bank").val(this.value);
                    });
            } else {

                spinner = new Spinner($.extend({}, DefaultOptions.Spinner, { color: "#000" }));
                var qrImage = $("#QrImage");
                var selectBankAccountInput = $("#BankAccountDropDown")[0];

                if (this.options.step === 2) {
                    spinner.spin($("#BankIdSpinner").get(0));
                    $("#BankIdErrorMessage").hide();
                    $("#BankIdDefaultMessage").show();

                    bankIdSign();
                }


                (function(a) {
                    (jQuery.browser = jQuery.browser || {}).mobile =
                        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
                        .test(a) ||
                        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
                        .test(a.substr(0, 4))
                })(navigator.userAgent || navigator.vendor || window.opera);

                // Select bankid app or separate unit
                function bankIdSign() {
                    // if not mobile -> openbankid, else let customer choose

                    if (!$.browser.ismobile) {
                        $("#BankIdOverlay").show();
                        openBankId(false);
                    } else {
                        // Show: SelectBankIdOverlay
                        $("#SelectBankIdOverlay").show();
                        $("#openBankIDApp").click(function() {
                            openBankId(true);
                        });
                        $("#otherUnitBankID").click(function() {
                            openBankId(false);
                        });
                    }
                }

                var hasOpenedBankid = false;
                var stopPolling = false;

                function openBankId(mobile) {

                    //Reset in case user calls multiple times
                    hasOpenedBankid = false;
                    $("#SelectBankIdOverlay").hide();
                    $("#BankIdOverlay").show();
                    spinner.spin($("#BankIdSpinner").get(0));


                    var ssn = $widget.options.ssn + "";
                    if (ssn.endsWith("****")) {
                        ssn = $widget.options.ssnaccount;
                    }

                    var data = {
                        'bankType': $widget.options.bank,
                        'socialSecurityNumber': ssn
                    };

                    $.ajax({
                        url: "/Account/BillectaInitiation",
                        type: "POST",
                        contentType: "application/json",
                        dataType: "json",
                        data: JSON.stringify(data),
                        success: function(response) {
                            setTimeout(function() {
                                    console.log(response);
                                if (response.PublicId !== "") {
                                        stopPolling = false;
                                        pollInterval = setInterval(function() {
                                                getBankAccountsFromBillecta(response.PublicId, mobile);
                                            },
                                            1000);
                                    } else {
                                        console.log("Unable to start billecta");
                                    }


                                },
                                500);
                        },
                        error: function() {
                            //alert('error');
                            $("#BankIdErrorMessage").show();
                            $("#BankIdDefaultMessage").hide();
                            spinner.stop();
                        }
                    });
                }


                function getBankAccountsFromBillecta(publicId, mobile) {
                    $.ajax({
                        url: "/Account/BillectaPolling",
                        type: "GET",
                        contentType: "application/json",
                        dataType: "json",
                        data: { publicId: publicId },
                        success: function(data) {

                            if (mobile && !hasOpenedBankid) {
                                // If mobile open app directly https://app.bankid.com/?autostarttoken=[TOKEN]&redirect=null
                                hasOpenedBankid = true;
                                $("#BankIdDefaultMessage").hide();
                                $("#BankIdWaitingForResponseFromApp").show();
                                // Change text to "Väntar på svar på BankID"
                                window.location = 'https://app.bankid.com/?autostarttoken=' +
                                    data.BankIdAutostartToken +
                                    '&redirect=null';
                            } else if (typeof data.QR != "undefined" && data.QR != null) {
                                qrImage.attr("src", data.QR);
                                qrImage.show();
                            }

                            if (data.Status >= 1 && !stopPolling) {
                                if (pollInterval) {
                                    stopPolling = true;
                                    clearInterval(pollInterval);
                                }
                                //Success
                                if (data.Status == 1) {


                                    var length = selectBankAccountInput.options.length;
                                    for (let i = 0; i < length; i++) {
                                        selectBankAccountInput.options[i] = null;
                                    }

                                    $.each(data.AccountNumbers,
                                        function(i, item) {
                                            if (item.Name) {
                                                selectBankAccountInput.append(
                                           /*         new Option(item.Name + " (" + item.AccountNo + ")", item.Number));*/
                                                    new Option(item.Name + " (" + item.AccountNo + ")", item.ClearingNo + "," + item.AccountNo));
                                            } else {
                                                selectBankAccountInput.append(
                                              /*      new Option(item.Clearing + " - " + item.AccountNo, item.Number));*/
                                                    new Option(item.ClearingNo + " - " + item.AccountNo, item.ClearingNo + "," + item.AccountNo));
                                            }
                                        });
                                    spinner.stop();
                                    $("#BankIdOverlay").hide();
                                    qrImage.hide();
                                } else if (data.Status == 2) { //Fail
                                    console.log(data);
                                    $("#BankIdErrorMessage").show();
                                    $("#BankIdDefaultMessage").hide();
                                    qrImage.hide();
                                    spinner.stop();
                                }
                            }
                        },
                        error: function() {
                            $("#BankIdErrorMessage").show();
                            $("#BankIdDefaultMessage").hide();
                            $("#BankIdWaitingForResponseFromApp").hide();
                            qrImage.hide();
                            spinner.stop();
                        }
                    });

                }
            }
        }
    });
