$.widget("custom.gamepuff", {
    options: {
        pageid: null,
        online: 0,
        starttime: null,
        keepupdated: false,
        openinghours: "",
        updateinterval: 10000,
        updating: false,
        url: "",
        jackpot: "",
        jackpotlabel: "",
        ismultiple: false,
        singlegame: ""
    },
    _create: function () {
        var $widget = this;
        $widget._setup();
        if ($widget.options.keepupdated) {
            setInterval(function () {
                if (!$widget.options.updating) {
                    $widget._updateGameData();
                }
            }, $widget.options.updateinterval);
        }
    },
    _setup: function () {
        var $widget = this;
        $widget._startCountDown();
        $("span.label", $widget.element).remove();
        if ($widget.options.jackpot !== "") {
            var jackpotElement = "".concat("<span class=\"label label-blue\"><small>", $widget.options.jackpotlabel, "</small>", $widget.options.jackpot, "</span>");
            $(jackpotElement).insertBefore($("div.block-game-info", $widget.element));
        }
        if ($widget.options.ismultiple) {
            $(".users", $widget.element).html("".concat("<i class=\"fas fa-users\" aria-hidden=\"true\"></i> ", $widget.options.online, " st"));
        } else {
            $(".users", $widget.element).html("".concat("<i class=\"fas fa-user hidden-xs\" aria-hidden=\"true\"></i> ", "<span class=\"hidden-xs\">", $widget.options.singlegame, "</span>"));
        }
        var clickoptions = { clickurl: $widget.options.url };
        $($widget.element).parent().off("click", clickoptions, $widget._elementclick).on("click", clickoptions, $widget._elementclick);
    },
    _elementclick: function (e) {
        if (e.data.clickurl === "") return;
        location.href = e.data.clickurl;
    },
    _startCountDown: function () {
        var $widget = this;
        var $countDown = $(".sts", $widget.element);
        if (!$widget.options.starttime) {
            $countDown.text($widget.options.openinghours);
            return;
        }
        if (!$widget.options.ismultiple) {
            $countDown.text($widget.options.openinghours);
            return;
        }
        var startTime = new Date($widget.options.starttime);
        startTime.setHours(startTime.getHours() + (new Date().getTimezoneOffset() / 60) * (-1));
        var timeToStart = (startTime.getTime() - new Date().getTime());
        if (timeToStart > 0 && (timeToStart / (1000 * 60 * 60) < 1)) {
            $countDown.countdown(startTime, { defer: true })
                .on("update.countdown", function (e) {
                    $(this).html(e.strftime("%M:%S"));
                }).countdown("start");
        } else {
            $countDown.text($widget.options.openinghours);
        }
    },
    updateGame: function (game) {
        var $widget = this;
        $widget.options.online = game.CurrentOnline;
        $widget.options.jackpot = game.Jackpot;
        $widget.options.openinghours = game.OpeningHours;
        if (game.StartTime) {
            $widget.options.starttime = new Date(parseInt(game.StartTime.replace(/(^.*\()|([+-].*$)/g, "")));
        }
        $widget._setup();
    },
    _updateGameData: function () {
        var $widget = this;
        if (!$widget.options.updating) {
            $widget.options.updating = true;
            AjaxHandler.Get(Urls.Game, { page: $widget.element.data("pageid") }).then(
                function (data, textStatus, xhr) {
                    $widget.updateGame(data);
                    $widget.options.updating = false;
                },
                function (xhr, textStatus, errorThrown) {
                    $widget.options.updating = false;
                }
            );

        }
    }
});