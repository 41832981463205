//https://www.npmjs.com/package/@elastic/app-search-javascript
import * as ElasticAppSearch from "@elastic/app-search-javascript";

export class AppSearch {
    appSearchClient = null;

    constructor() {
        this.createClient()
    }

    createClient() {
        const engineName = this.getEngineName();

        if (engineName == null) throw new Error("Engine cannot be null");

        this.appSearchClient = ElasticAppSearch.createClient({
            searchKey: this.getSearchKey(),
            endpointBase: this.getEndpointBase(),
            engineName: this.getEngineName()
        });
    }

    getSearchKey() {
        if ($("body").data("searchkey"))
            return $("body").data("searchkey");
        else if (typeof SETTINGS !== 'undefined')
            return SETTINGS.elasticsearch.searchkey;
        else
            return null;
    }

    getEndpointBase() {
        if (typeof SETTINGS !== 'undefined')
            return SETTINGS.elasticsearch.rooturl;
        else if (typeof Urls !== 'undefined' && typeof Urls.ElasticSearchRoot !== 'undefined')
            return Urls.ElasticSearchRoot;
        else
            return null;
    }

    getEngineName() {
        return null;
    }

    getSearchOptions() {
        let options = {
            search_fields: this.getSearchFields(),
            filters: this.getSearchFilters()
        };
        return options;
    }

    getSearchFields() {
        return null;
    }

    getSearchFilters() {
        return null;
    }

    handleSearchResult(resultList, callback) {
        
    }

    handleSearchError(error) {
        console.log(`error: ${error}`);
    }

    search(searchText) {
        const options = this.getSearchOptions();
        return new Promise((resolve, reject) => {
            this.appSearchClient
                .search(searchText, options)
                .then(resultList => {
                    this.handleSearchResult(resultList);
                    resolve(resultList);
                }, error => {
                    this.handleSearchError(error);
                    reject(error);
                })
                .catch(error => {
                    this.handleSearchError(error);
                });
        });
    }
}

export class AccountSearch extends AppSearch {

    getEngineName() {
        if (typeof SETTINGS !== 'undefined')
            return SETTINGS.elasticsearch.accountenginename;
        else if (typeof Urls !== 'undefined' && typeof Urls.ElasticSearchAccountEngineName !== 'undefined')
            return Urls.ElasticSearchAccountEngineName;
        else
        return "accounts";
    }

    getSearchFields() {
        let searchFiels = {
            name: { }
        };
        return searchFiels;
    }
}


export class AssociationSearch extends AccountSearch {

    getSearchFilters() {
        let searchFilters = {
            account_type: ["association"]
        };
        return searchFilters;
    }

}



