import { Spinner } from "spin.js";
import bootbox from "bootbox";

$.widget("custom.opengame", {
    options: {
        replay: false,
        wagerid: ""
    },
    _init: function() {
        var $widget = this,
            $element = $widget.element,
            spinner = new Spinner(DefaultOptions.Options);

        $element.click(function(e) {
            e.preventDefault();
            $element.prop("disabled", true).attr("disabled", "disabled");
            spinner.spin($element.closest("div").get(0));
            $.ajax({
                url: "/MyPages/UrlToGame",
                method: "GET",
                data: {
                    wagerId: $widget.options.wagerid
                },
                success: function(data) { 
                    if (data.Success) {
                        if ($widget.options.replay) {
                            data.Url = Utils.AddUrlParam(data.Url, "wagerId", $widget.options.wagerid.replace(/'/ig, ""));
                        }
                        window.location = data.Url;
                    } else {
                        $element.prop("disabled", false).removeAttr("disabled");
                        bootbox.alert(data.Message);
                    }
                },
                complete: function() {
                    spinner.stop();
                }
            });
        });
    }
});