(function($) {
    window.AjaxHandler = $.extend(window.AjaxHandler, {}, {
        Post: function (url, data, headers) {
            if (!headers) {
                headers = {};
            }
            headers["x-pageurl"] = window.location.href;
            var dfd = jQuery.Deferred();
            $.ajax({
                url: url,
                headers: headers,
                type: "POST",
                data: data,
                cache: false,
                success: function (response, textStatus, xhr) {
                    dfd.resolve(response, textStatus, xhr);
                },
                error: function (jqXhr, textStatus, errorThrown) {
                    dfd.reject(jqXhr, textStatus, errorThrown);
                }
            });
            return dfd.promise();
        },
        Get: function (url, data) {
            var dfd = jQuery.Deferred();
            var headers = {};
            headers["x-pageurl"] = window.location.href;
        
            $.ajax({
                url: url,
                type: "GET",
                data: data,
                cache: false,
                headers: headers,
                success: function (response, textStatus, xhr) {
                    dfd.resolve(response, textStatus, xhr);
                },
                error: function (jqXhr, textStatus, errorThrown) {
                    dfd.reject(jqXhr, textStatus, errorThrown);
                }
            });
            return dfd.promise();
        }
    });
})(jQuery);