import Handlebars from "handlebars/dist/handlebars.js";
import { Spinner } from "spin.js";

$.widget("custom.searchretailerresult", {
    options: {
        url: "",
        addressnotfound: "",
        technicalerror: "",
        organizationtodisplay: ""
    },
    _create: function () {
        var $widget = this,
            $form = $widget.element,
            spinner = new Spinner(DefaultOptions.Spinner),
            spinnerContainer = $form.get(0),
            $submit = $("button", $form),
            $searchresults = $("#searchresults", $form),
            templatemultipleresults = Handlebars.compile($("#multipleresults", $form).html()),
            templateretailerlist = Handlebars.compile($("#retailerlist", $form).html());

        var filterAddressResults = function(results) {
            var filteredResults = [],
                types = ["postal_town", "locality", "sublocality", "postal_code", "route", "street_address"];
            for (var i = 0; i < results.length; i++) {
                for (var j = 0; j < results[i].address_components.length; j++) {
                    if (results[i].address_components[j].short_name === "SE") {
                        for (var k = 0; k < results[i].types.length; k++) {
                            if ($.inArray(results[i].types[k], types) > -1) {
                                filteredResults.push(results[i]);
                                break;
                            }
                        }
                        break;
                    }
                }
            }
            return filteredResults;
        };

        var searchRetailers = function (street, zipcode, city, searchPhrase, formattedAddress, latitude, longitude) {
            var orgToDisplay = $widget.options.organizationtodisplay;
            $.ajax({
                url: $widget.options.url,
                type: "POST",
                dataType: "json",
                contentType: "application/json",
                data: JSON.stringify({ latitude: latitude, longitude: longitude, organizationtodisplay: orgToDisplay }),
                success: function (response) {
                    var index = 0;

                    Handlebars.registerHelper("index", function () {
                        index = (index + 1);
                        return index;
                    });
                    Handlebars.registerHelper("currentindex", function () {
                        return index;
                    });

                    $searchresults.html(templateretailerlist(response));
                    $searchresults.fadeIn(500, function () {
                        var options = {
                            zoom: 10,
                            center: new google.maps.LatLng(latitude, longitude),
                            mapTypeId: google.maps.MapTypeId.ROADMAP
                        };
                        var map = new google.maps.Map($("#find-retailer-map-canvas", $form).get(0), options);
                        var markers = [], circle;

                        if (response.length > 0) {
                            var kmRadius = response[response.length - 1].CoordinateDistanceKm;
                            var circleOptions = {
                                center: new google.maps.LatLng(latitude, longitude),
                                fillOpacity: 0,
                                strokeOpacity: 0.1,
                                map: map,
                                radius: (kmRadius > 0 ? kmRadius : 0.5) * 1000
                            };
                            circle = new google.maps.Circle(circleOptions);
                            map.fitBounds(circle.getBounds());
                        }

                        var longitudeOffset = 0;
                        for (var i = 0; i < response.length; i++) {
                            var retailer = response[i];
                            //if multiple retailers on same spot,
                            if (i > 0 && response[i - 1].Latitude === retailer.Latitude && response[i - 1].Longitude === retailer.Longitude) {
                                longitudeOffset = (longitudeOffset + 0.00002);
                            }

                            var marker = new MarkerWithLabel({
                                position: new google.maps.LatLng(retailer.Latitude, retailer.Longitude + longitudeOffset),
                                map: map,
                                labelContent: (i + 1),
                                labelAnchor: new google.maps.Point(0, 0),
                                labelClass: "google-map-marker",
                                icon: "/Static/img/pixel.png"
                            });
                            google.maps.event.addListener(marker, "click", function() {
                                for (var i = 0; i < markers.length; i++) {
                                    if (this === markers[i]) {
                                        var $listelem = $("#retailers li", $form).eq(i).find(".collapse");
                                        $("#retailers li .collapse", $form).not($listelem).each(function () {
                                            if ($(this).hasClass("in")) {
                                                $(this).collapse("hide");
                                            }
                                        });
                                        $listelem.collapse("show");
                                    }
                                }
                            });

                            markers.push(marker);
                        }

                        $("#retailers .collapse", $form).on("shown.bs.collapse", function () {
                            map.panTo(markers[$(this).parent().index()].position);
                            map.setZoom(15);
                        });

                        $("#retailers .collapse", $form).on("hidden.bs.collapse", function () {
                            if ($("#retailers .collapse.in", $form).length === 0 && circle) {
                                map.setCenter(new google.maps.LatLng(latitude, longitude));
                                map.fitBounds(circle.getBounds());
                            }
                        });

                        spinner.stop();
                    });
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    //Handle technical error
                    spinner.stop();
                    Utils.AddFormError($form, $widget.options.technicalerror);
                }
            });
        };

        var searchAddress = function () {
            $searchresults.fadeOut().empty();
            Utils.ClearFormMessages($form);
            var street = $("#Street", $form).val().replace(/\d+/g, ''),
                zipcode = $("#ZipCode", $form).val(),
                city = $("#City", $form).val();

            if (street === "" && zipcode === "" && city === "") return;

            spinner.spin(spinnerContainer);

            var searchPhrase = street + ",+" + zipcode + ",+" + city + ",+Sweden",
                geocoder = new google.maps.Geocoder();

            geocoder.geocode({ "address": searchPhrase }, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    var resultsFiltered = filterAddressResults(results);
                    if (resultsFiltered.length > 0) {
                        if (resultsFiltered.length === 1) {
                            searchRetailers(street, zipcode, city, searchPhrase, resultsFiltered[0].formatted_address, resultsFiltered[0].geometry.location.lat(), resultsFiltered[0].geometry.location.lng());
                        } else {
                            spinner.stop();
                            $searchresults.html(templatemultipleresults(resultsFiltered));
                            $("a", $searchresults).click(function (e) {
                                e.preventDefault();
                                var index = $("a", $searchresults).index(this);
                                $searchresults.fadeOut(500, function () {
                                    spinner.spin(spinnerContainer);
                                    searchRetailers(street, zipcode, city, searchPhrase, resultsFiltered[index].formatted_address, resultsFiltered[index].geometry.location.lat(), resultsFiltered[index].geometry.location.lng());
                                });
                            });
                            $searchresults.fadeIn();
                        }
                    }
                    else {
                        Utils.AddFormError($form, $widget.options.addressnotfound);
                        spinner.stop();
                    }
                } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS || results.length === 0) {
                    Utils.AddFormError($form, $widget.options.addressnotfound);
                    spinner.stop();
                } else {
                    Utils.AddFormError($form, $widget.options.technicalerror);
                    spinner.stop();
                }
            });
        }

        $submit.click(function(e) {
            e.preventDefault();
            searchAddress();
            return false;
        });

        $("input", $form).keydown(function (e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                searchAddress();
                return false;
            }
        });

        setTimeout(function() {
            searchAddress();
        }, 200);
    }
});