import Cookies from "js-cookie";

$.widget("custom.receiveticketform", {
    options: {},
    _create: function () {
        var $widget = this,
            $form = $widget.element,
            $code = $("#VoucherCode", $form);

        var formSubmitted = false;

        $form.submit(function(e) {
            if (Utils.UserIsLoggedIn() || formSubmitted) return;
            e.preventDefault();
        });

        var voucherCode = Cookies.get("receiveticketform_VoucherCode");
        if (voucherCode != null) {
            Cookies.remove("receiveticketform_VoucherCode", { path: "/", secure: true });
            formSubmitted = true;
            $form.submit();
            return;
        }
        
        function keyDown(e) {
            var keyCode = e.which || e.keyCode || e.charCode;
            if (keyCode === 13) {
                e.preventDefault();
                return;
            }
        }

        if (!Utils.UserIsLoggedIn()) {
            $code.keydown(keyDown).keyup(keyDown).blur(function() {
                Cookies.set("receiveticketform_VoucherCode", $code.val(), { path: "/", secure: true });
            });
        }
    }
});