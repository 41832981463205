$.widget("custom.accountdetails", {
    options: {
    },
    _init: function () {
        var $widget = this,
            $element = $widget.element,
            $usepublicaddress = $(".usepublicaddress", $element),
            $useraddress = $(".useraddress", $element);

        $usepublicaddress.on("change", function (e) {
            e.preventDefault();

            if ($usepublicaddress.is(":checked")) {
                $useraddress.hide(500);
            }
            else {
                $useraddress.show(500);
            }
        });
    }
});