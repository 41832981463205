$.widget("custom.bankidchangebank", {
    options: {
        sitename: ""
    },
    _create: function () {
        var $widget = this,
            $form = $widget.element,
            $ssn = $("#SocialSecurityNumber", $form),
            $clearing = $("input.clearingnumber", $form),
            $account = $("input.accountnumber", $form),
            $firstName = $("#FirstName", $form),
            $lastName = $("#LastName", $form);

        $form.on("BeforeAjaxFormPost", function (event) {
            
            $(".validation-summary-errors", $form).addClass("hidden");
            var result = kontonummer($clearing.val() + $account.val());
            if (result === false) {
                $(".validation-summary-errors", $form).removeClass("hidden");
                event.preventDefault();
                return;
            }

            var ssn = $ssn.val();

            var visibleContent = "".concat(
                "Bankkontobyte\n\n",
                $firstName.val(), " ",
                $lastName.val(), "\n",
                ssn, "\n",
                result.bank_name, " ",
                $clearing.val(), " ",
                $account.val(), "\n\n",
                "Jag vill byta mitt bankkonto.");

            var hiddenContent = "".concat(
                "Byt bankkonto för: ",
                $widget.options.sitename
            );

            if (!($form.data("confirmed") === ssn)) {
                event.preventDefault();
                $form.bankidsign({
                    ssn: ssn,
                    visiblecontent: visibleContent,
                    hiddencontent: hiddenContent,
                    complete: function () {
                        $form.data("confirmed", ssn);
                        $form.submit();
                    }
                });
                return false;
            }
        });
    }
});