$.widget("custom.fbsharedialog", {
    options: {
        quote: "",
        share: ""
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element;

        $("<a />").attr("href", "#").attr("title", $widget.options.share + " Facebook").addClass("share arrow-hidden").html($widget.options.share + "<i class=\"share-facebook\"><i>").appendTo($element).click(function(e) {
            e.preventDefault();

            FB.ui({
                method: "share",
                href: location.protocol.concat("//", location.host, location.pathname),
                quote: $widget.options.quote
            }, function (response) { });
        });
    }
});