import { Spinner } from "spin.js";
import bootbox from "bootbox";

(function ($) {
    window.FormHandler = $.extend(window.FormHandler, {}, {
        HandleFormError: function ($form, errorMessage) {
            var $formfeedback = $(".form-feedback", $form);
            if ($formfeedback.length === 0) {
                $formfeedback = $("<div class=\"alert form-feedback\"></div>").prependTo($form);
            }
            $formfeedback.addClass("alert-danger").html(errorMessage).show();
            if ($form.data("safeform")) {
                $(":submit", $form).prop("disabled", false).removeAttr("disabled");
            }
        },
        HandleServerError: function (form, jqXHR) {
            var $form = $(form);
            var validator = $form.data("validator");
            if (validator) {
                var errorArray = {};
                var inputErrors = jQuery.grep(jqXHR.responseJSON,
                    function (n, i) {
                        return n.key !== "";
                    });
                $(inputErrors).each(function () {
                    var modelError = this;
                    $(modelError.errors).each(function () {
                        errorArray[modelError.key] = this;
                    });
                });
                validator.showErrors(errorArray);

                var otherErrors = jQuery.grep(jqXHR.responseJSON,
                    function (n, i) {
                        return n.key === "";
                    });

                $(otherErrors).each(function () {
                    var error = this;
                    $(error.errors).each(function () {
                        $(".form-feedback", $form).addClass("alert-danger").html(this).show();
                    });
                });
            }
        },
        InitForms: function ($element) {
            //scan element for form
            if ($element.is("form")) {
                FormHandler.InitForm($element);
            }
            $("form", $element).each(function () {
                FormHandler.InitForm($(this));
            });
        },
        InitForm: function ($form) {
            if (!$form.data("preventformhandling") && !$form.data("forminitialized")) {
                var event = jQuery.Event("InitForm");
                $form.trigger(event);
                if (!event.isDefaultPrevented()) {
                    FormHandler.InitFormValidation($form);
                    FormHandler.InitFormAjax($form);
                }
                var trackingInit = $form.data("tracking-init");
                if (trackingInit) {
                    if (typeof (dataLayer) !== "undefined") {
                        dataLayer.push({ "event": "virtualPageView", "virtualPage": trackingInit });
                    } else if (typeof (ga) !== "undefined") {
                        ga("send", "pageview", trackingInit);
                    }
                }
                FormHandler.InitFormElements($form);

                if ($form.hasClass("usesafeform")) {
                    $form.safeform({
                        timeout: 10000,
                        submit: function () {
                            if ($(this).valid())
                                $(":submit", $(this)).prop("disabled", true).attr("disabled", "disabled");
                        }
                    });
                }
            }
            $form.data("forminitialized", true);
        },
        InitFormElements: function ($form) {
            var $selects = $("select.cs-select", $form);

            var isIe10 = false;
            var isIe11 = !(window.ActiveXObject) && "ActiveXObject" in window;
            /*@cc_on
                if (/^10/.test(@_jscript_version)) {
                    isIe10 = true;
                }
            @*/

            if (typeof (SelectFx) === "function") {
                // test specificly for IE 11 or IE 10 and Windows 8/8.1
                if ((isIe11 || isIe10) && /Windows NT (6.2|6.3)/ig.test(navigator.userAgent)) {
                    $selects.each(function () {
                        var $self = $(this);
                        if ($self.data("isemptytext") && $("option", $self).length === 0) {
                            $self.prop("disabled", true).attr("disabled", "disabled")
                                .append($("<option />").text($self.data("isemptytext")));
                        }
                    });
                } else {
                    $selects.each(function () {
                        return new SelectFx(this, { isemptytext: $(this).data("isemptytext") });
                    });
                }
            }

            var validKeyCodes = [8, 13, 37, 38, 39, 40, 46];

            $("[data-toggle='tooltip']", $form).tooltip();
            $("[data-toggle='popover']", $form).popover();

            $("[maxlength]", $form).each(function () {

                function keyDownUp(e) {
                    var keyCode = (e.keyCode || e.which);
                    var text = "";
                    if (window.getSelection) {
                        text = window.getSelection().toString();
                    } else if (document.selection && document.selection.type !== "Control") {
                        text = document.selection.createRange().text;
                    }
                    if (text !== "") $(this).val($(this).val().replace(text, ""));

                    if ($(this).val().length === parseInt($(this).attr("maxlength"))) {
                        var $next = $(this).next();
                        if ($next.length === 1 && validKeyCodes.indexOf(keyCode) === -1) {
                            $next.focus();
                        }
                        if (validKeyCodes.indexOf(keyCode) === -1) {
                            e.preventDefault(); // causes issues with firefox
                        }
                        return;
                    }
                }

                $(this).off("keypress keyup", keyDownUp).on("keypress keyup", keyDownUp);
            });

            $("[data-numeric]", $form).each(function () {

                function keyDownUp(e) {
                    var keyCode = e.keyCode || e.which;
                    // cmd/ctrl || // arrow keys || // delete key || // backspace key || // enter key || // numbers];
                    var isValid = (e.metaKey ||
                        validKeyCodes.indexOf(keyCode) !== -1 ||
                        /[0-9]/.test(String.fromCharCode(keyCode)));
                    if (isValid === false) {
                        e.preventDefault();
                        return;
                    }

                    var text = "";
                    if (window.getSelection) {
                        text = window.getSelection().toString();
                    } else if (document.selection && document.selection.type !== "Control") {
                        text = document.selection.createRange().text;
                    }
                    if (text !== "") $(this).val($(this).val().replace(text, ""));
                }

                $(this).off("keypress keyup", keyDownUp).on("keypress keyup", keyDownUp);
            });

        },
        InitFormValidation: function ($form) {
            var event = jQuery.Event("InitFormValidation");
            $form.trigger(event);
            if (!event.isDefaultPrevented()) {
                if (!$form.data("validator")
                ) { //If form has no validator, maybe form was inserted dynamically, try trigger unobtrusive
                    $.validator.unobtrusive.parse($form);
                }
            }
        },
        InitFormAjax: function ($form) {
            if ($form.hasClass("ajaxform") && ($form.attr("method") || "GET").toLowerCase() === "post") {
                var event = jQuery.Event("InitFormAjax");
                $form.trigger(event);

                $("input[type='submit'], button[type='submit']", $form).click(function (e) {
                    $(this).data().isclicked = true;
                });

                if (!event.isDefaultPrevented()) {
                    if ($form.data("validator")
                    ) { //Form is validated by jquery.validate, attach to submit by submithandler
                        $form.data("validator").settings.submitHandler = function (form) {
                            FormHandler.PostForm($(form));
                        };
                    } else { //Form is not validated, attach to regular submit-event.
                        $form.submit(function (e) {
                            e.preventDefault();
                            FormHandler.PostForm($(this));
                        });
                    }

                    $(".ajaxlink", $form).click(function (e) {
                        var $this = $(this),
                            $form = $this.parents("form");
                        if ($form.length === 0) {
                            return true;
                        }

                        if ($this.hasClass("createaccountlink")) {
                            e.preventDefault();
                            var $scratchModal = $this.parents("#scratchModal");

                            if ($scratchModal.length === 1) {
                                $scratchModal.off("hidden.bs.modal").on("hidden.bs.modal",
                                    function () {
                                        $scratchModal.off("hidden.bs.modal");
                                        ModalHandler.CreateAccountModal();
                                    }).modal("hide");
                            } else {
                                ModalHandler.CreateAccountModal();
                            }


                            return false;
                        };

                    if ($this.hasClass("loginlink")) {
                        e.preventDefault();
                        ModalHandler.AuthenticateModal();
                        return false;
                    };

                        if ($this.hasClass("loginlink")) {
                            e.preventDefault();
                            ModalHandler.LoginModal({
                                socialSecurityNumber: $("#SocialSecurityNumber", $form).val()
                            });
                            return false;
                        };

                        if ($this.data("append")) {
                            var valuesToAppend = $this.data("append").split(";");
                            $(valuesToAppend).each(function (index, elementId) {
                                var $elem = $("#" + elementId, $form);
                                if ($elem.length > 0) {
                                    $this.attr("href",
                                        Utils.AddUrlParam($this.attr("href"), elementId, $elem.val()));
                                }
                            });
                        }
                        if ($form.parents("[data-formconfiguration]").length > 0) {
                            var formConfiguration =
                                $form.parents("[data-formconfiguration]").data("formconfiguration");
                            $this.attr("href",
                                Utils.AddUrlParam($this.attr("href"), "formconfiguration", formConfiguration));
                        }

                        var spinner = new Spinner(DefaultOptions.Spinner).spin($form.get(0));
                        e.preventDefault();
                        AjaxHandler.Get($this.attr("href")).then(
                            function (data, textStatus, xhr) {
                                var $element = $(data);
                                FormHandler.ReplaceForm($form, $element);
                            },
                            function (jqXHR, textStatus, errorThrown) {
                            }
                        );
                    });
                }
            }
        },
        PostForm: function ($form) {
            var event = jQuery.Event("BeforeAjaxFormPost");
            $form.trigger(event);
            if (!event.isDefaultPrevented()) {
                var spinner = new Spinner(DefaultOptions.Spinner).spin($form.get(0));
                $form.data("spinner", spinner);
                var formConfiguration = "";
                if ($form.parents("[data-formconfiguration]").length > 0) {
                    formConfiguration = $form.parents("[data-formconfiguration]").data("formconfiguration");
                }
                var headers = { 'x-formconfiguration': formConfiguration };

                var serialize = $form.serializeArray();

                $("input[type='submit'], button[type='submit']", $form).each(function () {
                    if ($(this).data().isclicked) {
                        var name = $(this).attr("name"), value = $(this).val();
                        if ((name && value) &&
                            (typeof (serialize[name]) === "undefined" || serialize[name] == null)) {
                            serialize.push({ name: name, value: value });
                        }
                    }
                });

                AjaxHandler.Post($form.attr("action"), $.param(serialize), headers).then(
                    function (data, textStatus, xhr) {
                        event = jQuery.Event("AfterAjaxFormPost");
                        $form.trigger(event, [data, textStatus, xhr]);
                        if (!event.isDefaultPrevented()) {
                            spinner.stop();

                            var ajaxAccountLogin = xhr.getResponseHeader("AjaxAccountLogin");
                            if (ajaxAccountLogin !== null && ajaxAccountLogin !== "" && window.ReactNativeWebView) {
                                window.ReactNativeWebView.postMessage(JSON.stringify({
                                    type: "login",
                                    payload: ajaxAccountLogin
                                }));
                            }

                            var redirectHeader = xhr.getResponseHeader('AjaxRedirectURL');
                            if (redirectHeader != null) {
                                window.location = redirectHeader;
                                return;
                            }

                            var reloadPageHeader = xhr.getResponseHeader('AjaxReloadPage');
                            if (reloadPageHeader != null) {
                            
                                var locationhref = Utils.RemoveUrlParam(window.location.href, "modal");
                                locationhref = Utils.RemoveUrlParam(locationhref, "guid");

                                var accountCreatedPageHeader = xhr.getResponseHeader('AjaxAccountCreated');
                                if (accountCreatedPageHeader != null &&
                                    accountCreatedPageHeader.toLowerCase() === "true") {
                                    locationhref = Utils.AddUrlParam(locationhref, "modal", "accountcreated");
                                }

                                var pageSessionKey = xhr.getResponseHeader("AjaxSessionKey");
                                var pageElementOpenId = xhr.getResponseHeader("AjaxElementOpenId");
                                var pageScratchTicketHeader = xhr.getResponseHeader("AjaxScratchTicket");
                                if (pageScratchTicketHeader != null &&
                                    pageScratchTicketHeader.toLowerCase() === "true" &&
                                    pageElementOpenId != null &&
                                    pageElementOpenId !== "") {

                                    if (pageSessionKey != null && pageSessionKey !== "") {
                                        $("body").data("sessionid", pageSessionKey)
                                            .attr("data-sessionid", pageSessionKey);
                                    }
                                    $("#" + pageElementOpenId).ticketchoosercheckaccount();

                                    return;
                                }

                                window.location.href = locationhref;
                                return;
                            }
                            var $targetElement = $form;
                            if ($form.data("target-element") != null) {
                                $targetElement = $($form.data("target-element"));
                            }
                            var appendForm = $form.data("target-append") != null && $form.data("target-append");

                            // TODO: Vad skall denna göra?
                            //var validModelState = xhr.getResponseHeader('ModelStateIsValid');
                            var $element = $(data);
                            FormHandler.ReplaceForm($targetElement, $element, appendForm);
                        }
                    },
                    function (jqXHR, textStatus, errorThrown) {
                        event = jQuery.Event("AjaxFormPostError");
                        $form.trigger(event, [jqXHR, textStatus, errorThrown]);
                        if (!event.isDefaultPrevented()) {
                            spinner.stop();
                            FormHandler.HandleFormError($form, "Ett tekniskt fel inträffade. Försök igen senare.");
                        }
                    });

            }
        },
        ReplaceForm: function ($form, $newElement, appendForm) {
            FormHandler.InitForms($newElement);
            WidgetHandler.InitWidgets($newElement);
            TabHandler.InitTabs($newElement);
            var $targetElement = $form;
            if ($form.data("updatetargetid")) {
                $targetElement = $("#" + $form.data("updatetargetid"));
            }

            if (typeof (appendForm) === "boolean" && appendForm) {
                $targetElement.empty().append($newElement);
            } else {
                $targetElement.replaceWith($newElement);
            }

            if ($newElement.data("modalheader")) {
                $newElement.parents(".modal-content").find(".modal-header h4")
                    .text($newElement.data("modalheader"));
            }
            var event = jQuery.Event("AfterAjaxFormReplaced");
            $newElement.trigger(event);

            if ($form.data("updatetrigger")) {
                $(document).trigger($form.data("updatetrigger"));
            }
        }
    });

})(jQuery);