$.widget("custom.bankidsignature", {
    options: {
        sitename: "",
        ssn: "",
        ssnelement: "",
        visiblecontent: "",
        hiddencontent: ""
    },
    _create: function () {
        var $widget = this,
            $form = $widget.element;

        $form.submit(function (e) {
            if (!$form.validate().form()) {
                e.preventDefault();
                return false;
            }
            var ssn = $widget.options.ssn !== "" ? $widget.options.ssn : $($widget.options.ssnelement).val();
            if (!($form.data("confirmed") === ssn)) {
                e.preventDefault();
                $form.bankidsign({
                    ssn: ssn,
                    visiblecontent: $widget.options.visiblecontent,
                    hiddencontent: $widget.options.hiddencontent,
                    complete: function (user) {
                        $form.data("confirmed", ssn);
                        $form.submit();
                    }
                });
                return false;
            }
        });
    }
});